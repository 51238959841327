<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="row pt-md-3 mb-2">
		<div class="col-md-9 col-9">
			<div class="scroll-container" v-if="totalPages > 1">
				<span v-for="page in pages" :key="page.ID" 
					:class="{'page-number': true,  'is-selected': page.Selected}" 
					@click="onClick(page)"
				>
					{{page.Number}}
				</span>
			</div>
		</div>
		<div class="col-md-3 col-3 text-right">
			<span class="sub-text">{{currentStartRecord}}-{{currentFinishRecord}} of {{totalResults}} results</span>
		</div>
	</div>
</template>





<script>
export default {


	emits: ["click"],

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},



		currentPageNumber: {
			type: Number,
			required: true,
		},


		pageSize: {
			type: Number,
			required: true,
		},

		totalResults: {
			type: Number,
			required: true,
		}
	},





	data()
	{
		return {
			
		};
	},



	watch: {
		currentPageNumber: function (to, from) {
			console.log("   pagination::watch.currentPageNumber(): to=", to, " | from=", from);
		}
	},






	mounted()
	{
		this.init();	
	},





	computed: {
		/**
		 * Calculate the total number of pages from the `totalResults` and `pageSize`.
		 */
		totalPages()
		{
			const quotient = Math.floor(this.totalResults / this.pageSize);
			const remainder =  this.totalResults % this.pageSize;

			if (remainder > 0) return quotient + 1;
			return quotient;
		},


		pages()
		{
			var results = [];

			for (var i = 1; i <= this.totalPages; i++)
			{
				var selected = false;
				if (i == this.currentPageNumber)
				{
					selected = true;
				}

				results.push(
					{
						ID: i,
						Number: i,
						Selected: selected,
					}
				);
			}

			//console.log("pagination.pages(): results=", results);

			return results;
		},


		currentStartRecord()
		{
			// currentPageNumber
			// pageSize

			if (this.totalResults == 0) return 0;

			if (this.currentPageNumber == 1) return 1;

			return ((this.currentPageNumber - 1) * this.pageSize) + 1;
		},


		currentFinishRecord()
		{
			var size = (this.currentPageNumber * this.pageSize);

			if (this.currentPageNumber == 1) 
			{
				if (size > this.totalResults) return this.totalResults;
				return this.pageSize;
			}

			if (size > this.totalResults) size = this.totalResults;

			return size;
		}
	},





	methods: {
		init()
		{

		},


		onClick(page)
		{
			page.Selected = true;

			console.log("   pagination.onClick(): page=", page);
			this.$emit('click', page);
		}
	}
}
</script>





<style scoped lang="scss">
	.scroll-container
	{
		width: 100%;
		overflow-x: auto; 
		white-space: nowrap;
		
	}

	.page-number
	{
		padding-right: 4px;
		cursor: pointer;
		color: blue;
	}


	.is-selected
	{
		cursor: default;
		color: inherit;	
		font-weight: bold;
	}


	.sub-text
	{
		font-size: 75%;
	}
</style>
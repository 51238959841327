<template>
	<a :href="href" 
		type="button" 
		:id="id"
		:class="['c-action-link', 'btn', 'btn-outline-primary', 'btn-sm', cssClass]" 
		@click="onClick" 
		:disabled="disabled ? true : false"
		:title="title">
			<span class="icon" v-if="hasIcon()"><slot name="icon"></slot></span>
			<span class="text" v-if="hasText()"><slot name="text"></slot></span>
	</a>
</template>





<script>
import buttonPropsMixin from "./button-props-mixin";
import allFormElementPropsMixin from "../forms/all-form-element-props-mixin";

export default {

	mixins: [allFormElementPropsMixin, buttonPropsMixin],



	props: {
		
		/**
		 * The address to navigate to.
		 */
		href: {
			type: String,
			required: true,
			default: ""
		},
	},


	mounted()
	{
		this.displayType = "default";	// "standard" and "default"

		//console.log("c-action-link: this.$slots=", this.$slots);
	},




	methods: {
		//TODO: Remove
		//TODO: I think this worked in Vue 2.x
		// hasIcon()
		// {
		// 	return this.$slots.icon;
		// },
		//
		// hasText()
		// {
		// 	return this.$slots.text;
		// }

		hasIcon()
		{
			//Ref: https://stackoverflow.com/questions/50133153/get-rendered-html-contents-of-vue-slot-as-variable
			//Ref: https://eslint.vuejs.org/rules/require-slots-as-functions.html
			// Part 1) Ensure the slot is actually included in the calling component.
			// Part 2) Check there is HTML content within the slot in the calling component.
			const hasValue = !!this.$slots['icon'] && this.$slots.icon().length > 0;
			return hasValue;
		},

		hasText()
		{
			//Ref: https://stackoverflow.com/questions/50133153/get-rendered-html-contents-of-vue-slot-as-variable
			//Ref: https://eslint.vuejs.org/rules/require-slots-as-functions.html
			// Part 1) Ensure the slot is actually included in the calling component.
			// Part 2) Check there is HTML content within the slot in the calling component.
			const hasValue = !!this.$slots['text'] && this.$slots.text().length > 0;
			return hasValue;
		}
	}

}
</script>





<style scoped lang="scss">

//-- Start: Copied these in from DOM inspection. I think they came from Bootstrap
.c-action-link {
    display: inline-block;
    font-weight: 400;
    // color: #222f38;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    // background-color: transparent;
    border: 1px solid transparent;
    padding: 1rem 2rem;
    font-size: 1rem;
    // line-height: 1.5;
    border-radius: 1.85rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 1rem;
}

.btn-outline-primary {
    color: #ffb600;
    border-color: #ffb600;
}

.btn-outline-primary:hover {
    color: #212529;
    background-color: #ffb600;
    border-color: #ffb600;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #ffb600;
    border-color: #ffb600;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(255, 182, 0, 0.5);
}
//-- End: Copied these in from DOM inspection. I think they came from Bootstrap



	.c-action-link
	{
		background-color: #ffffff;
		border-radius: 4px;
	}


	a.c-action-link
	{
		color: #212529;	// Got this from DOM inspection. It's from... I don't know. Need this to override 'a' color set in /assets/sass/_global.scss
	}
</style>

<style scoped lang="scss">
	.icon {
		margin-right: 10px		
	}



	a.c-action-link.c-blue-action-link
	{
		// background-color: rgb(64, 158, 255);
		border: 1px solid rgb(64, 158, 255);
		// border-radius: 4px;

		// // color: white;
		// font-family: CircularStd, Montserrat, sans-serif;
		// font-size: 14px;
		// font-weight: 500;
		
		// padding: 4px;
		// padding-left: 16px;
		// padding-right: 16px;
	}


	// Copied from <el-button> type="primary"
	a.c-action-link.c-blue-action-link:hover
	{
		// background-color: rgb(64, 158, 255, .7);

		// color: #ffffff;
		border-color: #79bbff;
		// background-color: #79bbff;
		outline: 0;
	}

	// Copied from <el-button> type="primary"
	a.c-action-link.c-blue-action-link:active {
		// color: #ffffff;
		border-color: #337ecc;
		// background-color: #337ecc;
		outline: 0;
	}
</style>
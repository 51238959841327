const dateHelpers = {

	/**
	 * Convert a date [string] to a common display format.
	 * 
	 * @param {string} theDate JavaScript Date object, or a string representing a date in the format yyyy-mm-dd (e.g. 2020-05-09 which represents "09 May 2020").
	 * 
	 * @returns {string} The date converted to an expected display format.
	 */
	convertDateToDisplay(theDate, format)
	{
		if (theDate == "" || theDate == undefined)
		{
			// No date to convert.
			return "";
		}

		console.log("convertDateToDisplay(): theDate=", theDate);

		var display = "";

		var date = null;
		if (typeof theDate === 'string') 
		{
			date = new Date(theDate);
		}
		else 	
		{
			// Assume at the moment it is a Date object already
			date = theDate;
		}

		// It's a funky unrecognised value.
		if (date == null) return "";

		var year = date.getFullYear();
		var month = date.getMonth() + 1;	// Months are zero based (¯\_(ツ)_/¯)
		var day = date.getDate();


		// console.log("dateHelpers.convertDateToDisplay(" + isoDMY + "): date=", date, day, month, year);


		function getDayAccent(day)
		{
			if (day == 1) return "st";
			if (day == 2) return "nd";
			if (day == 3) return "rd";

			return "th";
		}

		function getMonthName(monthNumber, format = "long")
		{
			//console.log("getMonthName(): monthNumber=", monthNumber);
			
			if (monthNumber < 1 || monthNumber > 12 || monthNumber == undefined || monthNumber == "")
			{
				return "";
			}

			var months = {
				1: ["January","Jan"],
				2: ["February","Feb"],
				3: ["March","Mar"],
				4: ["April","Apr"],
				5: ["May","May"],
				6: ["June","June"],
				7: ["July","July"],
				8: ["August","Aug"],
				9: ["September","Sept"],
				10: ["October","Oct"],
				11: ["November","Nov"],
				12: ["December","December"]
			}

			// Return the short month name.
			if (format == "short") return months[monthNumber][1];

			// Default: return the long month name
			return months[monthNumber][0];
		}



		if (format == 1)
		{
			// Example: 5<sup>th</sup> March
			display = day + "<sup>" + getDayAccent(day) + "</sup> " + getMonthName(month);
		}
		if (format == 11)
		{
			// Example: 5<sup>th</sup> March
			display = day + "<sup>" + getDayAccent(day) + "</sup> " + getMonthName(month, "short");
		}

		if (format == 2)
		{
			// Example: 5<sup>th</sup> March 2020
			display = day + "<sup>" + getDayAccent(day) + "</sup> " + getMonthName(month) + " " + year;
		}
		if (format == 22)
		{
			// Example: 5<sup>th</sup> Mar 2020
			display = day + "<sup>" + getDayAccent(day) + "</sup> " + getMonthName(month, "short") + " " + year;
		}
		if (format == 222)
		{
			// Example: 5 Mar 2020
			display = day + " " + getMonthName(month, "short") + " " + year;
		}

		if (format == 3)
		{
			// Example: May 2020
			display = getMonthName(month) + " " + year;
		}

		if (format == 4)
		{
			// Example: 5<sup>th</sup> March
			display = day + "<sup>" + getDayAccent(day) + "</sup> " + getMonthName(month);
		}

		return display;
	},


	getDateTimestamp()
	{
		var date = new Date();

		return date.getTime();
	},


	/**
	 * Get the current date/time from the client system.
	 * @returns Date
	 */
	getNow()
	{
		var date = new Date();

		return date;
	},





	//TODO: a helper date formatting function to stop duplication of code of the following 3 methods.
	
	/**
	 * Calculate and return yesterday's date in a specified format.  
	 * 
	 * @param {number} format Which format to return the date in. (1: `yyyy-mm-dd` string; 2: `Date()` object)
	 */
	getLocalYesterdayDate(format)
	{
		var date = new Date();	// Today
		date.setDate(date.getDate() - 1);	// Adjust to yesterday

		if (format == 2)
		{
			return date;
		}

		if (format == 1)
		{
			// // Generate yyyy-mm-dd string
			// var year = date.getFullYear();
			// var month = date.getMonth() + 1;
			// if (month.toString().length == 1) month = "0" + month;
			// var day = date.getDate();
			// if (day.toString().length == 1) day = "0" + day;

			// var ymd = year + "-" + month + "-" + day;

			// return ymd;

			return this.formatDate(date, format);
		}
	},




	/**
	 * Calculate and return the date a in a specificed.  
	 * A positive offset number is the number of day in the future.  
	 * A negative offset number is the number of days in the past.
	 *   
	 * @param {number} offset The number of days in the future or past.  
	 * @param {number} format Which format to return the date in. (1: `yyyy-mm-dd` string; 2: `Date()` object)
	 */
	getLocalDateFromOffset(offset, format)
	{
		var date = new Date();	// Today
		date.setDate(date.getDate() + offset);	// Adjust to the offset date

		if (format == 2)
		{
			return date;
		}

		if (format == 1)
		{
			return this.formatDate(date, format);
		}
	},



	getDayNames()
	{
		var dayNames = [];
		dayNames.push("Sunday");
		dayNames.push("Monday");
		dayNames.push("Tuesday");
		dayNames.push("Wednesday");
		dayNames.push("Thursday");
		dayNames.push("Friday");
		dayNames.push("Saturday");
	},


	formatDate(date, format)
	{
		var year = date.getFullYear();
		
		var month = date.getMonth() + 1;
		if (month.toString().length == 1) month = "0" + month;
		
		var day = date.getDate();
		if (day.toString().length == 1) day = "0" + day;

		// var dayNames = this.getDayNames();
		// var dayNumber = date.getDay();
		// var dayName = dayNames[dayNumber];

		if (format == 1)
		{
			// Generate ISO "yyyy-mm-dd" string
			var ymd = year + "-" + month + "-" + day;

			return ymd;
		}
	},


	
	/**
	 * Calculate and return today's date in a specified format.  
	 * 
	 * @param {number} format Which format to return the date in. (1: `yyyy-mm-dd` string; 2: `Date()` object)
	 */
	getLocalTodayDate(format)
	{
		var date = new Date();	// Today

		if (format == 2)
		{
			return date;
		}

		if (format == 1)
		{
			// Generate yyyy-mm-dd string
			return this.formatDate(date, format);
		}
	},


	/**
	 * Return the current local date and time, formatted as: `yyyy-MM-ddThh:mm:ss`.
	 */
	getLocalDateAndTimeAsIso()
	{
		var dateTime = new Date();

		return dayjs(dateTime).format("YYYY-MM-DD") + "T" + dayjs(dateTime).format("HH:mm:ss");
	},


	getLocalUtcOffset()
	{
		//Ref: https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript
		//Ref: https://www.w3schools.com/jsref/jsref_gettimezoneoffset.asp
		let timezoneOffsetDifference = new Date().getTimezoneOffset();

		// Because the "difference" is inverted to what you think. 
		// Ref: https://www.w3schools.com/jsref/jsref_gettimezoneoffset.asp & https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
		timezoneOffsetDifference = timezoneOffsetDifference * -1;
		
		//console.log("getLocalUtcOffset().timezoneOffsetDifference=", timezoneOffsetDifference);

		let localUtcOffset = this.minutesToUtcOffset(timezoneOffsetDifference);
		
		//console.log("getLocalUtcOffset(): localUtcOffset=", localUtcOffset);

		return localUtcOffset;
	},

	


	/**
	 * Add (or remove with -ve value) a number of dates to a specified date.
	 * @param {Date} date The date to add/remove days on.
	 * @param {int} daysToAdd The number of date to add/remove.
	 * @returns {Date} The new date.
	 */
	addDays(date, daysToAdd)
	{
		const newDate = new Date(Number(date));
		newDate.setDate(date.getDate() + daysToAdd);

		return newDate;
	},



	/**
	 * Takes a calculates difference between UTC time and local time in minutes
	 * (https://www.w3schools.com/jsref/jsref_gettimezoneoffset.asp) 
	 * and returns the "±hh:mm" representation.
	 */
	minutesToUtcOffset(minutes)
	{
		var plusMinus = "";

		// Less than zero is minus, otherwise plus (including zero)
		plusMinus = (minutes < 0) ? "-" : "+";

		var absolute = Math.abs(minutes);

		var wholeHours = Math.round(absolute/60);

		var remainingMinutes = absolute - (wholeHours * 60);

		if (wholeHours.toString().length == 1) wholeHours = "0" + wholeHours;
		if (remainingMinutes.toString().length == 1) remainingMinutes = "0" + remainingMinutes;
		if (remainingMinutes.toString().length == 0) remainingMinutes = "00";

		var utcOffset = plusMinus + wholeHours + ":" + remainingMinutes;

		return utcOffset;
	}


};



export default dateHelpers;
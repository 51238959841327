//TODO: [@jason, 21 uly 2023] This was a silly idea and mainly relevant to Vue 2. Don't continue with this file.

const commonApiCalls = {


	//NOTE: Initially created as a helper when running with Vue 2. May be obsolete with Vue 3.
	/**
	 * Update (completeky replace) an array data value in a component.
	 * 
	 * @param vueDataValue the component's data value property to update.
	 * @param data The data (an Array) to load into the `vueDataValue` property.
	 */
	setArrayValue(vueDataValue, data, callback)
	{
		vueDataValue.splice(0, vueDataValue.length);

		for (var i = 0; i < data.length; i++)
		{
			vueDataValue.push(data[i]);
		}

		if (callback) callback();
	},

	

	getAllAddressesInAccount(appRoot, requestData, vueDataValue, callback)
	{
		// var requestData = {
		// 	accountId: ""		//ID of the account to get addresses in
		// };

		//console.log("addresses.getAllAddressesInAccount(): requestData=", requestData);

		appRoot.showProcessingOverlay();
		
		appRoot.callApi("/Addresses/GetAllAddressesInAccount", requestData, "GET")
		.then((responseData) => {
			//console.log("addresses.getAllAddressesInAccount(): responseData=", responseData);
			appRoot.hideProcessingOverlay();

			if (callback) callback();

			if (responseData == null) return; // Not successful

			// vueDataValue.splice(0, vueDataValue.length);

			// for (var i = 0; i < responseData.Addresses.length; i++)
			// {
			// 	vueDataValue.push(responseData.Addresses[i]);
			// }

			this.setArrayValue(vueDataValue, responseData.Addresses);

			//TODO: Pagingation not yet implemented. See controller "Clients/GetAllClientsInAccount".
			// _this.pagination.currentPageNumber = responseData.PageNumber;
			// _this.pagination.totalPages = responseData.TotalPages;
			// _this.pagination.pageSize = responseData.PageSize;
			// _this.pagination.totalResults = responseData.TotalResults;

			//console.log("_vueDataValue=", vueDataValue);
			//console.log("_this.pagination=", _this.pagination);

			//_this.dataTableUpdater += 1;	//TODO: Not sure what this is. Leftover from something I guess.


			if (callback) callback();
		});
	},
};




export default commonApiCalls;
<template>
	<input type="password" 
		:id="id" 
		:name="getName" 
		:class="['c-input-password', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '']" 
		:style="getCssStyle"
		:maxlength="maxlength"
		:disabled="disabled"
		:value="modelValue"
		@input="onInput"
		data-lpignore="true"
	>

	<span class="validation-text" v-if="isValid == false && validationMessage != ''">
		<small style="color: red" v-html="validationMessage"></small>
	</span>
</template>





<script>
//Note: `data-lpignore="true"` to stop LastPass icon
import allFormElementPropsMixin from "./all-form-element-props-mixin";


export default {

	emits: ["change", "update:modelValue"],

	mixins: [allFormElementPropsMixin],

	props: {
		
		initialValue: {
			type: String,
			default: ""
		},

		
		placeholder: {
			type: String,
			default: ""
		},

		
		maxlength: {
			type: String,
			default: ""
		},

		isBold: {
			type: Boolean,
			default: false,
		},


		preventFormSubmitOnEnter: {
			type: Boolean,
			default: false,
			required: false,
		},



		//TODO: New with Vue 3
        modelValue: {
            type: String,
            default: '',
            required: false
        },

	},





	data()
	{
		return {
			value: ""
		};
	},




	mounted()
	{
		//console.log("input-text.mounted(): initialValue=", this.initialValue);
		//console.log("input-text.mounted(" + this.id + "): cssClass=", this.cssClass);
		
		this.value = this.initialValue;
	},


	//TODO: Do I seriously need to do this for each control. Why? Because mounted doesn't work, probably because we're getting a value from the API in the parent and it needs to flow through to here, after this control is mounted.
	watch: {
		initialValue: function(to, from)
		{
			this.value = this.initialValue;
		}
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {
		onInput(event)
		{
			//console.log("onInput(): ", event.target.value);

			// this.$emit('input', $event.target.value);
			this.$emit('change', event.target.value);

			this.$emit('update:modelValue', event.target.value);
		},

	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
	
	.c-input-text 
	{
		width: 100%;
	}
</style>

<style lang="scss">
	
	.c-input-text.el-override-bold 
	{
		.el-input__inner {
			font-weight: bold;		
		}
	}
</style>


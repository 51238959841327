<template>
	<div class="c-input-radio-set">
		<label 
			v-for="option in options" :key="id + '-' + option.value"
			style="display: block; padding-right: 20px;"
		>
			<span style="display: inline-block; padding-right: 10px;">{{ option.text }}</span>
			<c-input-radio 
			

				:id="id" 
				:name="setName" 
				:option-value="option.value" 
				:initial-value="option.value" 
				v-model="value"

				type="native"

				@select="handleSelect"
				>
			</c-input-radio>
		</label>
	</div>

	<span class="validation-text" v-if="isValid == false && validationMessage != ''">
		<small style="color: red" v-html="validationMessage"></small>
	</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	emits: ["change", "input", "blur", "click", "update:modelValue"],
	
	mixins: [allFormElementPropsMixin],

	props: {

		/**
		 * The standard radio button "name" property shared across radios in the group
		 */
		setName: {
			type: [String],
			requred: true,
			default: ""
		},
		
		initialValue: {
			type: [ String, null ]
		},
		
        modelValue: {
            type: [String, null],
            default: '',
            required: true
        },

		/**
		 * A standard setup of options with "value" and "text" properties.
		 * Use this for a standard list WIHTOUT option groups.
		 */
		options: {
			type: [Array, null],
			default: null
		},

		// /**
		//  * A custom data set, where "value" and "text" for standard "options"
		//  * are mapped from the "dataValue" and "dataText" (name of the properties in the data items) respectively.
		//  */
		// data: {
		// 	type: Array,
		// 	default: null
		// },








		// dataValue: {
		// 	type: String,
		// 	default: ""
		// },

		// dataText: {
		// 	type: String,
		// 	default: ""
		// },


		// type: {
		// 	type: String,
		// 	default: "native"
		// },


		// multiple: {
		// 	type: Boolean,
		// 	default: false
		// },



		// //TODO: New with Vue 3
        // // modelValue: {
        // //     type: Array,
        // //     default: () => { return []; },
        // //     required: true
        // // },

        // // modelValue: {
        // //     type: String,
        // //     default: '',
        // //     required: true
        // // },






		
		
	},






	data()
	{
		return {
			value: []
		};
	},






	mounted()
	{
		//TODO: Doing this here is crapping out the value for some reason

		//this.value = this.initialValue;

		//console.log("input-select.mounted()[" + this.id + "]: initialValue=" + this.initialValue, "value=", this.value);

		this.init();
	},




	created()
	{
		this.value = this.initialValue;

		// console.log("input-select.created()[" + this.id + "]: initialValue=" + this.initialValue, "value=", this.value);
	},



	watch: {
		initialValue: function(to, from)
		{
			this.value = this.initialValue;

			// console.log("watch[initialValue][" + this.id + "]: value=", this.value, "to=", to, "from=", from);
		}
	},





	computed: {
		
	},





	methods: {
		init()
		{
			this.value = this.modelValue;
		},

		onClick()
		{
			this.$emit('click', {});
		},


		onInput($event)
		{
			this.$emit('input', $event.target.value);
		},


		// onChange($event)
		// {
		// 	this.$emit('change', $event.target.value);
		// },


		handleSelect(value)
		{
			// console.log("input-radio-select.handleChange(): value=", value);
			// console.log("input-radio-select.handleChange(): this.value", this.value);

			//const emitValue = this.value;	// Nope: This is 1 selection behind
			const emitValue = value;

			// console.log("input-radio-select.handleChange(): emitValue=", emitValue);

			//this.$emit('input', this.value);	//TODO: Disabled 20 Jan 2024
			this.$emit('change', emitValue);

			this.$emit('update:modelValue', emitValue);
		},


		onBlur($event)
		{
			this.$emit('blur', $event.target.value);
		},











		// onInputEl(value)
		// {
		// 	//console.log("onInputEl(): ", value);

		// 	this.$emit('input', value);


		// 	//this.$emit('update:modelValue', value);
		// },

		// onBlurEl($event)
		// {
		// 	// console.log("onBlurEl(): ", $event);

		// 	// this.$emit('blur', $event.target.value);
		// },

		onChangeEl(value)
		{
			//console.log("onChangeEl(): ", value);

			//this.$emit('input', this.value);	//TODO: Disabled 20 Jan 2024
			this.$emit('change', this.value);

			//this.$emit('update:modelValue', this.value);
			this.$emit('update:modelValue', value);
		},


	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";


.c-input-select
{
	width: 100%;

	// text-indent: 5px;


	option
	{
		padding: 4px;
	}


	option:checked
	{
		color: rgb(64, 158, 255);
		font-weight: bold;
	}
}

.c-input-select-multiple
{
	// This should cover the first 5 items in the list
	height: 142px;
}
</style>
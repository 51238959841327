<template>
	<c-utility-button id="timesheet-filter-button" type="standard" :is-drop-down="true">
		<template v-slot:icon>
			<c-lotus-icon code="calendar" css-color-class="text-primary"></c-lotus-icon>
		</template>
		<template v-slot:text><span v-html="label"></span></template>
		<template v-slot:drop-down>
			<!-- <h6 class="dropdown-header">Date</h6> -->
			<div class="dropdown-item no-highlight" v-if="type.toLowerCase() == 'single'">
				<!-- <c-input-date id="filter-single-date" v-model="filterSingleDate" :initial-value="filterSingleDate" @change="onFilterChange('single-date')"></c-input-date> -->

				<el-date-picker
					:id="id" 
					v-model="singleDateValue"
					@change="onChangeSingle"
					@input="onInputSingle"
					@blur="onBlurSingle"
					format="dd MMM yyyy"
					value-format="yyyy-MM-dd"
					>
				</el-date-picker>
			</div>
			<!-- <div class="dropdown-divider"></div>
			<h6 class="dropdown-header">Range</h6> -->
			<div class="dropdown-item no-highlight" v-if="type.toLowerCase() == 'range'">
				<el-date-picker
					:id="id"
					v-model="dateRangeValue"
					type="daterange"
					range-separator="To"
					start-placeholder="Start date"
					end-placeholder="End date"
					format="dd MMM yyyy"
					value-format="yyyy-MM-dd"
					@change="onChangeRange"
					@input="onInputRange"
					@blur="onBlurRange">
				</el-date-picker>
			</div>
		</template>
	</c-utility-button>
</template>





<script>
import buttonPropsMixin from "./button-props-mixin";
import allFormElementPropsMixin from "../forms/all-form-element-props-mixin";

export default {


	emits: ["change", "input"],


	mixins: [allFormElementPropsMixin, buttonPropsMixin],


	props: {

		initialValue: {
			type: [String, Array],
			default: ""
		},


		label: {
			type: String,
			default: "Select a date"
		},


		type: {
			type: String,
			default: "single"	// "single", "range"
		},

	},


	data() 
	{
		return {
			dateRangeValue: [],
			singleDateValue: ""
		};
	},




	created()
	{
		if (this.type.toLowerCase() == "range") this.dateRangeValue = this.initialValue;
		if (this.type.toLowerCase() == "single") this.singleDateValue = this.initialValue;

		//console.log("created(): this.singleDateValue=", this.singleDateValue, "this.dateRangeValue=", this.dateRangeValue);
	},



	watch: {
		initialValue: function(to, from)
		{
			if (this.type.toLowerCase() == "range") this.dateRangeValue = this.initialValue;
			if (this.type.toLowerCase() == "single") this.singleDateValue = this.initialValue;

			//console.log("watch(): this.singleDateValue=", this.singleDateValue, "this.dateRangeValue=", this.dateRangeValue);
		}
	},




	methods: {
		// onChangeRange(value)
		// {
		// 	console.log("onChangeRange(): value=", value);
		// 	console.log("onChangeRange(): this.value=", this.value);

		// 	this.$emit('change', this.value);
		// },


		// onChangeSingle(value)
		// {
		// 	console.log("onChangeSingle(): value=", value);
		// 	console.log("onChangeSingle(): this.value=", this.singleDateValue);

		// 	this.$emit('change', this.singleDateValue);
		// },


		


		// onChange($event)
		// {
		// 	//this.$emit('change', $event.target.value);

		// 	this.$emit('input', this.value);
		// 	this.$emit('change', this.value);
		// },








		onInputSingle(value)
		{
			//console.log("onInputSingle(): ", value);

			this.$emit('input', value);
		},

		onBlurSingle($event)
		{
			// console.log("onBlurSingle(): ", $event);

			// this.$emit('blur', $event.target.value);
		},

		onChangeSingle(value)
		{
			//console.log("onChangeSingle(): ", value);

			this.$emit('input', this.singleDateValue);		//TODO: This is needed to bind back to the model
			this.$emit('change', this.singleDateValue);
		},



		
		onInputRange(value)
		{
			//console.log("onInputRange(): ", value);

			this.$emit('input', value);
		},

		onBlurRange($event)
		{
			// console.log("onBlurRange(): ", $event);

			// this.$emit('blur', $event.target.value);
		},

		onChangeRange(value)
		{
			//console.log("onChangeRange(): ", value);

			this.$emit('input', this.dateRangeValue);		//TODO: This is needed to bind back to the model
			this.$emit('change', this.dateRangeValue);
		},
	}
}
</script>





<style scoped lang="scss">
@import "/assets/sass/_global.scss";
</style>
<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<a v-bind:href="href" :class="['c-link', cssClass]" v-on:click="onClick">{{text}}</a>
</template>





<script>
export default {

	emits: ["click"],
	
	props: [
		'id',
		'css-class',
		'href',
		'text',

		'type'		// Standard, primary, secondary, etc.
	],





	data()
	{
		return {

		};
	},





	computed: {
		
	},





	methods: {
		onClick()
		{
			this.$emit('click', {});
		}
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
</style>
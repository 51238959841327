import commonApiCalls from "/helpers/commonApiCalls";
import apis from "/api";

const jobHelpers = {
	
	//TODO: This is quick for initial development. Ideally we want to get this from the API... Do we?
	getJobStatuses: function()
	{
		var jobStatuses = [
			{ Value: "NotStarted", Text: "Not Started" },
			{ Value: "InProgress", Text: "In Progress" },
			{ Value: "Completed", Text: "Completed" },
			{ Value: "ReOpened", Text: "Re-Opened" },
			{ Value: "Cancelled", Text: "Cancelled" },
			{ Value: "Deleted", Text: "Deleted" },
		];

		return jobStatuses;
	},






	getAllJobTasksInJob(appRoot, requestData, vueDataValue, callback)
	{
		appRoot.callApi("/Jobs/GetAllJobTasksInJob", requestData, "GET")
		.then((responseData) => {
			//console.log("getAllJobTasksInJob(): responseData=", responseData);
			if (callback) callback(false);
			if (responseData == null) return; // Not successful


			commonApiCalls.setArrayValue(vueDataValue, responseData.JobTasks, callback)


			if (callback) callback(true);
		});

	},




	getAllJobCommentaryInJob(appRoot, requestData, vueDataValue, callback)
	{
		appRoot.callApi("/Jobs/GetAllJobCommentaryInJob", requestData, "GET")
		.then((responseData) => {
			//console.log("getAllJobCommentaryInJob(): responseData=", responseData);
			if (callback) callback(false);
			if (responseData == null) return; // Not successful


			commonApiCalls.setArrayValue(vueDataValue, responseData.JobCommentary, callback)


			if (callback) callback(true);
		});

	},








	getAllCountriesInAccount(appRoot, vueDataValue, callback)
	{
		var requestData = {
		};

		appRoot.callApi("/Countries/GetAllCountries", requestData, "GET")
		.then((responseData) => {
			//console.log("getAllCountriesInAccount(): responseData=", responseData);
			if (callback) callback(false);
			if (responseData == null) return; // Not successful

			commonApiCalls.setArrayValue(vueDataValue, responseData.Countries, callback)

			if (callback) callback(true);
		});
	},





	getCountryById(countries, countryId)
	{
		var country = null;

		countries.forEach((item, index) => {
			if (item.ID.toLowerCase() == countryId.toLowerCase())
			{
				country = item;
				return;
			}
		});

		return country;
	},

	

	saveJob(jobData)
	{
		console.log("saveJob(): jobData=", jobData);
		//return; // For testing

		apis.callApi("/Jobs/CreateOrUpdateJob", jobData, "POST")
		.then((responseData) => {
			console.log("saveJob(): jobData=", jobData, " | responseData=", responseData);

			this.isSaving = false;
			
			if (responseData == null) return; // Not successful
		});
	},



	getJobsInAccountForDate(accountId, dateIso, dateUtcOffset, callback)
	{
		if (!dateIso)
		{
			// One of the dates is blank, so cannot proceed otherwise we get stuck with nothing.
			return;
		}
		
		var requestData = {
			accountId: accountId,

			pageNumber: -1,
			pageSize: 100,

			FromDateLocal: dateIso,
			ToDateLocal: dateIso,

			AllocationDateUtcOffset: dateUtcOffset
		};

		console.log("getJobsInAccountForDate(): requestData=", requestData);
		//return;
		
		apis.callApi("/Jobs/GetJobsInAccount", requestData, "GET")
		.then((responseData) => {
			console.log("getJobsInAccountForDate(): responseData=", responseData);

			if (responseData == null) return; // Not successful

			var jobs = [];
			if (responseData.data && responseData.data.Jobs) jobs = responseData.data.Jobs;

			if (callback) callback(jobs);
		});
	},



	copyDemoJobs(forDate, forUtcOffset)
	{
		console.log("copyDemoJobs(): forDate=", forDate);
		console.log("copyDemoJobs(): forUtcOffset=", forUtcOffset);

		var requestData = {
			AllocationDateLocal: forDate,
			AllocationDateUtcOffset: forUtcOffset
		};

		apis.callApi("/Jobs/CopyDemoJobs", requestData, "POST")
		.then((responseData) => {
			console.log("getJobsInAccountForDate(): responseData=", responseData);

			if (responseData == null) return; // Not successful
		});


	},





};


export default jobHelpers;
<template>
	<span>
		<!-- No drop-down -->
		<el-button :id="id" :name="getName" 
			value="initialValue" 
			:class="['c-utility-button', cssClass]" 
			:style="getCssStyle"
			:type="getDisplayType"
			@click="onClick" 
			v-if="isDropDown == false" 
			:disabled="disabled ? true : false">
				<span class="icon"><slot name="icon"></slot></span>
				<span class="text"><slot name="text"></slot></span>
		</el-button>

		<!-- Has drop-down -->
		<el-button :id="id" :name="getName" 
			value="initialValue" 
			:class="['c-utility-button', cssClass]" 
			:style="getCssStyle"
			@click="onClick" 
			v-if="isDropDown == true"
			data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :disabled="disabled ? true : false">
			<span class="icon"><slot name="icon"></slot></span>
			<span class="text"><slot name="text"></slot></span>
			<span class="drop-down"><i class="fas fa-angle-down"></i></span>
		</el-button>
		<div class="dropdown-menu" :aria-labelledby="id" id="select-drop-dopwn" v-if="isDropDown == true">
			<span class="text"><slot name="drop-down"></slot></span>
		</div>
	</span>
</template>





<script>
import buttonPropsMixin from "./button-props-mixin";
import allFormElementPropsMixin from "../forms/all-form-element-props-mixin";

export default {

	mixins: [allFormElementPropsMixin, buttonPropsMixin],



	computed: {
		getDisplayType()
		{
			//NOTE: Had to add this when upgrading to Vue 3 and switching from emenet-ui to element-plus.
			if (this.displayType == "standard") return "default";

			return this.displayType;
		}
	},


	mounted()
	{
		//console.log("utility-button(): type=", this.type);
		this.displayType = this.type;
	},
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";

	

	.c-utility-button
	{
		.icon
		{
			display: inline-block;
			padding-right: 4px;
		}

		.text
		{
			display: inline-block;
		}


		.drop-down
		{
			display: inline-block;
			padding-left: 12px;
		}
	}




	.component-button-secondary button{
		color:white;
		letter-spacing: 2px;
	}
	.component-button-secondary img{
		margin-top:-4px;
		display: inline-block;
	}


	.component-button-primary button{
		color:white;
		letter-spacing: 2px;
	}
	.component-button-primary img{
		margin-top:-4px;
		display: inline-block;
	}





</style>
<template>
	<button
		:id="id" 
		:name="getName"
		:value="initialValue" 
		:class="['c-button-cancel','c-native-button', getClass]" 
		:style="getCssStyle"
		:type="displayType"
		:plain="isPlain"
		:disabled="disabled ? true : false"
		:native-type="nativeType"
		:size="getSize"
		@click="onClickNative"
		v-if="origin=='native'"
	>
		<!-- <span class="icon" v-if="$slots.icon"><slot name="icon"></slot></span>
		<span class="text"><slot name="text"></slot></span> -->
		
		<span class="icon" v-if="hasIcon()"><slot name="icon"></slot></span>
		<span class="text" v-if="hasText()"><slot name="text"></slot></span>

	</button>
</template>





<script>
import buttonPropsMixin from "./button-props-mixin";
import allFormElementPropsMixin from "../forms/all-form-element-props-mixin";

export default {

	mixins: [allFormElementPropsMixin, buttonPropsMixin],



	props: {
		// Where is the underlying button coming from. Just needed while we transition from element to native and have to test.
		origin: {
			type: String,
			default: "native"	// "element" (<el-button>) or "native" (<button>)
		},
	},


	

	mounted()
	{
		this.displayType = "info"; 
		this.isPlain = true; 
	},





	methods: {
		hasIcon()
		{
			//Ref: https://stackoverflow.com/questions/50133153/get-rendered-html-contents-of-vue-slot-as-variable
			//Ref: https://eslint.vuejs.org/rules/require-slots-as-functions.html
			// Part 1) Ensure the slot is actually included in the calling component.
			// Part 2) Check there is HTML content within the slot in the calling component.
			const hasValue = !!this.$slots['icon'] && this.$slots.icon().length > 0;
			return hasValue;
		},

		hasText()
		{
			//Ref: https://stackoverflow.com/questions/50133153/get-rendered-html-contents-of-vue-slot-as-variable
			//Ref: https://eslint.vuejs.org/rules/require-slots-as-functions.html
			// Part 1) Ensure the slot is actually included in the calling component.
			// Part 2) Check there is HTML content within the slot in the calling component.
			const hasValue = !!this.$slots['text'] && this.$slots.text().length > 0;
			return hasValue;
		}
	}

}
</script>





<style scoped lang="scss">
@import "./shared-styles.scss";
</style>



<style scoped lang="scss">
	.icon {
		margin-right: 10px;
	}


	.c-native-button
	{
		background-color: rgb(244, 244, 245);
		border: 1px solid rgb(200, 201, 204);
		border-radius: 4px;

		color: rgb(144, 147, 153);
		font-family: CircularStd, Montserrat, sans-serif;
		font-size: 14px;
		font-weight: 500;
		
		padding: 4px;
		padding-left: 16px;
		padding-right: 16px;
	}


	// Copied from <el-button> type="primary"
	.c-native-button:hover
	{
		// background-color: rgb(64, 158, 255, .7);

		color: #ffffff;
		border-color: #909399;
		background-color: #909399;
		outline: 0;
	}

	// Copied from <el-button> type="primary"
	.c-native-button:active {
		color: #ffffff;
		border-color: #73767a;
		background-color: #73767a;
		outline: 0;
		
	}



	.c-native-button:disabled
	{
		background-color: rgb(215, 215, 215);
		border: 1px solid rgb(215, 215, 215);

		color: rgb(255, 255, 255);
	}
</style>
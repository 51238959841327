<template>
	<span>
		<input type="text" 
			v-if="showInput == true"
			:id="id" 
			:name="getName" 
			:value="modelValue"
			:class="['expanding-text expanding-text-short', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '']" :style="getCssStyle"
			@change="onChange"
			@input="onInput"
			@blur="onBlur"
			:placeholder="placeholder"
			data-lpignore="true">
		
		<textarea 
			v-if="showTextArea == true"
			:id="id" 
			:name="getName" 
			:class="['expanding-text expanding-text-long', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '']" 
			:style="getCssStyle"
			:value="modelValue"
			@change="onChange"
			@input="onInputTextArea"
			@blur="onBlur"></textarea>
		
	</span>
</template>





<script>
//Note: `data-lpignore="true"` to stop LastPass icon
import allFormElementPropsMixin from "./all-form-element-props-mixin";


export default {

	mixins: [allFormElementPropsMixin],

	emits: ["change", "input", "blur", "update:modelValue"],

	props: {
		
		initialValue: {
			type: String
		},
		

		placeholder: {
			type: String,
			required: false,
			default: ""
		},


		//TODO: New with Vue 3
        modelValue: {
            type: String,
            default: '',
            required: true
        },





	},





	data()
	{
		return {
			value: "",

			showInput: true,
			showTextArea: false,

			textWidth: 0,
			inputWidth: 0,
			cursorPosition: 0
		};
	},




	//TODO: on windows resize, recalculate the widths.

	mounted()
	{
		this.value = this.initialValue;

		this.determineWhichInputFieldToShow();
	},




	watch: {
		value: function(to, from)
		{
			//this.textWidth = this.getTextWidth();
			this.determineWhichInputFieldToShow();
		},

		initialValue: function(to, from)
		{
			this.value = this.initialValue;
		}
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		},

		showTextWidth()
		{
			return this.getTextWidth();
		}
	},





	methods: {
		onClick()
		{
			//this.$emit('click', {});
		},


		onInput($event)
		{
			//console.log("expanding-text.onInput(): $event=", $event.target.value, $event);

			this.$emit('input', $event.target.value);

			this.$emit('update:modelValue', $event.target.value);
		},


		//onInputTextArea(value)
		onInputTextArea($event)
		{
			//console.log("expanding-text.onInputTextArea(): $event=", $event.target.value, $event);

			this.$emit('input', $event.target.value);

			this.$emit('update:modelValue', $event.target.value);
		},


		onChange($event)
		{	
			this.$emit('change', $event.target.value);
		},


		onBlur($event)
		{
			//this.$emit('blur', $event.target.value);
		},





		getTextWidth()
		{
			//Ref: https://stackoverflow.com/a/44314626/115704

			var input = document.getElementById(this.id);
			var fontFamily = "";
			var fontSize = "";
			
			if (input != null && input != undefined)
			{
				// Ref: https://stackoverflow.com/questions/30674207/how-do-i-get-the-font-style-font-family-from-an-element
				// Ref: https://stackoverflow.com/questions/44302717/get-input-text-width-when-typing
				fontFamily = getComputedStyle(input).getPropertyValue("font-family");
				fontSize = getComputedStyle(input).getPropertyValue("font-size")
				//console.log("Input.font", fontFamily, fontSize);
			}
			
			var c = document.createElement("canvas");
			//var c = document.getElementById('canvas');

			c.font = fontSize + " " + fontFamily;
			var ctx = c.getContext("2d");
			var width = ctx.measureText(this.value).width;

			var roundUp = 1.35;	// Hack because the .width doesn't seem right

			width = width * roundUp;

			return width;
		},





		getInputWidth()
		{
			var input = document.getElementById(this.id);

			if (!input) return 0;

			var width = input.clientWidth;

			return width;
		},





		determineWhichInputFieldToShow()
		{
			this.textWidth = this.getTextWidth();
			this.inputWidth = this.getInputWidth();


			var changed = false;

			if (this.textWidth > this.inputWidth)
			{
				// Text width is longer than the length of the single-line input. Show the textarea.
				if (this.showInput == true)
				{
					this.showInput = false;
					this.showTextArea = true;

					changed = true;
				}
			}
			else
			{
				// Show single line input.
				if (this.showTextArea == true)
				{
					this.showInput = true;
					this.showTextArea = false;

					changed = true;
				}

			}
			
			//TODO: Under development
			// Keep the cursor position in the switched text box.
			if (changed == true)
			{
				
				this.cursorPosition = this.getCursorPosition();
				// console.log("CHANGED!!! this.cursorPosition=", this.cursorPosition);
				setTimeout(() => {
					this.setCaretPosition(this.id, this.cursorPosition);
				}, 200);
			}
		},





		getCursorPosition()
		{
			var ctl = document.getElementById(this.id);

			if (!ctl) return 0;

			var startPos = ctl.selectionStart;
			var endPos = ctl.selectionEnd;

			//console.log("Cursor:", startPos + ", " + endPos);

			return startPos;
		},





		setCaretPosition(elemId, caretPos) 
		{
			var elem = document.getElementById(elemId);

			//console.log("setCaretPosition(): caretPos=", caretPos);

			if(elem != null) 
			{
				if(elem.createTextRange) 
				{
					//console.log("setCaretPosition(): 2");

					var range = elem.createTextRange();
					range.move('character', caretPos);
					range.select();
				}
				else 
				{
					if(elem.selectionStart) 
					{
						//console.log("setCaretPosition(): 3");

						elem.focus();
						elem.setSelectionRange(caretPos, caretPos);
					}
					else
					{
						//console.log("setCaretPosition(): 4");

						elem.focus();
					}
				}
			}
			else
			{
				//console.log("setCaretPosition(): 1");
			}
		}
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
	
	.expanding-text
	{
		width: 100%;
	}



	.expanding-text-short
	{
		// This is to approximately match the styling of the select list (and I presume text box) in Element UI
		padding: 10px;
		border-radius: 4px;

		border: 1px solid #DCDFE6;
	}
	.expanding-text:focus
	{
		border-color: #409EFF;
	}

	.expanding-text-long
	{
		// This is to approximately match the styling of the select list (and I presume text box) in Element UI
		padding: 10px;
		border-radius: 4px;

		border: 1px solid #DCDFE6;
	}
	.expanding-text-long:focus
	{
		border-color: #409EFF;
	}



	#text-width-test
	{
		// visibility: hidden;
		display: inline-block;
	}
</style>
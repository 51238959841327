<template>
    <span class="lotus-only-content" title="Lotus Only Content"><i class="fas fa-eye-slash"></i></span>
</template>





<script>
export default {

}
</script>





<style lang="scss" scoped>
    .lotus-only-content
    {
        font-size: 50%;
    }
</style>
<template>
	<div class="page-container">
		
		<div class="title-nav-outer-container">
			<div class="container title-nav-inner-container">
				<div class="row">
					<div class="col-2">
						<img width="1161" height="335" src="https://lotusai.co/wp-content/uploads/2022/05/logo_white.svg" class="logo-image" alt="" loading="lazy">
					</div>
					<div class="col-10">
						<a class="nav-link nav-link-white" href="https://lotusai.co">Home</a>
						<a class="nav-link nav-link-gold" href="https://lotusai.co/contact-us">Contact Us</a>
					</div>
				</div>
			</div>
		</div>


		<div class="container">
			<!-- // Page Content -->
			<div style="flex: 1; margin-bottom: 10px;">
				<slot></slot>
			</div>
		</div>	

    </div>
</template>



<script> 
// This layout is designed to be consistent with the general feel of the primary domain (https://lotusai.co/).
// Initial developed for the /create-account page so it doesn't feel jarring for users when they come from a 
// call to action in the public site.

export default {
	name: "DomainConsistentLayout",

	data() {
		return {

		};
	},



	mounted() {
		console.log("DomainConsistentLayout.mounted()");
	},

	methods: {
		
	}
}
</script>





<style lang="scss" scoped>
	.page-container
	{
		height: 100vh; 
		display: flex; 
		flex-direction: column
	}




	.title-nav-outer-container
	{
		background-color: #4D5670;
	}
	.title-nav-inner-container
	{
		padding-top:20px;
		padding-bottom: 10px;
	}

	.logo-image
	{
		width: 88%;
		height: auto;
	}



	.nav-link
	{
		padding: 10px 25px 0px 15px;
		
		font-family: "Montserrat", Sans-serif;

		font-size: 15px;
		
		transition: all .4s ease;
		text-transform: none;
		font-weight: 500;
		font-weight: 700;
		letter-spacing: normal;

		display: inline-block;

		//height: 100%;
	}
	.nav-link-white
	{
		color: #FFFFFF;
	}
	
	.nav-link-gold
	{
		color: #E3C165;
	}
</style>
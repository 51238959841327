<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div :id="id" :class="['c-spacer', getClasses()]">
	</div>
</template>





<script>
export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},



		// fluid: {
		// 	type: Boolean,
		// 	required: false,
		// 	default: true
		// },




	},





	data()
	{
		return {

		};
	},



	// watch: {
	// 	currentPageNumber: function (to, from) {
	//
	// 	}
	// },






	mounted()
	{
		
	},





	computed: {
		
	},





	methods: {
		getClasses()
		{
			// console.log("getLayoutTypeClass() fluid=", this.fluid);
			// if (this.fluid) return "layout-fluid";

			// return "layout"

			return "horizontal-1"
		}
	}
}
</script>





<style scoped lang="scss">
	.horizontal-1
	{
		display: inline-block;
		width: 4px;
	}
</style>
<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div>
		<h1 :id="id" :class="['c-heading', cssClass, getHeadingTypeClass]" v-if="type == 'standard' && level == 1"><slot></slot></h1>
		<h2 :id="id" :class="['c-heading', cssClass, getHeadingTypeClass]" v-if="type == 'standard' && level == 2"><slot></slot></h2>
		<h3 :id="id" :class="['c-heading', cssClass, getHeadingTypeClass]" v-if="type == 'standard' && level == 3"><slot></slot></h3>
		<h4 :id="id" :class="['c-heading', cssClass, getHeadingTypeClass]" v-if="type == 'standard' && level == 4"><slot></slot></h4>
		<h5 :id="id" :class="['c-heading', cssClass, getHeadingTypeClass]" v-if="type == 'standard' && level == 5"><slot></slot></h5>


		<h3 :id="id" :class="['c-heading', 'text-primary', cssClass, getHeadingTypeClass]" v-if="type == 'report-sub-heading'"><slot></slot></h3>
	</div>
</template>





<script>
export default {
	emits: ["click"],
	
	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},


		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},
		
		
		level: {
			type: Number,
			require: true
		},
		

		
		/**
		 * Standard, Page Title, Page Section Title, Form Title
		 */
		type: {
			type: String,
			required: false,	
			default: "standard"	// report-sub-heading
		}
	},





	data()
	{
		return {

		};
	},





	mounted()
	{
		//console.log("heading.mounted(): type=", this.type);
	},





	computed: {
		getHeadingTypeClass()
		{
			if (this.type == "page-title") return "page-title";

			return "";
		}
	},





	methods: {
		onClick()
		{
			this.$emit('click', {});
		}
	}
}
</script>





<style scoped lang="scss">
@import "/assets/sass/_global.scss";

h1
{
	font-family: $font-bold;
	font-size: 28pt;
}

h2
{
	font-family: $font-bold;
	font-size: 24pt;
}

h3
{
	font-family: $font-bold;
	font-size: 20pt;
}

h4
{
	font-family: $font-bold;
	font-size: 16pt;
	font-weight: bold;
}


h5
{
	font-family: $font-bold;
	font-size: 12pt;
	font-weight: bold;
}


</style>
<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div :id="id" :class="['c-grid', getLayoutTypeClass()]">
		<slot></slot>
	</div>
</template>





<script>
export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},



		fluid: {
			type: Boolean,
			required: false,
			default: true
		},




	},





	data()
	{
		return {

		};
	},



	// watch: {
	// 	currentPageNumber: function (to, from) {
	//
	// 	}
	// },






	mounted()
	{
		
	},





	computed: {
		
	},





	methods: {
		getLayoutTypeClass()
		{
			//console.log("getLayoutTypeClass() fluid=", this.fluid);
			if (this.fluid) return "container-fluid";

			return "container";
		}
	}
}
</script>





<style scoped lang="scss">
	.c-grid
	{
		// Override the Bootstrap (I think) styles currently used.
		padding-left: 0;
		padding-right: 0;

		// width: 100%;
	}
</style>
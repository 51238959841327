<template>
	<div class="no-layout-container">
		<slot></slot>
	</div>
</template>



<script>
export default {
	name: "NoLayout",

	data() {
		return {

		};
	},



	mounted() {
		console.log("NoLayout.mounted()");
	},




	// watch: {
	// 	$route(to, from) {
	// 		this.handleRoute();
	// 	}
	// },




	methods: {
		
	}
}
</script>





<style lang="scss" scoped>
	.no-layout-container
	{
		/*
		TODO: This is a bit of a hack to layer the DIV rather than do a proper layout (#app root element has yellow background still set that we need to cover).
		*/
		width: 100%;
		height: 100%;
		background-color: white;

		// position: fixed;
		// top: 0;
		// left: 0;
		// right: 0;
		// bottom: 0;
	}
</style>
<template>
	<div>
		<slot></slot>
	</div>
</template>



<script>
export default {
	name: "PublicLayout",

	data() {
		return {

		};
	},



	mounted() {
		//console.log("PublicLayout.mounted()");
	},




	// watch: {
	// 	$route(to, from) {
	// 		this.handleRoute();
	// 	}
	// },




	methods: {
		
	}
}
</script>

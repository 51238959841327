<template>
	<div class="container" style="height: 100vh; display: flex; flex-direction: column">

			<div style="display: flex; margin-top: 4px">
				<div style="flex: 0 0 100px;">
					<a href="/home" title="Return to home page">
						<img src="https://assets.lotusai.co/portal/images/logo-white-icon.png" alt="Lotus AI logo" class="header-logo mb-4" style="height: 40px;" title="Lotus AI">
					</a>
				</div>
				<div style="flex: 1;">
					<el-card id="primary-top-nav-card" class="box-card" style="width: 100%;">
						<div style="display: flex;">
							<div style="flex: 0 0 200px;">
								<page-title>Knowledgebase</page-title>
							</div>
							<div style="flex: 1;" class="text-right">
								Need more help? <a href="/support" style="color: blue">Request Support</a>.
							</div>
						</div>
					</el-card>
				</div>
			</div>

			<!-- // Page Title -->
			<div id="page-title-container" style="width: 100%; margin-bottom: 20px;">
				<c-layout-container>
					<page-title>
						<span class="kb-title"></span>
					</page-title>
				</c-layout-container>
			</div>

			<!-- // Page Content -->
			<div style="flex: 1; margin-bottom: 10px;">
				<slot></slot>
			</div>
			
    </div>
</template>



<script>
export default {
	name: "KnowledgebaseLayout",

	


	data() {
		return {

		};
	},



	mounted() 
	{

	},


	created() 
	{

	},





	methods: {


	}
}
</script>





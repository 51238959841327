<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<!-- No drop-down -->
	<el-button 
		:id="id" :name="getName"
		value="initialValue" 
		:class="['c-button', cssClass]" 
		:style="getCssStyle"
		:type="displayType"
		:plain="isPlain"
		@click="onClick" 
		v-if="isDropDown == false" 
		:disabled="disabled ? true : false"
		:native-type="nativeType"
		:size="getSize"
	>
		<span class="icon" v-if="$slots.icon"><slot name="icon"></slot></span>
		<span class="text"><slot name="text"></slot></span>
	</el-button>

	<!-- Has drop-down -->
	<el-button 
		:id="id" :name="getName" 
		value="initialValue" 
		:class="['c-button', cssClass]" 
		:style="getCssStyle"
		:type="displayType"
		:plain="isPlain"
		@click="onClick" 
		v-if="isDropDown == true"
		:disabled="disabled ? true : false"
		:native-type="nativeType"
		:size="getSize"
		data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" 
	>
		<span class="icon" v-if="$slots.icon"><slot name="icon"></slot></span>
		<span class="text"><slot name="text"></slot></span>
		<span class="drop-down"><i class="fas fa-angle-down"></i></span>
	</el-button>
	<div class="dropdown-menu" :aria-labelledby="id" id="select-drop-dopwn" v-if="isDropDown == true">
		<span class="text"><slot name="drop-down"></slot></span>
	</div>

</template>





<script>
//TODO: Deprecate and remove this.
/**
 * 
 * 
 * 					NOTE: [@jason, 8 May 2021] Don't use this component now. Use one of the `c-button-*` buttons instead.
 * 							We're going for specific button components to make it easier to know what to use in different
 * 							situations.
 * 
 * 
 */

import buttonPropsMixin from "./button-props-mixin";
import allFormElementPropsMixin from "../forms/all-form-element-props-mixin";

export default {


	mixins: [allFormElementPropsMixin, buttonPropsMixin],



	mounted()
	{
		//* Values include: "standard" (default); "primary"; "secondary"/"info"; "teriary" (muted), "danger", "warning".
		if (this.type.toLowerCase() == "primary".toLowerCase())
		{
			this.displayType = "primary";
		}
		else if (this.type.toLowerCase() == "secondary".toLowerCase())
		{
			this.displayType = "info";
		}
		else if (this.type.toLowerCase() == "info".toLowerCase()) 
		{
			this.displayType = "info";
		}
		else if (this.type.toLowerCase() == "tertiary".toLowerCase()) 
		{ 
			this.displayType = "info"; 
			this.isPlain = true; 
		}
		else if (this.type.toLowerCase() == "danger".toLowerCase())
		{
			this.displayType = "danger";
		}
		else if (this.type.toLowerCase() == "warning".toLowerCase())
		{
			this.displayType = "warning";
		}
		else 
		{
			this.displayType = "default";	// "standard" and "default"
		}
	},





	// computed: {
	// 	getBtnClass() {
	// 		if (this.type == "standard") return "btn-link";
	// 		// if (this.type == "primary") return "btn-primary";
	// 		// if (this.type == "secondary") return "btn-secondary";
	// 		// if (this.type == "tertiary") return "btn-light";
	// 		// if (this.type == "link") return "btn-link";
			
			
	// 		return "btn-link";
	// 	},

	// },

}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";

	

	.c-button
	{
		.icon
		{
			display: inline-block;
			padding-right: 4px;
		}

		.text
		{
			display: inline-block;
		}


		.drop-down
		{
			display: inline-block;
			padding-left: 12px;
		}
	}




	.component-button-secondary button{
		color:white;
		letter-spacing: 2px;
	}
	.component-button-secondary img{
		margin-top:-4px;
		display: inline-block;
	}


	.component-button-primary button{
		color:white;
		letter-spacing: 2px;
	}
	.component-button-primary img{
		margin-top:-4px;
		display: inline-block;
	}





</style>


<style lang="scss">
// .el-button+.el-button 
// {
//     margin-left: 10px;
// }

// span>.el-button + span>.el-button 
// {
//     margin-left: 10px;
// }
</style>



<style scoped lang="scss">
	.icon {
		margin-right: 10px		
	}
</style>
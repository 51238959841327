<template>
	<i :class="['c-icon', cssColorClass, getIconCssClass()]" :id="id"></i>
</template>





<script>
export default {

	emits: ["click"],
	
	props: {

		/**
		 * Unique code/name identifying the icon to display
		 */
		code: {
			type: String,
			required: true,
			default: ""
		},
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * The field's `name` value. 
		 * If not defined, `id` is used.
		 */
		name: {
			type: String
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},


		/**
		 * Name of the global CSS class contianing the colour to apply to the icon.
		 */
		cssColorClass: {
			type:String
		}
		
	},





	data()
	{
		return {

		};
	},





	computed: {
		getCode()
		{
			if (this.code == undefined) return '';

			return this.code.toLowerCase();
		},

	},





	methods: {
		onClick()
		{
			this.$emit('click', {});
		},


		getIconCssClass()
		{
			var iconCode = this.code;
			if (iconCode == undefined) return "";

			iconCode = iconCode.toLowerCase();

			// Calendar
			if (iconCode == "calendar") return "far fa-calendar-alt";
			// Time / Clock
			if (iconCode == "time") return "far fa-clock";
			// Budget / Amount
			if (iconCode == "budget") return "fas fa-dollar-sign";
			// Search
			if (iconCode == "search") return "fas fa-search";
			// Export
			if (iconCode == "export") return "fas fa-sign-in-alt";
			// History
			if (iconCode == "history") return "fas fa-history";
			// Change / Undo
			if (iconCode == "change") return "fas fa-undo-alt";
			// Non-Billable Time
			if (iconCode == "non-billable-time") return "fas fa-file-invoice-dollar";
		}
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
</style>
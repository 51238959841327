<template>
	<div class="c-layout-container">
		<el-card class="box-card disable-overflow" v-if="type == 'card'">	
			<slot></slot>
		</el-card>
		<div v-if="type == 'none'">	
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			type: String,
			default: "card"
		}
	}
}
</script>

<style lang="scss" scoped>
	//TODO: [jsnelders, 27/01/2022] Untested across the portal. Quickly implemented to fix timesheet bucke details pane not sticking in edit timesheet page.
	.disable-overflow
	{
		// We need to "disable" overflow so "position: sticky;" works in child elements.
		overflow: inherit;
	}
</style>
<template>
	<!-- NOTE: Derived from PrimaryLayout -->
	<!-- NOTE: Used for paged embedded in a web view in the native mobile app -->
	<div class="container-fluid_nope">

		<div class="inner-container">

			<div class="col-12_nope" style="margin: 5px;">
				<!-- // Navigation -->
				<!-- // 	V1: With logo -->
				<div class="row" v-if="1==2">
					<div class="col-12 col-md-11" >
						<el-card id="primary-top-nav-card" class="box-card" style="margin-bottom: 10px; margin-top: 10px;">
							<primary-top-nav context="mobile-app"></primary-top-nav>
						</el-card>
					</div>
					<div class="col-12 col-md-1 text-center icon-container">
						<img src="https://assets.lotusai.co/portal/images/logo-white-icon.png" alt="Lotus AI logo" class="header-logo mb-4" style="max-height: 50px; margin-top: 25px" title="Lotus AI">
					</div>
				</div>

				<!-- // 	V2: No logo -->
				<div class="row" v-if="1==2">
					<div class="col-12" >
						<el-card id="primary-top-nav-card" class="box-card" style="margin-bottom: 10px; margin-top: 10px;">
							<primary-top-nav context="mobile-app"></primary-top-nav>
						</el-card>
					</div>
				</div>




				<!-- // Page Title -->
				<div id="page-title-container" class="page-title-container" v-if="pageTitle" style="width: 100%; margin-bottom: 20px;">
					<c-layout-container class="page-title-card" height="auto">
						<page-title>{{pageTitle}}</page-title>
					</c-layout-container>
				</div>



				<!-- // Page Content -->
				<div class="page-content-container" v-if="1==2">
					<div class="default-layout" v-if="layoutType == 'default'">
						<div class="row">
							<div class="col-12 pb-3">
								<c-layout-container id="primary-layout-page-content-card" type="card" v-if="layoutContainer == 'card'">
									<slot></slot>
								</c-layout-container>
								<c-layout-container id="primary-layout-page-content-none" type="none" v-if="layoutContainer == 'none'">
									<slot></slot>
								</c-layout-container>
							</div>
						</div>
					</div>
				</div>
				<div class="page-content-container">
					<div class="default-layout">
						<div class="row">
							<div class="col-12 pb-3">
								adsf
								<slot></slot>
							</div>
						</div>
					</div>
				</div>

				
				<!-- <div class="mt-2 pb-2" id="footer-layout-container">
					<c-layout-container class="page-title-card" height="auto">
						<layout-footer></layout-footer>
					</c-layout-container>
				</div> -->
            </div>
        </div>
    </div>
</template>



<script>
import globalStore from '/globalStore';
import layoutMixin from "./layout-mixin";
//import layoutFooter from "./footer";	//TODO: Remove
import accountAdminMixin from "/account-admin/account-admin-mixin";


export default {
	name: "MobileAppEmbeddedViewLayout",

	emits: ["child-event"],

	mixins: [layoutMixin, accountAdminMixin],

	// components: {
	// 	'layout-footer': layoutFooter,	//TODO: Remove
	// },



	props: [
		'subPageTitle'
	],

	data() {
		return {
			globalStore: globalStore,

			pageTitle: "",
			pageSubTitle: "",

			section: "",
			sectionLayoutClass: "",

			layoutType: "",	// The "layout-type" specified for the page in the route meta. "account-admin" or "" (default)

			layoutContainer: "", // specified in route meta data. Default is "card"
		};
	},



	mounted() 
	{
		this.init();
	},


	created() 
	{
		this.init();
	},





	methods: {


		init() 
		{
			this.pageTitle = this.$route.meta.pageTitle;

			//console.log("this.$route.meta.layout=", this.$route.meta.layout);
			//this.layoutType = this.$route.meta.layout;

			this.layoutType = this.$route.meta.layoutType;
			if (!this.layoutType) this.layoutType = "default";

			this.layoutContainer = this.$route.meta.layoutContainer;
			//console.log("layoutContainer=", this.layoutContainer);

			if (!this.layoutContainer) this.layoutContainer = "card";
			//console.log("layoutContainer 2=", this.layoutContainer);
			
			// Check the user is [still] logged in.
			this.$root.checkCurrentAuthTokenStillValid();


			// We want to maintain the Compass Gold background to fit with the mobile app.
			document.body.classList.add('bg-override-lotus-gold');


			// this.$on("child-event", this.handleChildEvent);	//TODO: Remove

			this.$parent.$emit('child-event', 'mobile-app layout emit');
		},



		onPageTitle(data)
		{
			console.log("PrimaryLayout.onPageTitle(): data=", data);
		},



		//TODO: Remove
		// handleChildEvent(data)
		// {
		// 	console.log("PrimaryLayout.handleChildEvent(): data=", data);
		// }

		



	}
}
</script>






<style lang="scss">
	@import "./shared.scss";
</style>

<style lang="scss" scoped>
	.inner-container
	{
		//TDOO: With the variable #app background this is causing issues
		//margin-top: 5px;	
		padding-top: 5px;	//.. but this  does the trick.
	}



	.page-title-container
	{
		// border-bottom: 1px solid rgba(0,0,0,0.065);
	}

	
	#primary-top-nav-card
	{
		overflow: inherit;
	}


</style>

<style lang="scss">


	//TODO: Review these now we have "c-layout-container"
	#page-title-container .page-title-card 
	{
		.el-card__body
		{
			padding: 15px;
			padding-left: 20px;
		}
	}


	#primary-top-nav-card
	{
		.el-card__body
		{
			padding: 10px;
			// padding-left: 20px;
		}
	}



	#footer-layout-container
	{
		//-- Stick and always visible at the bottom.
		// position: sticky; 
		// bottom: 0;
		// z-index: 10000;	// Make sure it's on top of everything (problem: Element IO drop downs slide behind it.)

		//NOTE: Decided not to have it sticky. Doesn't quite feel right.
	}

	#footer-layout-container .el-card__body
	{
		width: 100%;
		
		padding-top: 10px;
		padding-bottom: 10px;
	}



	@media screen and (max-width: 767px) {
		.header-logo
		{
			height: 40px;
			margin-top: 5px !important;
			margin-bottom: 5px !important;
		}

		.icon-container
		{
			padding: 0;
		}
	}


	body.bg-override-lotus-gold
	{
		background-color: #ffb600 !important;
	}
</style>
<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<dialog :id="id" :class="['c-modal', 'size-message-' + getSize, cssClass]" ref="myDialog">
		<header class="text-left">
			<h2 class="modal-header-text">
				<slot name="header"></slot>
			</h2>
		</header>

		<main v-if="type == 'modal'" class="text-left">
			<div v-if="msg">
				{{ msg }}
			</div>
			<div v-if="!msg">
				<slot></slot>
			</div>
		</main>

		<main v-if="type == 'alert'" class="text-left">
			<div v-if="msg">
				{{ msg }}
			</div>
			<div v-if="!msg">
				<slot></slot>
			</div>
		</main>

		<main v-if="type == 'confirm'" class="text-left">
			<div v-if="msg">
				{{ msg }}
			</div>
			<div v-if="!msg">
				<slot></slot>
			</div>
		</main>


		<footer class="text-right">
			<c-button-cancel id="modal-close-button" class="modal-close-button mr-2" @click="closeModal('close')" v-if="canShowCancelButton">
				<template v-slot:text>{{ cancelButtonText }}</template>
			</c-button-cancel>

			<c-button-standard id="modal-ok-button" class="modal-close-button mr-2" @click="closeModal('ok')" v-if="canShowOkButton">
				<template v-slot:text>{{okButtonText}}</template>
			</c-button-standard>

			<c-button-standard id="modal-save-button" class="modal-close-button mr-2" @click="closeModal('save')" v-if="canShowSaveButton">
				<template v-slot:text>{{saveButtonText}}</template>
			</c-button-standard>

			<c-button-danger id="modal-delete-button" class="modal-close-button mr-2" @click="closeModal('delete')" v-if="canShowDeleteButton">
				<template v-slot:text>{{deleteButtonText}}</template>
			</c-button-danger>




			<c-button-danger id="modal-archive-button" class="modal-close-button mr-2" @click="closeModal('archive')" v-if="canShowArchiveButton">
				<template v-slot:text>{{archiveButtonText}}</template>
			</c-button-danger>
			
			<c-button-standard id="modal-restore-button" class="modal-close-button mr-2" @click="closeModal('restore')" v-if="canShowRestoreButton">
				<template v-slot:text>{{restoreButtonText}}</template>
			</c-button-standard>




			<c-button-standard id="modal-yes-button" class="modal-close-button" @click="closeModal('confirm')" v-if="canShowYesNoButton">
				<template v-slot:text>{{yesButtonText}}</template>
			</c-button-standard>
			<c-button-secondary id="modal-no-button" class="modal-close-button ml-1" @click="closeModal('decline')" v-if="canShowYesNoButton">
				<template v-slot:text>{{noButtonText}}</template>
			</c-button-secondary>
		</footer>
	</dialog>
</template>










<script>
//Initial prototype: https://stackblitz.com/edit/vue3-app-rnk7bj?file=src%2Fcomponents%2Fc-modal2.vue,src%2FApp.vue

export default {

	props: {

		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		msg: {
			type: String,
			default: ""
		},

		// Trigger this modal to be opened from a parent component.
		trigger: {
			type: String,
			default: ""		// "open" and "closed"
		},

		// The type of modal this is. Types include:
		// - "modal": a normal modal (pass in any content to display or interact with).
		// - "alert": a read-only message to the user with just the option to close.
		// - "confirm": a confirmation message requiring either a "yes" or "no" style response.
		type: {
			type: String,
			default: "modal"
		},

		// A pre-defined size that is dependancy also on teh provided `type`. Sizes include:
		// - "small" 
		// - "medium"
		// - "large"
		// - "larger"
		// Later we can have:
		// - "": Not use. Defaults to "auto" unless a custom CSS is passed in.
		// - "auto": Fluid and handled by HTML rendering
		size: {
			type: String,
			default: ""
		},




		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: [String, Array],
			default: ""
		},





		//---------- Default Buttons ----------//
		/**
		 * Show the "OK" button?
		 */
		showOkButton: {
			type: Boolean,
			required: false,
			default: false
		},
		okButtonText: {
			type: String,
			required: false,
			default: "OK"
		},


		/**
		 * Show the "Save" button?
		 */
		showSaveButton: {
			type: Boolean,
			required: false,
			default: false
		},
		saveButtonText: {
			type: String,
			required: false,
			default: "Save"
		},

		/**
		 * Should the modal automatically close when the save button is clicked?  
		 * Set to `false` to haver the parent control perform a close via the `trigger` prop.  
		 * Example: when hosting a form and we need to modal to stay open if validation fails.
		 */
		closeOnSave: {
			type: Boolean,
			required: false,
			default: false	// `false` because generally the save button will in the context of the modal hosting a form.
		},


		/**
		 * Show the "Cancel" button? (can also be used for a simple "Cancel" or "Close")
		 */
		showCancelButton: {
			type: Boolean,
			required: false,
			default: false
		},
		cancelButtonText: {
			type: String,
			required: false,
			default: "Cancel",		//"Discard changes" as a default modals with a form
		},
		

		/**
		 * Show the "Delete" button?
		 */
		showDeleteButton: {
			type: Boolean,
			required: false,
			default: false
		},
		deleteButtonText: {
			type: String,
			required: false,
			default: "Delete"
		},


		/**
		 * Show the "Archive" button?
		 */
		showArchiveButton: {
			type: Boolean,
			required: false,
			default: false
		},
		archiveButtonText: {
			type: String,
			required: false,
			default: "Archive"
		},


		/**
		 * Show the "Restore" button?
		 */
		showRestoreButton: {
			type: Boolean,
			required: false,
			default: false
		},
		restoreButtonText: {
			type: String,
			required: false,
			default: "Restore"
		},


		/**
		 * Show the "Yes" and "No" button?
		 */
		showYesNoButtons: {
			type: Boolean,
			required: false,
			default: false
		},
		yesButtonText: {
			type: String,
			required: false,
			default: "Yes"
		},
		noButtonText: {
			type: String,
			required: false,
			default: "No"
		},
	},





	data()
	{
		return {
            dialogVisible: false,
		};
	},





	watch: { 
		trigger: function(newVal, oldVal) {
		
			// Triggered cleared for next usage.
			//if (newVal == "") return;

			//console.log("watch::trigger(): newVal=", newVal);

			// No value passed in
			if (!newVal) return;


			if (newVal.toLowerCase() == "open") 
			{
				this.showModal();
				return;
			}

			if (newVal.toLowerCase() == "closed")
			{
				// Nothing to do. Just resetting the trigger state...
				// No! When we pass in a close even triggered by something in the custom
				// content (e.g. a custom button), we need to call the trigger handler.
				this.handleCloseTrigger();
				return;
			}
		}
	},


	emits: ["action"],




	






	mounted()
	{
		// this.dialogVisible = this.state;

		this.init();
	},





	computed: {
		testX()
		{
			return "testX";
		},

		getSize()
		{
			//console.log("modal2.getSize(): this.size=", this.size);
			if (this.size == "") return "small";
			if (this.size == "small") return "small";
			if (this.size == "medium") return "medium";
			if (this.size == "large") return "large";
			if (this.size == "larger") return "larger";
			if (this.size == "80pc") return "80pc";
			
			console.error(`modal2: Unhandled value '${this.size}' for 'size' prop.`);
			return "small2";
		},



		canShowOkButton()
		{
			if (this.showOkButton) return true;

			return false;
		},

		canShowCancelButton()
		{
			//console.log("this.showCancelButton=", this.showCancelButton);
			if (this.showCancelButton) return true;

			return false;
		},

		canShowSaveButton()
		{
			if (this.showSaveButton) return true;

			return false;
		},

		canShowDeleteButton()
		{
			if (this.showDeleteButton) return true;

			return false;
		},

		canShowArchiveButton()
		{
			if (this.showArchiveButton) return true;

			return false;
		},

		canShowRestoreButton()
		{
			if (this.showRestoreButton) return true;

			return false;
		},
		
	

		canShowYesNoButton()
		{
			if (this.showYesNoButtons) return true;

			return false;
		},
	},





	methods: {
		init()
		{
			// Detect when 'Esc' key is used (that's fine),
			// and ensure a 'close' event is emitted.
			const _this = this;
			this.$refs.myDialog.addEventListener('cancel', (event) => {
				if (_this.type == 'confirm') _this.$emit('action', { action: 'close', isEsc: true });
				
				_this.$emit('action', { action: 'close', isEsc: true });
			});
		},







		
		showModal() 
		{
			this.$refs.myDialog.showModal();  // Vue 2
		},



		// Perform the actual close of the modal from a trigger.
		// Don't emit event - that's already happened (to cause the return trigger).
		handleCloseTrigger() 
		{
			this.$refs.myDialog.close();  // Vue 2
		},


		//-- For a standard modal and a "message alert"

		closeModal(response)
		{
			let autoClose = true;
			if (response == "save" && this.closeOnSave == false)
			{
				// Not auto-closing when clicking the save button.
				autoClose = false;
			}

			// Automatically close this modal.
			if (autoClose) this.$refs.myDialog.close();  // Vue 2

			this.$emit('action', { action: response });
		},

	}
}
</script>






<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
	// Emulate Bootstrap .text-right
	.text-left {
		text-align: left;
	}

	.text-right {
		text-align: right;
	}

	.text-centre {
		text-align: centre;
	}


	/*html {
		font-family: 'montserrat', sans-serif;
		}*/

	.c-modal {
		padding: 0;
		border: 1px solid #ddd;
		border-radius: 2px;

		// Change top positioning
		// position: absolute;
		// top: 25%;
		// transform: translateY(-100%);	//TODO: This fucks up top position.
	}

	dialog.c-modal::backdrop {
		background-color: rgba(0, 0, 0, 0.7);
	}





	header {
		padding: 6px;

		margin-bottom: 10px;

		text-align: left;

		/*border-bottom: 1px solid black;*/
		background-color: #c1daeb;
	}

	.modal-header-text {
		margin: 0;

		font-size: 1em;
		font-weight: bold;
	}






	main {
		padding: 6px;
	}






	footer {
		padding: 6px;

		margin-top: 10px
			/*border-top: 1px solid black;*/
	}



	.modal-message {
		font-family: 'quicksand', sans-serif;
		color: #868a8e;
	}

	.modal-close-button {}








	.size-auto {}


	.size-small {
		width: 600px;
	}

	.size-medium {
		width: 800px;
	}

	.size-large {
		width: 1200px;
	}

	.size-larger {
		width: 1500px;
	}




	/** Size defaults for "modal" type dialogs. */
	.size-modal-small {
		width: 600px;
	}

	.size-modal-medium {
		width: 800px;
	}

	.size-modal-large {
		width: 1200px;
	}


	.size-modal-80pc {
		width: 80%;
	}


	/** Size defaults for "alert" and "confirm" message type dialogs. */
	
	.size-message-80pc {
		width: 80%;
	}

	.size-message-small {
		width: 400px;
	}

	.size-message-medium {
		width: 600px;
	}

	.size-message-large {
		width: 800px;
	}

	.size-message-80pc {
		width: 80%;
	}
</style>

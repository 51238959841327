<template>
	<span>
		<el-select
			v-model="value"
			:style="getCssStyle"
			:disabled="disabled"
			filterable
			reserve-keyword
			placeholder="Type to find & select a bucket"
			@change="onChangeEl"
			>
			<el-option
				v-for="bucket in bucketsWithFlatHierarchy"
				:key="bucket.ID"
				:label="bucket.Label"
				:value="bucket.ID">
			</el-option>
		</el-select>

		<!-- //TDOO: 4 Nov 2011 -  Adding this here just so the actual SELECT list will render. Seems to act as a trigger need to figure out how to do this correctly -->
		<!-- <span style="display: none">
			{{buckets.length}}
		</span> -->
		

		<span class="validation-text" v-if="isValid == false && validationMessage != ''">
			<small style="color: red" v-html="validationMessage"></small>
		</span>
	</span>
</template>





<script>
import allFormElementPropsMixin from "./forms/all-form-element-props-mixin";

//TODO: 5 Nov 2020 - Some hacking around to try and get the list to render. 
//						Ended up going with the watch. Work OK on a hard page load (eg. in development, F5 refresh)
//						but in dev if updating this component then saving in VS Code it loses the list.
//					Nope! FFS! It doesn' work when I have a whole set these controls on the host page (e.g in the original "keywords" page in system admin).
//					OK, so I've got it on created() as well as the 'bucket' watch

export default {


	mixins: [allFormElementPropsMixin],



	emits: ["input", "change", "update:modelValue"],

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * The field's `name` value. 
		 * If not defined, `id` is used.
		 */
		name: {
			type: String
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},



		/**
		 * Inline CSS styles to apply
		 */
		cssStyle: {
			type: String
		},



		/**
		 * The rendered size of the field.  
		 * Valid values are: "standard" (default), "small", "large"
		 */
		size: {
			type: String
		},
		


		initialValue: {
			type: String
		},


		/**
		 * Set this from the parent page/component when wanting to 
		 * programatically update the selected value.
		 */
		updateValue: {
			type: String
		},
		

		//TODO: Make this obsolete in favour of the pre-formatted and sorted `list` just being passed in.
		/**
		 * An array of Bucket objects.
		 */
		buckets: {
			type: Array
		},

		
		/**
		 * The list of items (objects) representing buckets, in the format { ID: "", Label: "" } and sorted.
		 */
		list: {
			type: Array,
			default: function()
			{ 
				return [];
			}
		},



		bucketStructure: {
			type: String,
			default: "standard"		// or "summary" (//TODO: Obsolete)
		},




		isValid: {
			type: Boolean,
			default: true
		},

		validationMessage: {
			type: String,
			default: ""
		}
	},





	data()
	{
		return {
			value: [],

			bucketsWithFlatHierarchy: [],

			// For Element UI
			loading: false,
			options: [],
		};
	},






	mounted()
	{
		this.value = this.initialValue;

		// console.log("bucket-select.mounted(): initialValue=", this.initialValue);
		// console.log("bucket-select.mounted(): buckets=", this.buckets);
		// console.log("bucket-select.mounted(): bucketStructure=", this.bucketStructure);

		//TODO: 4 Nov 2020 - This is a HACK to get rendering to happen on pages with masses of data being loaded (Eg. system admin keywords page for PFS).
		//this.$forceUpdate();
	},



	created()
	{
		this.load();
		// console.log("bucket-select.created(): bucketsWithFlatHierarchy=", this.bucketsWithFlatHierarchy);
	},




	watch: {
		'buckets': function(to, from)
		{
			console.log("bucket-select.watch(buckets): from=", from, "to=", to); 

			this.bucketsWithFlatHierarchy = this.createFlatHierarchyStandard(this.buckets);
		},

		'initialValue': function(to, from)
		{
			console.log("bucket-select.watch(initialValue): from=", from, "to=", to); 

			this.value = this.initialValue;
		},

		'updateValue': function(to, from)
		{
			console.log("bucket-select.watch(updateValue): from=", from, "to=", to); 

			this.value = this.updateValue;
		}
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		},
	},





	methods: {

		load()
		{
			// console.log("bucket-select.load(): list.length=", this.list);
			// console.log("bucket-select.load(): buckets.length=", this.buckets);

			if (this.list.length > 0)
			{
				// Pre-build list to just render. Array of objects like { ID: "", Label: "" }
				this.bucketsWithFlatHierarchy = this.list;
			}
			else
			{
				// Generate ther select list from a standard array of Bucket ojects.
				this.bucketsWithFlatHierarchy = this.createFlatHierarchyStandard(this.buckets);
			}
			
		},


		onChange()
		{
			this.$emit('input', this.value);
			this.$emit('change', this.value);

			// this.$emit('input', $event.target.value);
			// this.$emit('change', $event.target.value);

			this.$emit('update:modelValue', this.value);
		},


		onChangeEl()
		{
			// console.log("onChangeEl(): ", value);

			this.$emit('input', this.value);
			this.$emit('change', this.value);

			this.$emit('update:modelValue', this.value);
		},


		//TODO: Make this obsolete in favour of passing in a pre-build `list` of items.
		createFlatHierarchyStandard(items)
		{
			var list = [];

			if (items == undefined)
			{
				return [];
			}

			items.forEach(item => {
				
				var label = item.FullPathTitle;

				// Use FullBucketsOnlyPath instead if it's available.
				// Probably should have a prop to control which property is used.
				if (item.Ext && item.Ext.FullBucketsOnlyPath) label = item.Ext.FullBucketsOnlyPath;

				list.push({
					ID: item.ID,
					Label: label,
				});
			});

			
			// Sort alphabetically
			list.sort((a, b) => (a.Label.toLowerCase() > b.Label.toLowerCase()) ? 1 : -1);

			return list;
		},



		// For Element UI to do a dynamic search/lookup.
		remoteMethod(query) 
		{
			if (query !== '') 
			{
				this.loading = true;

				setTimeout(() => {
					this.loading = false;

					this.options = this.bucketsWithFlatHierarchy.filter(item => {
						return item.Label.toLowerCase().indexOf(query.toLowerCase()) > -1;
					});
				}, 200);
			} 
			else 
			{
				this.options = [];
			}
		}






	}
}
</script>





<style scoped lang="scss">
	
	.c-bucket-select
	{
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
	}
</style>
<template>
	<div :class="['c-info-text', cssClass, sizeCssClass]" :style="cssStyle">
		<slot></slot>
	</div>
</template>





<script>
// Informational text in support of an input field, heading, link... whatever

export default {

	props: {

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},


		/**
		 * Additional CSS "style" values to apply.  
		 */
		cssStyle: {
			type: String
		},


		size: {
			style: String,
			default: "small"
		}

	},





	mounted() 
	{
		
	},




	data()
	{
		return {
			
		};
	},





	computed: {
		sizeCssClass()
		{
			//console.log("this.size.toLowerCase()=", this.size.toLowerCase());

			//Note: [@jason, 15 Aug 2023] All these size variations seemed like a good idea at the time.
			//		What... a year - 2 years - later and it's either "small" or not small. Over engineered.

			if (this.size.toLowerCase() == "50%") return "size-50";
			if (this.size.toLowerCase() == "60%") return "size-60";
			if (this.size.toLowerCase() == "75%") return "size-75";
			if (this.size.toLowerCase() == "80%") return "size-80";
			if (this.size.toLowerCase() == "90%") return "size-90";
			if (this.size.toLowerCase() == "100%") return "size-100";

			if (this.size.toLowerCase() == "small") return "size-75";
			if (this.size.toLowerCase() == "normal") return "size-100";

			// Let's defined some standard step downs in terms of font size relative to the parent.
			//TODO: [jsnelders, 2022-02-16] Just guessing at the moment.
			if (this.size.toLowerCase() == "down-1") return "size-90";
			if (this.size.toLowerCase() == "down-2") return "size-75";
			if (this.size.toLowerCase() == "down-3") return "size-60";

			return "size-100";
		}
	},





	methods: {

	}
}
</script>




<style lang="scss">
/* 
	Globally scoped standard CSS for a custom `.c-info-text` that is used to replace the Bootstrap 4.x `.text-muted`
	styling. This is the same as the scoped .c-info-text in this component, but where we need to apply it as a 
	normal class to other elements as well.
	We're using a custom over the Bootstrap class for greater control, especially when we need to upgrade 
	(or move away from) Bootstrap and retain control over breaks.

	NOTE: This component will need to be globally registered to ensure this global class is available everywhere.
*/
@import "/assets/sass/_global.scss";

.c-info-text
{
	font-family: $font-standard;
	// font-size: 12pt;	// Inherit the size, and control changes in implementation from parent
	
	//color: rgba(34, 47, 56, 0.75) !important;	//The original Bootstrap `.text-muted` class colour
	//color: #222f38;
	//color: #121416;
	color: rgba(0, 0, 0, 0.7);

	font-weight: normal;

	font-size: small;

	//cursor: pointer;
}
</style>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";

	//NOTE: This is the original c-info-text styling, scoped to the component. But it's probably
	//		not needed now we have the globlly scoped above. 
	//TODO: Test removing this and see if everything is still OK.
	//TODO: Obsolete in scoped setting. See global scope.
	// .c-info-text
	// {
	// 	font-family: $font-standard;
	// 	// font-size: 12pt;	// Inherit the size, and control changes in implementation from parent
		
	// 	//color: rgba(34, 47, 56, 0.75) !important;	//The original Bootstrap `.text-muted` class colour
	// 	//color: #222f38;
	// 	//color: #121416;
	// 	color: rgba(0, 0, 0, 0.70);

	// 	font-weight: normal;

	// 	font-size: small;

	// 	//cursor: pointer;
	// }


	.size-normal,
	.size-100
	{
		font-size: 100%;
	}

	.size-90
	{
		font-size: 90%;
	}

	.size-80
	{
		font-size: 80%;
	}


	.size-75
	{
		font-size: 75%;
	}
	

	.size-60
	{
		font-size: 60%;
	}
	

	.size-50
	{
		font-size: 50%;
	}

	//-- Original design. Colour too light and font size too small.
	// font-size: 12px;
	// font-weight: normal;
	// opacity: 0.4;

</style>
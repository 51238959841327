<template>
	<div class="outer-container">
		<div class="header-bar">
			<div>
				<div class="text-left" style="display: inline-block; width: 48%;" @click="onTopNavBarToggleMenu">
					<div class="menu-icon-container">
						<span style="cursor: pointer;">
							<i class="fas fa-bars menu-icon"></i>
						</span>
					</div>
				</div>
				<div class="text-right" style="display: inline-block; width: 50%;">
					<span style="padding-right: 10px;">Welcome, {{globalStore.state.currentUser.firstName}}</span>
					<img src="https://assets.lotusai.co/portal/images/logo-gold-transparent.png" alt="" class="menu-bar-logo" style="height: 25px">
				</div>
			</div>
		</div>


		<div class="layout-container">
			<!-- // SIDEBAR + NAVIGATION // -->
			<div :class="{'sidebar': true, 'closed' : isSideNavClosed, 'mobile-open': !isSideNavClosed && isMobile}">
				<div class="sidebar-header">
					<div v-if="1==2">
						<img src="https://assets.lotusai.co/portal/images/logo-gold-transparent.png" alt="Lotus AI logo" class="mb-4" title="Lotus AI" style="max-height: 50px; margin-top: 25px;">
					</div>
					<div style="width: 100%; text-align: center;">
						<a href="/" class="logo-link">
							<h1 class="lotus-gold-color" style="font-size: 1.5em; font-weight: bold;">{{ moduleTitle }}</h1>
							<h2 style="font-size: 1.2em; text-transform: uppercase;">{{ moduleSubTitle}}</h2>
						</a>
					</div>

					<hr class="sidebar-hr">

				</div>

				<div class="sidebar-content">
					<!-- Content for the sidebar goes here -->
					<primary-side-navigation></primary-side-navigation>
				</div>

				<div class="pinned-area pinned-area-sidebar">
					<hr class="sidebar-hr">

					<div class="mb-2">
						<div style="display:inline-block; margin-right: 10px;">
							<div class="avatar-circle">
								<a href="/my-profile" style="color: inherit">
									<span class="avatar-letter">{{currentUserInitials()}}</span>
								</a>
							</div>
						</div>
						<a href="/my-profile" style="color: inherit">
							<span style="font-weight: bold; font-size: 20px;">{{currentUserDisplayName()}}</span>
						</a>
					</div>
					<div class="mb-1" style="font-size: .9em;">
						Need help? <a href="mailto:support@lotusai.co" class="need-help-link">Contact support</a>
					</div>
				</div>
			</div>

			<!-- // MAIN CONTENT // -->
			<div :class="{'content': true, 'mobile-open': !isSideNavClosed && isMobile}">
				<div>
					<c-layout-container type="none" class="page-title-card mb-4 mt-1" height="auto" v-if="canShowPageTitle()">

						<el-page-header @back="goBack">
							<template #content>
								<span class="mr-3" style="font-size: 1em; font-weight: 600;">
									<span v-html="getPageTitle"></span>
								</span>
							</template>
						</el-page-header>
					</c-layout-container>

					<!-- //TODO: Implement -->
					<slot name="pageTitle"></slot>

					<!-- //TODO: Implement -->
					<slot name="breakcumbs"></slot>
				</div>

				<!-- // Page Content -->
				<div class="content-area">
					<div class="content-inner">
						<!-- Main content goes here -->
						<c-layout-container id="primary-layout-page-content-card" type="none">
							<slot></slot>
						</c-layout-container>

						<!-- //TODO: Remove this -->
						<!-- <div style="width: 100%; height: 1780px;">Test content area</div> -->
					</div>
				</div>
				
				<div class="pinned-area pinned-area-main-content" style="text-align: right;">
					<span class="mr-1" style="padding-right: 6px;">Powered by</span>
					<img src="https://assets.lotusai.co/portal/images/white-logo-horizontal-test-removebg-preview.png" alt="" style="height: 50px">
					<layout-footer v-if="1==2"></layout-footer>
				</div>
			</div>
		</div>
	</div>
</template>



<script>
import globalStore from '/globalStore';
import layoutMixin from "./layout-mixin";
import layoutFooter from "./footer.vue";
import primarySideNavigation from "/layouts/navigation/primary-side-nav.vue";
import accountAdminMixin from "/account-admin/account-admin-mixin";
import Primary2SideBar from "./Primary2Sidebar.vue";


export default {
	name: "PrimaryLayout",

	emits: ["child-event"],

	mixins: [layoutMixin, accountAdminMixin],

	components: {
		'layout-footer': layoutFooter,
		'primary-side-navigation': primarySideNavigation,
		'primary-side-bar' : Primary2SideBar
	},

	props: [
		'subPageTitle'
	],



	data() {
		return {
			globalStore: globalStore,

			pageTitle: "",
			pageSubTitle: "",

			isMobile: false,

			isSideNavClosed: false,
			triggerClosedMenu: false,
			triggerOpenMenu: false,

			section: "",
			sectionLayoutClass: "",

			layoutType: "",	// The "layout-type" specified for the page in the route meta. "account-admin" or "" (default)

			layoutContainer: "", // specified in route meta data. Default is "card"


			//-- For "account-admin" layouts
			accessAccountId: "",




			moduleTitle: "Tessta",
			moduleSubTitle: "Client Portal"
			

		};
	},



	mounted() 
	{
		this.init();
	},


	created() 
	{
		window.addEventListener("resize", this.onWindowResize);

		this.init();
		
	},

	// This is to derive the pageTitle from route, which will change.
	// This works, but using computed instead.
    // watch: {
	// 	$route(to, from) {
	// 		this.pageTitle = to.meta.pageTitle;
	// 	}
	// },

	computed: {
		getPageTitle() {
			var _this = this;

			var pageTitleRaw = this.$route.meta.pageTitle;

			var pathParts = pageTitleRaw.split(' > ');

			var finalTitle = "";
			pathParts.forEach((part) => {
				if (finalTitle != "")
				{
					//finalTitle = finalTitle + ' <i class="fas fa-angle-right"></i> ';	
					finalTitle = finalTitle + ' ' + _this.getPathSeparator() + ' ';	
				}
				finalTitle = finalTitle + part;
			});

			//return pageTitleRaw;
			return finalTitle;
		},
	},


	methods: {


		init() 
		{
			this.pageTitle = this.$route.meta.pageTitle;

			//console.log("this.$route.meta.layout=", this.$route.meta.layout);
			//this.layoutType = this.$route.meta.layout;

			this.layoutType = this.$route.meta.layoutType;
			if (!this.layoutType) this.layoutType = "default";

			this.layoutContainer = this.$route.meta.layoutContainer;

			if (!this.layoutContainer) this.layoutContainer = "card";
			
			// Check the user is [still] logged in.
			this.$root.checkCurrentAuthTokenStillValid();


			// this.$on("child-event", this.handleChildEvent);	//TODO: Remove

			this.$parent.$emit('child-event', 'primary layout emit');


			this.authoriseAccessIfSystemAdmin();

			// Make sure side menu is closed when loading on mobile.
			this.onWindowResize();




			// Module title
			const route = this.$route;
			let module = route.meta.module ? route.meta.module : "";
			//console.log("*** Primary2Layout(): module=", module, " | route=", route);
			
			//TODO: This is quick and dirty. We need a better module level config for things like this.
			if (module == "tesstaClients")
			{
				// "Tessta Client Portal"
				this.moduleTitle = "Tessta";
				this.moduleSubTitle = "Client Portal";
			}
			else
			{
				// Everything else ("Lotus Portal")
				this.moduleTitle = "Lotus";
				this.moduleSubTitle = "Portal";
			}



			this.initForAccountAdmin();
		},




		/**
		 * When the screen width slides down into mobile width range, auto close the side menu.  
		 * When it slides back up into desktop width range, auto re-open the side menu.
		 */
		onWindowResize()
		{
			const mobileMaxWidth = 820;
			this.isMobile = window.innerWidth < mobileMaxWidth;

			// Mobile width
			if (this.isMobile)
			{
				// Menu close already triggered. Don't do it again.
				if (this.triggerClosedMenu) return;
				this.isSideNavClosed = true;	// Close the side meny.
				this.triggerClosedMenu = true;

				this.triggerOpenMenu = false;
				return;
			}

			// Desktop width
			else if (!this.isMobile)
			{
				this.triggerClosedMenu = false;
				this.isSideNavClosed = false;	// Re-open the side menu.
				if (this.triggerOpenMenu) return;
				this.triggerOpenMenu = true;
			}

		},





		canShowPageTitle()
		{
			const route = this.$route;
			let module = route.meta.module ? route.meta.module : "";
			//console.log("nav(): module=", module, " | route=", route);
			
			module = module.toLowerCase();

			if (module == "tesstaClients".toLowerCase()) return false;


			return true;
		},



		getPathSeparator()
		{
			return '<i class="fas fa-long-arrow-alt-right"></i>';
		},


		initForAccountAdmin()
		{
			this.accessAccountId = this.getCurrentAccountAdminAccountId();

			if (this.isAccountAdmin())
			{
				this.loadAccount();		// From: accont-admin-mixin.js
			}
		},





		isAccountAdmin()
		{
			//TODO: Do this properly, with a feature access check. But for the moment it's fine.
			//		(Note: the `layoutType == 'account-admin'` probably doesn't need to change in the HTML part of this file when we change this method)
			return this.layoutType == 'account-admin';
		},


		/**
		 * Authorise user access to this page(s) if trying to access /system-admin.
		 */
		authoriseAccessIfSystemAdmin()
		{
			var path = this.$root.getCurrentRoutePath();
			//console.log("authoriseAccessIfSystemAdmin(): path=", path);

			var isSystemAdminPage = path.startsWith("/system-admin/");
			//console.log("authoriseAccessIfSystemAdmin(): isSystemAdminPage=", isSystemAdminPage);

			if (isSystemAdminPage == false)
			{
				// This page is not for system admin, so skip this check and keep loading.
				return;
			}

			if (this.$root.isSystemAdminUser() == false)
			{
				// User cannot access system admin pages, so rediect to the standard home page.
				this.$root.redirectToHomePage();
				
				return;
			}
			
			// console.log("SystemAdmin: Has access");
		},



		onPageTitle(data)
		{
			//console.log("PrimaryLayout.onPageTitle(): data=", data);
		},


		//TODO: Remove
		// handleChildEvent(data)
		// {
		// 	console.log("PrimaryLayout.handleChildEvent(): data=", data);
		// },

		
		getContentContinerType()
		{
			//console.log("contentContinerType()");
			if (this.layoutContainer == 'card') return "card";
			if (this.layoutContainer == 'none') return "none";

			return "card";
		},




		onTopNavBarToggleMenu()
		{
			if (this.isSideNavClosed)
			{
				this.isSideNavClosed = false;
			}
			else
			{
				this.isSideNavClosed = true;
			}
		
		},





		goBack()
		{
			history.back();
		},




		currentUserInitials()
		{
			// console.log("globalStore.state.currentUser=", this.globalStore.state.currentUser);
			var first = "";
			if (this.globalStore.state.currentUser.firstName.length > 0)
			{
				first = this.globalStore.state.currentUser.firstName.substring(0, 1);
			}
			
			var last = "";
			if (this.globalStore.state.currentUser.lastName.length > 0)
			{
				last = this.globalStore.state.currentUser.lastName.substring(0, 1);
			}

			return first + "" + last;
		},


		currentUserDisplayName()
		{
			// console.log("globalStore.state.currentUser=", this.globalStore.state.currentUser);
			var first = "";
			if (this.globalStore.state.currentUser.firstName.length > 0)
			{
				first = this.globalStore.state.currentUser.firstName;
			}
			
			var last = "";
			if (this.globalStore.state.currentUser.lastName.length > 0)
			{
				last = this.globalStore.state.currentUser.lastName.substring(0, 1);
			}

			return first + " " + last;
		}



	}
}
</script>






<style lang="scss">
	@import "./shared.scss";
</style>

<style lang="scss" scoped>
	.inner-container
	{
		//TDOO: With the variable #app background this is causing issues
		//margin-top: 5px;	
		padding-top: 5px;	//.. but this  does the trick.
	}


	
	#primary-top-nav-card
	{
		overflow: inherit;
	}


</style>




<style lang="scss" scoped>
/* Reset some default styles for consistent rendering */
body,
html {
	margin: 0;
	padding: 0;
	height: 100%;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


// See https://chatgpt.com/share/e3c37264-de62-4afe-adbe-841aee4c2bae for layout help received.
// with the header, content and 2 columns.

.outer-container
{
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.header-bar
{
	background-color: white;
	color: #333;
	// display: flex;
	// flex-direction: row;
	position: relative;

	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 10px;
}





/* Create the main container for the layout */
.layout-container {
	display: flex;
	
	// height: 100vh;
	/* 100% of the viewport height */

	flex-grow: 1;  /* Fills the remaining space after the header */
}

// .not-collapse {	
//     width: 300px; 
// }








/* Style the sidebar */
.sidebar {
	background-color: white;
	color: #333;
	display: flex;
	flex-direction: column;
	position: relative;

	overflow: hidden;
	transition: all 0.5s ease;
	/* Hide overflowing content when collapsed */
	// transition: width 0.3s ease;
	/* Smooth width transition */

	width: 300px; 
}

.collapse-parent {
	width: 28px;
    height: 28px;
    display: inline-flex;
}

.sidebar-hr
{
	width: 80%;

    height: 2px;
    background-color: #ededed;
    border: none;
}

.sidebar-collaps{
	color: var(--default-color, #33394A);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}


.sidebar-content {
	flex-grow: 1;
	overflow: auto;
	/* Enable scrolling if content overflows */
}


/* ---- */

/* Show the sidebar in collapsed state */
.sidebar.closed {
	width: 0px;
	/* Collapsed width */
}

/* ---- */
//TODO: "Collapsed" state is now obsolete
//----- Collapsed state -----//

// /*  Hide text for collapsed navigation items */
// .sidebar.collapsed .text {
// 	display: none;
// }


// /* Show the sidebar in collapsed state */
// .sidebar.collapsed {
// 	width: 70px;
// 	/* Collapsed width */
// }

// //TODO: Not implemented. But this is what I'm thinking when in a collapsed state and someone opens a top-level menu. The sidebar needs to widen.
// .sidebar.collapsed.sub-items-open {	
//     width: 300px; 
// }


// .sidebar.collapsed .sidebar-content {
// 	width: 70px;
// 	/* Adjust the collapsed width as needed */
// }


// .sidebar.collapsed .icon {
// 	font-size: 24px;
// 	/* Adjust the icon size as needed */
// }


// .sidebar.collapsed #sidebar-toggle::before {
// 	content: "→";
// }


// /* Hide the toggle button text in collapsed state */
// .sidebar.collapsed #sidebar-toggle #sidebar-toggle-text {
// 	display: none;
// }





.sidebar-header {
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* background-color: #444; */
	background-color: transparent;
}


#sidebar-toggle::before {
	content: "←";
}


.sidebar-content {
	flex-grow: 1;
	overflow-y: auto;
	transition: width 0.3s ease;
	/* Smooth width transition */
}










/* Style the main content area */
.content {
	width: 50%;
	flex-grow: 1;
	/* Expand to fill available horizontal space */
	background-color: #F8F8F8;
	padding: 20px;
	padding-bottom: 0px;
	display: flex;
	flex-direction: column;
	position: relative;
}


.content-area {
	flex-grow: 1;

	// We need this (and not whole page scrolling) to ensure the 
	// static bottom footer works correctly.
	//overflow: auto;

	
	// [jsnelders, 6 Sept 2024]: this should hide when content is shorter than the window, but it doesn't. 
	// 		Not sure why and I don't have time to find out.
	// [jsnelders, 20 Sept 2024]: If we disable this and the `overflow: auto;` above, then the scroll on this element
	//		disappears and we have a window level scroll. I think that's OK for now.
	// overflow-y: auto;

	/* overflow: auto;  */
	/* Enable scrolling if content overflows */


	// background-color: white;
	/* border: 2px solid white;  */
	border-radius: 10px; 
	padding: 10px;

	//[jason, 9 Feb 2024]: I've added this so we don't see content running behind and avoce the sticky header
	// for the `simple-table` component.
	padding-top: 0;

}
.content-inner {
	//[jsnelders, 20 Sept 2024]: I jave a feeling I wanted to do something stiucky at the bottom of the page, but... meh.
	// height: 100%; /* Allow content to fill the container */
	// overflow: auto;
	padding-right: 10px; /* Create space for the scrollbar without shifting content */
}
/* Custom style the scrollbar */
/* width */
.content-inner::-webkit-scrollbar {
	width: 9px;
	height: 9px;
}

/* Track */
.content-inner::-webkit-scrollbar-track {
	background: #f1f1f1;
	border-radius: 3px; /* Border radius of the thumb */
}

/* Handle */
.content-inner::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 3px; /* Border radius of the thumb */
}

/* Handle on hover */
.content-inner::-webkit-scrollbar-thumb:hover {
	background: #555;
}





/* Style the pinned areas */
.pinned-area {
	height: 100px;
	min-height: 100px;
	background-color: #ccc;
}

.pinned-area-sidebar {
	height: 120px;
	min-height: 120px;

	background-color: transparent;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.pinned-area-main-content {
	height: 60px;
	min-height: 60px;
	
	background-color: transparent;
	
	display: flex;
	flex-direction: row;
    align-content: center;
    align-items: center;

	justify-content: flex-end;
}

.navigation-item {
	display: flex;
	align-items: center;
	padding: 10px;
	cursor: pointer;
	transition: padding 0.3s ease;
	/* Smooth padding transition */
}

.cur-pointer {
	cursor: pointer;
}


.sidebar.collapsed .navigation-item {
	padding: 10px 5px;
	/* Adjust padding for collapsed state */
}


.icon {
	margin-right: 10px;
	font-size: 20px;
}

.expand-icon {
	background: url('https://assets.lotusai.co/portal/icons/expand.svg') no-repeat;
}

.collapse-icon {
	background: url('https://assets.lotusai.co/portal/icons/collapse.svg') no-repeat;
}

/* Need to change collapse

.navigation-item {
	justify-content: center;
	padding: 10px 10px !important;
}
.navigation-item .icon{
	margin-right: 0px;
}

*/


.text {
	display: inline-block;
}



.need-help-link
{
	color: #F0CF7A;
}



.lotus-gold-color
{
	color: #ffb600 !important;
}



.avatar-circle {
	width: 40px;
	height: 40px;
	background-color: #F0CF7A; /* Circle color */
	border-radius: 50%; /* Makes it a circle */
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-weight: bold;
	color: white; /* Letter color */
}











	//TODO: Review these now we have "c-layout-container"
	#page-title-container .page-title-card 
	{
		.el-card__body
		{
			padding: 15px;
			padding-left: 20px;
		}
	}


	#primary-top-nav-card
	{
		.el-card__body
		{
			padding: 10px;
			// padding-left: 20px;
		}
	}



	// #footer-layout-container
	// {
	// 	//-- Stick and always visible at the bottom.
	// 	// position: sticky; 
	// 	// bottom: 0;
	// 	// z-index: 10000;	// Make sure it's on top of everything (problem: Element IO drop downs slide behind it.)
	//
	// 	//NOTE: Decided not to have it sticky. Doesn't quite feel right.
	// }

	#footer-layout-container .el-card__body
	{
		width: 100%;
		
		padding-top: 10px;
		padding-bottom: 10px;
	}



	@media screen and (max-width: 767px) {
		.header-logo
		{
			height: 40px;
			margin-top: 5px !important;
			margin-bottom: 5px !important;
		}

		.icon-container
		{
			padding: 0;
		}
	}



	.logo-link
	{
		color: inherit;
	}



	.show-top-nav-bar {
		display: block;
	}



	.sidebar.mobile-open
	{
		width: 100%;
		max-width: 500px;   // On tablet sized we don't have it full width.
	}
	.content.mobile-open
	{
		width: 0;
		display: none;
	}


</style>

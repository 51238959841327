<template>
	<slot></slot>
</template>





<script>
/*
Ref: https://github.com/fiduswriter/Simple-DataTables
*/

export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String,
			required: false
		},
	},


	data()
	{
		return {

		};
	},






	mounted()
	{
		
	},





	computed: {

	},





	methods: {
		init()
		{

		},
	}
}
</script>





<style lang="scss">
@import "./standard-table.scss";

</style>
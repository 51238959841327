<template>
	<div :class="['c-task-select']" v-if="isVisible">
		<input type="text" 
			:id="id + '-free-text'" :name="getName" 
			:class="['c-task-select-free-text', inputCssClass, isValid == false ? 'is-invalid' : '']" 
			v-model="freeText" 
			@change="onChangeFreeText"
			data-lpignore="true"
			placeholder="Enter your task..."
			maxlength="128"
			v-if="canFreeText"
		>

		<select 
			:id="id + '-select'" :name="getName" 
			:class="['c-task-select-select', selectCssClass, isValid == false ? 'is-invalid' : '', tasks.length == 0 ? 'hidden' : '']" 
			v-model="taskId" 
			@change="onChangeSelect"
			v-if="canSelect"
			>

			<option v-for="task in taskWithFlatHierarchy" :value="task.ID" :key="task.ID">{{task.Title}}</option>

		</select>

		<span class="validation-text" v-if="isValid == false && validationMessage != ''">
			<small style="color: red" v-html="validationMessage"></small>
		</span>

		<label class="c-task-select-use-free-text-label" v-if="freeTextAllowed == true && tasks.length > 0">
			<input type="checkbox" 
				:id="id + '-check'" :name="getName" 
				:class="['c-task-select-use-free-text']" 
				v-model="useFreeText" 
			> Use my own task
		</label>
	</div>
</template>





<script>
export default {


	emits: ["change", "input", "blur"],

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * The field's `name` value. 
		 * If not defined, `id` is used.
		 */
		name: {
			type: String
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},



		/**
		 * The rendered size of the field.  
		 * Valid values are: "standard" (default), "small", "large"
		 */
		size: {
			type: String
		},
		


		// initialValue: {
		// 	type: [ String, Array ]
		// },



		initialTaskId: {
			type: String,
			default: ""
		},
		


		initialFreeText: {
			type: String,
			default: ""
		},
		


		tasks: {
			type: Array
		},





		sequence: {
			type: Object
		},




		isValid: {
			type: Boolean,
			default: true
		},


		/**
		 * Set to true to allow the user to enter a free text task value (bypassing the select)
		 */
		freeTextAllowed: {
			type: Boolean,
			default: false
		},

		validationMessage: {
			type: String,
			default: ""
		},



		styleType: {
			type: String,
			required: false,
			default: "bootstrap"
		}
	},





	data()
	{
		return {
			//value: [],
			taskId: "",
			freeText: "",

			useFreeText: false,

			taskWithFlatHierarchy: [],

			selectCssClass: "",
			inputCssClass: "",
		};
	},






	mounted()
	{
		//console.log("task-select.mounted(): tasks", this.tasks);
		//this.value = (this.initialValue == undefined || this.initialValue == null) ? "" : this.initialValue;
		this.taskId = this.initialTaskId;
		this.freeText = this.initialFreeText;

		//console.log("task-select.mounted(): initialValue=", this.initialValue);
		// console.log("task-select.mounted(): tasks=", this.tasks);

		//this.taskWithFlatHierarchy = this.createFlatHierarchy(this.tasks);
		this.taskWithFlatHierarchy = this.createFlatHierarchy(this.getTasks);

		if (this.styleType == "bootstrap")
		{
			this.selectCssClass = "custom-select";
			this.inputCssClass = "form-control";
		}
	},





	// watch: {
	// 	value: function(to, from)
	// 	{
	// 		console.log("task-select.watch(value): to=", to, "from=", from);
	// 	}
	// },



	computed: {
		getName() {
			return this.name ? this.name : this.id;
		},


		getCount()
		{
			if (this.tasks == undefined) return 0;

			return this.tasks.length;
		},

		

		getTasks()
		{
			if (this.tasks == undefined) return [];

			return this.tasks;
		},


		isVisible()
		{
			return this.canFreeText || this.canSelect;
		},


		canFreeText()
		{
			return this.freeTextAllowed == true && (this.useFreeText == true || this.tasks.length == 0);
		},


		canSelect()
		{
			return this.useFreeText == false && this.tasks.length > 0;
		}
	},





	methods: {


		onChange(changed)
		{
			var freeText = this.freeText;
			if (freeText == null || freeText == undefined) freeText = "";

			var taskId = this.taskId;
			if (taskId == null || taskId == undefined) taskId = "";

			var data = { Changed: changed, TaskID: taskId, FreeText: freeText, sequence: this.sequence };

			//console.log("task-select.onChange(): data=", data);

			//this.$emit('input', data);
			this.$emit('change', data);
		},


		onChangeSelect()
		{
			//console.log("task-select.onChangeSelect():");

			this.onChange("select");
		},

		onChangeFreeText()
		{
			this.onChange("free-text");
		},


		onInput($event)
		{
			// console.log("onInput(): ", $event);

			this.$emit('input', $event.target.value);
		},

		onBlur($event)
		{
			// console.log("onBlur(): ", $event);

			this.$emit('blur', $event.target.value);
		},

		// onChange($event)
		// {
		// 	console.log("onChange(): ", $event);
		// 	//this.$emit('change', $event.target.value);

		// 	this.$emit('input', this.value);
		// 	this.$emit('change', this.value);
		// },



		createFlatHierarchy(items)
		{
			var list = [];

			//TODO: Sort alphabetically

			items.forEach(item => {
				// console.log("item=", item);

				list.push({
					ID: item.ID,
					Title: item.Title,
				});
			});

			
			// Sort alphabetically
			list.sort((a, b) => (a.Title.toLowerCase() > b.Title.toLowerCase()) ? 1 : -1);

			return list;
		}




	}
}
</script>





<style scoped lang="scss">
	
	.c-task-select
	{
		font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
		// font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;

		display: inline-block;
		width: 100%
	}

	.c-task-select-free-text
	{
		width: 100%;
		border: 1px solid #dddddd;
		// background:none;
	}

	.c-task-select-select
	{
		width: 100%;
	}


	.c-task-select-select.hidden
	{
		display: none;
	}
</style>
<template>
		<textarea 
			:id="id" :name="getName" 
			:class="['c-input-textarea', 'c-input-native-base-restyle', cssClass, isValid == false ? 'is-invalid' : '', 'c-input-textarea-native']" 
			:style="getCssStyle"
			@change="onChange"
			@input="onInput"
			@blur="onBlur"
			:value="modelValue"
			v-if="1==1 && type == 'native'"
		></textarea>

		<el-input
			type="textarea"
			:id="id" :name="getName" 
			:class="['c-input-textarea', cssClass, isValid == false ? 'is-invalid' : '']" 
			:style="getCssStyle"
			:rows="rows"
			v-model="value" 
			:value="modelValue"
			:placeholder="placeholder"
			@change="onChangeEl"
			@input="onInputEl"
			@blur="onBlurEl"
			v-if="type == 'element'"
			>
		</el-input>

		<span class="validation-text" v-if="isValid == false && validationMessage != ''">
			<small style="color: red" v-html="validationMessage"></small>
		</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	emits: ["change", "input", "blur", "click", "update:modelValue"],

	mixins: [allFormElementPropsMixin],

	props: {

		initialValue: {
			type: String
		},


		rows: {
			type: Number,
			required: false,
			default: 2,
		},


		//TODO: New with Vue 3
        modelValue: {
            type: String,
            default: '',
            required: true
        },





		type: {
			type: String,
			default: "native"
		},





	},





	data()
	{
		return {
			value: ""
		};
	},






	mounted()
	{
		this.value = this.initialValue;
	},


	watch: {
		initialValue: function(to, from)
		{
			this.value = this.initialValue;
		}
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {
		// onClick()
		// {
		// 	this.$emit('click', {});
		// },



		onInput($event)
		{
			// console.log("input-textarea.onInput(): $event=", $event);

			const value = $event.target.value;
			
			// console.log("input-textarea.onInput(): value=", value);

			//this.$emit('input', $event.target.value);

			this.$emit('change', value);

			this.$emit('input', value);

			this.$emit('update:modelValue', value);
		},


		onChange($event)
		{	
			this.$emit('change', $event.target.value);
		},


		onBlur($event)
		{
			this.$emit('blur', $event.target.value);
		},






		onInputEl(value)
		{
			//console.log("input-textarea::onInputEl(): ", value);

			this.$emit('change', value);

			this.$emit('input', value);

			this.$emit('update:modelValue', value);
		},

		onBlurEl($event)
		{
			// console.log("onBlurEl(): ", $event);

			this.$emit('blur', $event.target.value);
		},

		onChangeEl(value)
		{
			// console.log("onChangeEl(): ", value);

			//this.$emit('input', this.value);
			//this.$emit('change', value);
		},


	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
	
	.c-input-textarea-native
	{
		width: 100%;

		// This is to approximately match the styling of the select list (and I presume text box) in Element UI
		padding: 10px;
		border-radius: 4px;

		border: 1px solid #DCDFE6;
	}
	.c-input-textarea-native:focus
	{
		border-color: #409EFF;
	}
</style>
<template>
	<div>
		<div class="row pt-md-3 mb-1" v-if="subTitleHasContent()">
			<div class="col-md-12 col-12 sub-title-container">
				<slot name="subTitle"></slot>
			</div>
		</div>

		<div class="row pt-md-1 mb-2">
			<div :class="['col-md-12','col-12','text-left','breadcrumbs-container']" v-if="breadcrumbsHasContent()">
				<slot name="breadcrumbs"></slot>
			</div>
			<div :class="['col-md-12','col-12','page-toolbar','actions-container', getActionsAlignment()]" v-if="actionsHasContent()">
				<el-card class="box-card" shadow="never" style="border-style: dashed; border-left: none; border-right: none">
					<slot name="actions"></slot>
				</el-card>
			</div>
		</div>

	</div>
</template>





<script>
export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},
	},





	data()
	{
		return {

		};
	},






	mounted()
	{

	},





	computed: {

	},





	methods: {
		subTitleHasContent()
		{
			var slot = this.$slots.subTitle();
			var hasContet = this.slotHasContent(slot);
			return hasContet;
		},
		breadcrumbsHasContent()
		{
			var slot = this.$slots.breadcrumbs();
			var hasContet = this.slotHasContent(slot);
			//console.log("subTitleHasContent(): hasContet=", hasContet);
			return hasContet;
		},
		actionsHasContent()
		{
			var slot = this.$slots.actions();
			var hasContet = this.slotHasContent(slot);
			//console.log("subTitleHasContent(): hasContet=", hasContet);
			return hasContet;
		},

		slotHasContent(slot)
		{
			if (!slot) return false;
			if (slot.length == 0) return false;

			return true;
		},


		getActionsAlignment()
		{
			if (this.breadcrumbsHasContent() == true)
			{
				return "text-right";
			}
			return "text-left";
		}
	}
}
</script>





<style scoped lang="scss">
</style>
<template>
	<div class="c-lotus-button-wrapper">
		<!-- v-if="type == 'standard'"  -->
		<button :type="getHtmlButtonType" 
			:id="id" :name="getName" 
			:class="['c-button', 'btn', 'c-lotus-button', 'c-lotus-button-standard', getBtnClass, getCssClass]"
			:style="getCssStyle"
			@click="onClick">

			<span class="icon"><slot name="icon"></slot></span>
			<span class="text"><slot name="text"></slot></span>
		</button>

		<div class="component-button-primary" v-if="1==2 && type == 'primary'" :class="['c-button', 'c-lotus-button', 'c-lotus-button-primary']" @click="onClick">
			<button class="btn btn-primary rounded-pill w-100">
				<span class="icon"><slot name="icon"></slot></span>
				<span class="text"><slot name="text"></slot></span>
			</button>
		</div>

		<div class="component-button-secondary" v-if="1==2 && type == 'secondary'" :class="['c-button', 'c-lotus-button', 'c-lotus-button-secondary']" @click="onClick">
			<button class="btn btn-dark rounded-pill w-100 mb-2">
				<span class="icon"><slot name="icon"></slot></span>
				<span class="text"><slot name="text"></slot></span>
			</button>
		</div>
	</div>
</template>





<script>
import allFormElementPropsMixin from "../forms/all-form-element-props-mixin";

export default {


	mixins: [allFormElementPropsMixin],

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: true
		},

		/**
		 * The field's `name` value. 
		 * If not defined, `id` is used.
		 */
		name: {
			type: String
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String,
			default: ""
		},

		
		value: {
			type: String
		},
		
		
		isSubmit: {
			type: Boolean,
			required: false,
			default: false
		},

		
		/**
		 * Values include: "standard"; "primary"; "secondary"; "teriary" "link".
		 */
		type: {
			type: String,
			default: "standard"
		}
	},





	data()
	{
		return {
			btnClass: ""
		};
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		},

		getHtmlButtonType() {
			return this.isSubmit ? "submit" : "button"
		},

		
		getBtnClass() {
			if (this.type == "standard") return "btn-primary";
			if (this.type == "primary") return "btn-primary";
			if (this.type == "secondary") return "btn-secondary";
			if (this.type == "tertiary") return "btn-light";
			if (this.type == "link") return "btn-link";
			
			
			return "btn-primary";
		},


		getCssClass()
		{
			if (this.type == "primary")
			{

				return "btn-primary rounded-pill w-100 " + "c-lotus-button-primary " + this.cssClass; 
			}
			if (this.type == "secondary")
			{
				return "btn-dark rounded-pill w-100 mb-2 " + "c-lotus-button-secondary " + this.cssClass;
			}
			if (this.type == "tertiary")
			{
				return "c-lotus-button-tertiary " + this.cssClass;
			}
			if (this.type == "link")
			{
				return "c-lotus-button-link " + this.cssClass;
			}

			return this.cssClass
		}
	},





	methods: {
		onClick()
		{
			console.log("lotus-button.onClick()2");

			//NOTE: [@jason, 13 Feb 2023] Discovered that clicking the button in this component
			//		emits a click naturall and `@click.prevent="onClick"` does not stop it.
			//		So having the emit below means a double-emit from this component to the caller.

			// var _this = this;

			// this.$emit('click', {
			// 	id: _this.id
			// });
		}
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";

	.c-button
	{
		.icon
		{
			display: inline-block;
			padding-right: 4px;
		}

		.text
		{
			//display: inline-block;
			display: inline;	// inline, because `component-button-secondary` with an icon ends up with icon above the text
		}
	}



	.c-lotus-button-tertiary
	{
		color: black
	}

	.c-lotus-button-link
	{
		color: black
	}




	.component-button-secondary button{
		color:white;
		letter-spacing: 2px;
	}
	.component-button-secondary img {
		margin-top:-4px;
		//display: inline-block;
		display: inline;
	}


	.component-button-primary button{
		color:white;
		letter-spacing: 2px;
	}
	.component-button-primary img{
		margin-top:-4px;
		display: inline-block;
	}


	// Override. Original radius looks to be coming from Bootstrap?
	.rounded-pill,
	.c-lotus-button
	{
		border-radius: 10px !important;	// !important to override the global class (no idea why that was important). Ultimately find and remove the global class then get rid of important.
	}

	.c-lotus-button-wrapper {
		width: max-content;
	}

</style>
<template>
	<!-- NOTE: The layout for the browser version of the mobile app, to replicate or replace the native app -->
	<div class="bg-white outer-container">

		<!-- Menu bar -->
		<div class="menu-bar-container padding-standard menu-bar-bg">

			<!-- NOTE: Putting these DIVs inside class="container" fucks up rendering of the login page
				after re-loading from the other pages (e.g. Menu > Logout) 
			-->
			<!-- <div class="menu-icon-container" @click="openNav()" v-if="show.view != 'login'">
				<i class="fas fa-bars menu-icon"></i>
			</div> -->

			<div>
				<div class="text-left" style="display: inline-block; width: 48%;">
					<div class="menu-icon-container" @click="openNav()" v-if="show.view != 'login'">
						<i class="fas fa-bars menu-icon"></i>
					</div>
				</div>
				<div class="text-right" style="display: inline-block; width: 50%;">
					<!-- <img src="https://assets.lotusai.co/portal/images/logo-white-icon.png" alt="" class="menu-bar-logo"> -->
					<img src="https://assets.lotusai.co/portal/images/logo-gold-transparent.png" alt="" class="menu-bar-logo">
				</div>
			</div>


			
			<div id="mySidenav" class="sidenav">
				<div class="closeContainer">
					<a href="javascript:void(0)" class="closebtn" @click="closeNav()">&times;</a>
				</div>
				
				<div class="header-container">
					<div class="text item">
						<b>{{user.displayName}}</b>
					</div>
				</div>
				
				<div class="side-nav-top">
					<div class="items-container">
						<a href="javascript:void(0)" class="item" @click="showView('current-status', true)">Current Status</a>
						<a href="javascript:void(0)" class="item" @click="showView('work-plan', true)">Your Plan</a>
						<a href="javascript:void(0)" class="item" @click="showView('change-pin', true)">Change PIN</a>

						<div class="mb-4"></div>
						<a href="javascript:void(0)" class="item" @click="showView('settings', true)">Settings</a>
					</div>
				</div>

				<div class="side-nav-bottom">
					<div class="items-container">
						<!-- <a href="javascript:void(0)" @click="showView('contact-managemnet', true)">Contact management</a> -->
						<!-- <a href="javascript:void(0)" class="item" @click="showView('contact-app-support', true)">Contact app support</a> -->
						<a href="javascript:void(0)" class="item" @click="showView('login', true)">Logout</a>    
					</div>
				</div>
			</div>

		</div>


		<div class="content-container padding-standard content-container-bg pt-3">
			<div style="width: 100%">
				<login-screen v-if="show.view == 'login'"></login-screen>
				<current-status-screen v-if="show.view == 'current-status'"></current-status-screen>
				<work-plan-screen v-if="show.view == 'work-plan'"></work-plan-screen>


				<settings-screen v-if="show.view == 'settings'">
					<h2>Settings</h2>
				</settings-screen>
			</div>


			<div style="width: 100%" v-if="1==2">
				<c-layout-container>
					<page-title>{{pageTitle}}</page-title>
					<slot></slot>
				</c-layout-container>
			</div>

			
			<div class="col-12 text-center mt-3 powered-by-container">
				<p>Powered by Lotus AI</p>
			</div>

		</div>
	</div>
</template>



<script>
import globalStore from '/globalStore';
import layoutMixin from "./layout-mixin";
//import layoutFooter from "./footer";	//TODO: Remove
import accountAdminMixin from "/account-admin/account-admin-mixin";


import LoginScreen from '/developers/prototypes/mobile-login.vue';
import SettingsScreen from '/developers/prototypes/mobile-settings.vue';
import WorkPlanScreen from '/developers/prototypes/mobile-work-plan.vue';
import CurrentStatusScreen from '/developers/prototypes/mobile-current-status.vue';
import mobileData from '/developers/prototypes/mobile-data.js';


export default {
	name: "MobileAppLayout",


	components: {
		'login-screen': LoginScreen,
		'settings-screen': SettingsScreen,
		'work-plan-screen': WorkPlanScreen,
		'current-status-screen': CurrentStatusScreen,
	},

	emits: ["child-event"],

	mixins: [layoutMixin, accountAdminMixin],

	// components: {
	// 	'layout-footer': layoutFooter,	//TODO: Remove
	// },



	props: [
		'subPageTitle'
	],

	data() {
		return {
			////////////////////////////////////////////////////////////////////////
			//					From "MobileAppEmbeddedViewLayout"
			////////////////////////////////////////////////////////////////////////
			globalStore: globalStore,

			pageTitle: "",
			pageSubTitle: "",

			section: "",
			sectionLayoutClass: "",

			layoutType: "",	// The "layout-type" specified for the page in the route meta. "account-admin" or "" (default)

			layoutContainer: "", // specified in route meta data. Default is "card",






			////////////////////////////////////////////////////////////////////////
			//					From "mobile app prototype"
			////////////////////////////////////////////////////////////////////////

            show: {
                // view: "login"
                view: "current-status"
                //view: "work-plan"
            },



            user: {
                displayName: "Jason Snelders"
            },



			workPlan: mobileData.workPlan,



            //-- Current Status
            currentStatus: {
				//status: "checked-in",
				status: "not-checked-in",
				//status: "change-task",

				selectedTaskID: ""
            },



		};
	},



	computed: {
        //-- Current Status
		getCurrentStatus()
		{
			if (this.currentStatus.status == "not-checked-in")
			{
				return this.workPlan.jobs.find( (job) => { return job.id == "job2"; });
			}

			if (this.currentStatus.status == "checked-in")
			{
				return this.workPlan.jobs.find( (job) => { return job.id == "job2"; });
			}
			
			if (this.currentStatus.status == "change-task")
			{
				return this.workPlan.jobs.find( (job) => { return job.id == "job2"; });
			}

			return null;
		},
	},


	mounted() 
	{
		this.init();
	},


	created() 
	{
		this.init();
	},





	methods: {

		////////////////////////////////////////////////////////////////////////
		//					From "MobileAppEmbeddedViewLayout"
		////////////////////////////////////////////////////////////////////////

		init() 
		{
			this.pageTitle = this.$route.meta.pageTitle;

			//console.log("this.$route.meta.layout=", this.$route.meta.layout);
			//this.layoutType = this.$route.meta.layout;

			this.layoutType = this.$route.meta.layoutType;
			if (!this.layoutType) this.layoutType = "default";

			this.layoutContainer = this.$route.meta.layoutContainer;
			//console.log("layoutContainer=", this.layoutContainer);

			if (!this.layoutContainer) this.layoutContainer = "card";
			//console.log("layoutContainer 2=", this.layoutContainer);
			
			// Check the user is [still] logged in.
			this.$root.checkCurrentAuthTokenStillValid();


			// We want to maintain the Compass Gold background to fit with the mobile app.
			//document.body.classList.add('bg-override-lotus-gold');


			// this.$on("child-event", this.handleChildEvent);	//TODO: Remove

			this.$parent.$emit('child-event', 'mobile-app layout emit');
		},



		onPageTitle(data)
		{
			console.log("PrimaryLayout.onPageTitle(): data=", data);
		},



		//TODO: Remove
		// handleChildEvent(data)
		// {
		// 	console.log("PrimaryLayout.handleChildEvent(): data=", data);
		// }

		















		////////////////////////////////////////////////////////////////////////
		//					From "mobile app prototype"
		////////////////////////////////////////////////////////////////////////


		//-- General
        showView(view, fromMenu = false)
        {
            this.show.view = view;

            if (fromMenu)
            {
                // Triggered from menu item select. Close the menu.
                this.closeNav();
            }
        },






        //-- Navigation menu
        /* Set the width of the side navigation to 250px */
        openNav() 
        {
			var sideNavDev = document.getElementById("mySidenav");
			//sideNavDev.style.width = "250px";
			sideNavDev.classList.remove('sidenav-closed');
			sideNavDev.classList.add('sidenav-open');
        },

        /* Set the width of the side navigation to 0 */
        closeNav() 
        {
			var sideNavDev = document.getElementById("mySidenav");
            //sideNavDev.style.width = "0";
			sideNavDev.classList.remove('sidenav-open');
			sideNavDev.classList.add('sidenav-closed');
        }






	}
}
</script>























<style lang="scss">
	////////////////////////////////////////////////////////////////////////
	//					From "MobileAppEmbeddedViewLayout"
	////////////////////////////////////////////////////////////////////////
	// @import "./shared.scss";
</style>

<style lang="scss" scoped>
	////////////////////////////////////////////////////////////////////////
	//					From "MobileAppEmbeddedViewLayout"
	////////////////////////////////////////////////////////////////////////
	.inner-container
	{
		//TDOO: With the variable #app background this is causing issues
		//margin-top: 5px;	
		padding-top: 5px;	//.. but this  does the trick.
	}



	// .page-title-container
	// {
	// 	// border-bottom: 1px solid rgba(0,0,0,0.065);
	// }

	
	// #primary-top-nav-card
	// {
	// 	overflow: inherit;
	// }


</style>

<style lang="scss">
	////////////////////////////////////////////////////////////////////////
	//					From "MobileAppEmbeddedViewLayout"
	////////////////////////////////////////////////////////////////////////


	// //TODO: Review these now we have "c-layout-container"
	// #page-title-container .page-title-card 
	// {
	// 	.el-card__body
	// 	{
	// 		padding: 15px;
	// 		padding-left: 20px;
	// 	}
	// }


	// #primary-top-nav-card
	// {
	// 	.el-card__body
	// 	{
	// 		padding: 10px;
	// 		// padding-left: 20px;
	// 	}
	// }



	// #footer-layout-container
	// {
	// 	//-- Stick and always visible at the bottom.
	// 	// position: sticky; 
	// 	// bottom: 0;
	// 	// z-index: 10000;	// Make sure it's on top of everything (problem: Element IO drop downs slide behind it.)

	// 	//NOTE: Decided not to have it sticky. Doesn't quite feel right.
	// }

	// #footer-layout-container .el-card__body
	// {
	// 	width: 100%;
		
	// 	padding-top: 10px;
	// 	padding-bottom: 10px;
	// }



	// @media screen and (max-width: 767px) {
	// 	.header-logo
	// 	{
	// 		height: 40px;
	// 		margin-top: 5px !important;
	// 		margin-bottom: 5px !important;
	// 	}

	// 	.icon-container
	// 	{
	// 		padding: 0;
	// 	}
	// }


	// body.bg-override-lotus-gold
	// {
	// 	background-color: #ffb600 !important;
	// }
</style>



<style lang="scss" scoped>
	////////////////////////////////////////////////////////////////////////
	//					From "mobile app prototype"
	////////////////////////////////////////////////////////////////////////
	@import './mobile-app.scss';

	h1 {
		// Keep it true.
		display:  none;
	}
</style>
<template>
	<div>
		<h3 :class="['page-section-title', pageSectionTitleVersionClass, 'page-section-title-level-1']" v-if="level == 1">
			<slot></slot>
		</h3>
		<h4 :class="['page-section-title', pageSectionTitleVersionClass, 'page-section-title-level-2']" v-if="level == 2">
			<slot></slot>
		</h4>
	</div>
</template>





<script>
export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},

		level: {
			type: Number,
			default: 1,
			required: false
		},


		/**
		 * What version of this componet. Controls styling.
		 */
		version: {
			type: Number,
			required: false,
			default: 1
		},


		// text: {
		// 	type: String,
		// 	required: true
		// },
	},





	data()
	{
		return {
			pageSectionTitleVersionClass: "page-section-title-v1",
		};
	},






	mounted()
	{
		this.init();
	},





	computed: {

	},





	methods: {
		init()
		{
			this.pageSectionTitleVersionClass = "page-section-title-v1";
			if (this.version == 2) this.pageSectionTitleVersionClass = "page-section-title-v2";
		}
	}
}
</script>





<style scoped lang="scss">
@import "/assets/sass/_global.scss";

.page-section-title
{
	font-family: $font-bold;
}


.page-section-title-v1
{

	color: #444444;
}


.page-section-title-v2
{
	color: $primary-yellow;
}

.page-section-title-level-1
{
	// font-size: 28pt;
	// font-size: 1.75em;
	font-size: 1.2em;
	margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.2;
}

.page-section-title-level-2
{
	// font-size: 28pt;
	// font-size: 1.75em;
	font-size: 1em;
	margin-bottom: 0.5rem;
    font-weight: 700;
    line-height: 1.2;
}



</style>
<template>
	<span :class="['c-column-header', cssClass]" @click="sortTable(index)">
		<slot></slot>
	</span>
</template>





<script>
export default {

	emits: ["setColumnIndex"],

	props: {

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},



		/**
		 * Index for sorting
		 */
		index: {
			type: Number
		}
	},





	mounted() 
	{
		
	},




	data()
	{
		return {
			
		};
	},





	computed: {
		
	},





	methods: {
		sortTable(columnIndex)
		{
			console.log("column-header.sortTable(): columnIndex", columnIndex);
			
			this.$parent.$emit('SortColumnIndex', columnIndex - 1);	// Emits in the table-column
		}
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";

	.c-column-header
	{
		font-family: $font-standard;
		// font-size: 12pt;	// Inherit the size, and control changes in implementation from parent
		color: #222f38;
		opacity: .75;
		font-weight: normal;

		cursor: pointer;
	}

	//-- Original design. Colour too light and font size too small.
	// font-size: 12px;
	// font-weight: normal;
	// opacity: 0.4;

</style>
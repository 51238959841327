<template>
	<div v-if="showMessage == true">
		<div role="alert" aria-live="assertive" aria-atomic="true" v-bind:class="['toast', messageTypeClass]" data-autohide="false" v-bind:id="toastId"
			@mouseenter="onMouseEnter"
			@mouseleave="onMouseLeave">
			<div :class="[messageTypeBorderClass]">
				<div class="toast-header">
					<strong class="mr-auto message-title" v-html="title"></strong> 
					<small v-if="time != null">11 mins ago</small> 
					<button type="button" class="ml-2 mb-1 close" daria-label="Close" @click="onClose">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="toast-body">
					<small class="countdown-timer">{{(showDurationMilliseconds - counter*1000)/1000}}</small>
					<div v-html="message"></div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: "ToastMessage",

	// Reference: https://getbootstrap.com/docs/4.3/components/toasts/#stacking

	// We're using custom close to we completely remove the element frm the DOM (Bootstrap leaves it in an hidden).

	props: [
		'toast-id',		// Required. Must be unique for all toasts. Consider something timestamp based.
		'title',		// Optional.
		'message',		// Required
		'time',			// Optional

		'message-type'	// Optional
	],



	data()
	{
		return {
			messageTypeClass: "info-message",
			showDurationMilliseconds: 3000,		// Originally 10000. That was too long.

			showMessage: false,

			counterHandle: null,
			counter: 0,
			isPaused: false,

			messageTypeBorderClass: "border-success"
		};
	},



	created()
	{
		//console.log("toast.created(): toastId=", this.toastId);
	},


	updated()
	{
		//TODO: That's interesting. This is called multiple times.
		//console.log("toast.updated(): toastId=", this.toastId);
	},


	mounted()
	{
		// console.log("toast.mounted(): toastId=", this.toastId);
		// console.log("toast: message=", this.message);
		// console.log("toast: messageTypeClass=", this.messageTypeClass);

		this.showMessage = true;
		this.runTimer();


		if (this.messageType == "message")
		{
			this.messageTypeClass = "info-message";
			this.messageTypeBorderClass = "border-info";
		}
		if (this.messageType == "fail")
		{
			this.messageTypeClass = "fail-message";
			this.messageTypeBorderClass = "border-fail";
		}
		if (this.messageType == "success")
		{
			this.messageTypeClass = "success-message";
			this.messageTypeBorderClass = "border-success";
		}

	},




	methods: {
		runTimer()
		{
			var _this = this;

			this.counterHandle = setInterval(function() {
				if (_this.isPaused == false)
				{
					_this.counter = _this.counter + 1;

					if ((_this.counter * 1000) >= _this.showDurationMilliseconds)
					{
						_this.showMessage = false;	// Auto close the message.

						clearInterval(_this.counterHandle);
					}
				}
			}, 1000);
		},

		onClose()
		{
			this.showMessage = false;
			
			clearInterval(this.counterHandle);
		},


		onMouseEnter()
		{
			this.isPaused = true;
		},
		

		onMouseLeave()
		{
			this.isPaused = false;
		}
	}
}
</script>

<style scoped>
	.toast
	{
		min-width: 500px;
		

		/* margin-top: 10px; */
		font-size: 1em;	/* Override Bootstrap size. Make it more readable for older eyes. */

		padding: 10px;
		
	}

	.info-message .message-title
	{
		color: inherit;
	}
	.border-info
	{
		border: 4px solid darkgrey;
		border-radius: 4px;
	}

	.fail-message .message-title
	{
		color: red
	}
	.border-fail
	{
		border: 4px solid red;
		border-radius: 4px;
	}

	.success-message .message-title
	{
		color: green;
	}
	.border-success
	{
		border: 4px solid green;
		border-radius: 4px;
		padding-top:6px;
	}


	.countdown-timer
	{
		opacity: .2;
		float: right;
	}


	.toast
	{
		background-color: white
	}
</style>
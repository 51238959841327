//TODO: [@jason, 21 uly 2023] This was a silly idea and mainly relevant to Vue 2. Don't continue with this file.

const addressHelpers = {
	getAddressDisplay(address)
	{
		//console.log("getAddressDisplay(): address=", address);

		// No address provided
		if (!address) return "";

		
		let display = "";

		if (address.Line1)
		{
			display = address.Line1;
		}
		if (address.Line2)
		{
			if (display != "") display = display + ", ";
			display = display + address.Line2;
		}
		if (address.Line3)
		{
			if (display != "") display = display + ", ";
			display = display + address.Line3;
		}

		if (address.CityName)
		{
			if (display != "") display = display + ", ";
			display = display + address.CityName;
		}

		if (address.StateName)
		{
			if (display != "") display = display + ", ";
			display = display + address.StateName;
		}

		if (address.PostCode)
		{
			if (display != "") display = display + ", ";
			display = display + address.PostCode;
		}


		return display;
	},
};




export default addressHelpers;
<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<img src="" id="" name="" :class="['c-logo', cssClass]" />
</template>





<script>
export default {

	emits: ["click"],

	
	props: [
		'id',
		'css-class',
		'href',
		'text',

		'type'		// Standard, primary, secondary, etc.
	],





	data()
	{
		return {

		};
	},





	computed: {
		
	},





	methods: {
		onClick()
		{
			this.$emit('click', {});
		}
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
</style>
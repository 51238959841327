<template>
	<span>
		<select 
			:id="id" :name="getName" 
			:class="['select-address', cssClass, isValid == false ? 'is-invalid' : '']" 
			:style="getCssStyle"
			filterable
			v-model="value" 
			@change="onChange"
			@input="onInput"
			@blur="onBlur"
			:multiple="multiple ? true : false"
			v-if="type == 'native'">
			<option v-for="option in getOptions" :value="option.value" :key="id + '-' + option.value">{{option.text}}</option>
		</select>

		<el-select 
			v-model="value" 
			:id="id" :name="getName" 
			:class="['select-address', 'simulate-form-control', cssClass, isValid == false ? 'is-invalid' : '']" 
			:style="getCssStyle"
			@change="onChangeEl"
			@input="onInputEl"
			@blur="onBlurEl"
			filterable 
			placeholder="Select"
			:multiple="multiple ? true : false"
			:disabled="disabled"
			v-if="type == 'element' && 1==2">
			<el-option
				v-for="option in getOptions"
				:key="id + '-' + option.value"
				:label="option.text"
				:value="option.value">
				<slot></slot>
				<!-- Shw only below MD -->
				<span class="d-block d-md-none">{{ option.text }}</span>

				<!-- Shw on MD and above-->
				<span class="d-none d-md-block">{{ option.text }}</span>
			</el-option>
		</el-select>

		<el-select 
			v-model="value" 
			:id="id" :name="getName" 
			:class="['select-address', 'simulate-form-control', cssClass, isValid == false ? 'is-invalid' : '']" 
			:style="getCssStyle"
			@change="onChangeEl"
			@input="onInputEl"
			@blur="onBlurEl"
			filterable 
			placeholder="Select"
			:multiple="multiple ? true : false"
			:disabled="disabled"
			v-if="type == 'element'">
			<el-option class="select-address-option"
				v-for="address in addresses"
				:key="id + '-' + address.ID"
				:label="address.DisplayAddress"
				:value="address.ID">
				<!-- Show only below MD -->
				<div class="d-block d-md-none select-address-small" v-html="getSmallScreenDisplayAddress(address)"></div>

				<!-- Shw on MD and above-->
				<span class="d-none d-md-block select-address-large">{{ address.DisplayAddress }}</span>
			</el-option>
		</el-select>

		<span class="validation-text" v-if="isValid == false && validationMessage != ''">
			<small style="color: red" v-html="validationMessage"></small>
		</span>

	</span>
</template>



<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {


	emits: ["change", "input", "blur", "click", "update:modelValue"],

	
	mixins: [allFormElementPropsMixin],

	props: {
		
		initialValue: {
			type: [ Number, String, Array ]
		},
		

		//TODO: Make this obsolete (still here during initial dev). Relace with "addresses"
		/**
		 * A standard setup of options with "value" and "text" properties
		 */
		options: {
			type: Array,
			default: null
		},


		//TODO: Actually, in the context of manage/job-edit-component.vue, this is the sane as 'data;.
		/**
		 * A list of standard address objects. This will replace 'options'.
		 */
		addresses: {
			type: Array,
			default: null
		},


		/**
		 * A custom data set, where "value" and "textd" for standard "options"
		 * are mapped from the "dataValue" and "dataText" (name of the properties in the data items) respectively.
		 */
		data: {
			type: Array,
			default: null
		},


		dataValue: {
			type: String,
			default: ""
		},

		dataText: {
			type: String,
			default: ""
		},


		type: {
			type: String,
			default: "element"
		},


		multiple: {
			type: Boolean,
			default: false
		},



		//TODO: New with Vue 3
        modelValue: {
            type: String,
            default: '',
            required: true
        },



		
		
	},






	data()
	{
		return {
			value: []
		};
	},






	mounted()
	{
		//TODO: Doing this here is crapping out the value for some reason

		//this.value = this.initialValue;

		//console.log("input-select.mounted()[" + this.id + "]: initialValue=" + this.initialValue, "value=", this.value);
	},




	created()
	{
		this.value = this.initialValue;

		// console.log("input-select.created()[" + this.id + "]: initialValue=" + this.initialValue, "value=", this.value);
	},



	watch: {
		initialValue: function(to, from)
		{
			this.value = this.initialValue;

			// console.log("watch[initialValue][" + this.id + "]: value=", this.value, "to=", to, "from=", from);
		}
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		},


		getOptions()
		{
			if (this.data)
			{
				// Generate an options array from the supplied raw data and identified value and text fields.
				var options = this.$root.getSelectOptionsFromData(this.data, this.dataValue, this.dataText);

				// console.log("input-select.getOptions(from data): this.dataValue=", this.dataValue);
				// console.log("input-select.getOptions(from data): this.dataText=", this.dataText);
				// console.log("input-select.getOptions(from data): option=", options);

				return options;
			}
			else
			{
				// Use a correctly formatted options list
				return this.options;
			}
		}
	},





	methods: {
		onClick()
		{
			this.$emit('click', {});
		},


		onInput($event)
		{
			this.$emit('input', $event.target.value);
		},


		onChange($event)
		{
			this.$emit('change', $event.target.value);
		},


		onBlur($event)
		{
			this.$emit('blur', $event.target.value);
		},











		onInputEl(value)
		{
			//console.log("onInputEl(): ", value);

			this.$emit('input', value);

			//this.$emit('update:modelValue', value);
		},

		onBlurEl($event)
		{
			// console.log("onBlurEl(): ", $event);

			// this.$emit('blur', $event.target.value);
		},

		onChangeEl(value)
		{
			//console.log("onChangeEl(): ", value);

			this.$emit('input', this.value);
			this.$emit('change', this.value);

			this.$emit('update:modelValue', this.value);
		},




		getSmallScreenDisplayAddress(address)
		{
			var displayAddress = "";
			if (address.Line1) displayAddress = displayAddress + "" + address.Line1;
			if (address.Line2) displayAddress = displayAddress + ", " + address.Line2;
			if (address.Line3) displayAddress = displayAddress + ", " + address.Line2;
			
			displayAddress = displayAddress + ",<br/>" + address.CityName;
			displayAddress = displayAddress + ", " + address.StateName;
			displayAddress = displayAddress + ", " + address.PostCode;
			
			//TODO: Disabling while we're in Australia only. Need to look how we handle this after going international.
			//displayAddress = displayAddress + ",<br/>" + address.Ext.Country.CountryName;

			if (address.Name) displayAddress = "<i>" + address.Name + "</i>: <br/>" + displayAddress;

			return displayAddress;
		}


	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";

	.select-address
	{
	}
</style>

<style lang="scss">
	.select-address
	{
		.el-select-dropdown__item
		{

		}
	}


	/**
	* Override height of Element UI option on small screens, so we can show multiple lines
	*/
	.el-select-dropdown__item.select-address-option
	{
		height: inherit;
		line-height: 22px;


		.select-address-small
		{
			padding-bottom: 10px;
		}
	}
</style>
<template>
	<span>
		<!-- <i :class="['c-icon-with-dropdown', cssColorClass, getIconCssClass()]" :id="id" :title="title"></i> -->

		<el-dropdown trigger="click">
			<span class="el-dropdown-link" :title="title">
				<i :class="getIconCssClass(getPrimaryIcon)"></i>
			</span>
			<el-dropdown-menu slot="dropdown">
				<el-dropdown-item v-for="item in items" :key="item.id" :icon="getIconCssClass(item.icon)" @click.native="item.click">
					<span v-html="item.content"></span>
				</el-dropdown-item>
			</el-dropdown-menu>
		</el-dropdown>
	</span>
</template>





<script>

// <el-dropdown-item v-for="item in items" ... @click.native="item.click">
// Ref for click event:
//	https://www.google.com/search?q=el-dropdown-item+click&oq=el-dropdown-item+click&aqs=chrome..69i57j0.2583j0j7&sourceid=chrome&ie=UTF-8
//		https://github.com/ElemeFE/element/issues/6186
//		https://www.programmersought.com/article/36001542058/



export default {

	emits: ["click"],

	props: {

		/**
		 * Unique code/name identifying the icon to display
		 */
		primaryIcon: {
			type: String,
			// required: true,
			default: "standard"
		},
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * The field's `name` value. 
		 * If not defined, `id` is used.
		 */
		name: {
			type: String
		},

		/**
		 * Title to display when hovering over the icon.
		 */
		title: {
			type: String,
			default: ""
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},


		/**
		 * Name of the global CSS class contianing the colour to apply to the icon.
		 */
		cssColorClass: {
			type:String
		},



		/**
		 * List of items to display in the dropdown.  
		 * `id` is required to act as a key in the `v-for`.  
		 * `click` is a callback function.  
		 *   
		 * Item:
		 * { id: "", icon: "", content: "", click: function() }
		 */
		items: {
			type: Array
		}
		
	},





	data()
	{
		return {
			test: "el-icon-check"
		};
	},




	computed: {
		getCode()
		{
			if (this.code == undefined) return '';

			return this.code.toLowerCase();
		},


		getPrimaryIcon()
		{
			if (this.primaryIcon == undefined) return '';

			return this.primaryIcon.toLowerCase();
		}

	},





	methods: {
		onClick()
		{
			this.$emit('click', {});
		},


		getIconCssClass(code)
		{
			var iconCode = code;
			if (iconCode == undefined) return "";

			iconCode = iconCode.toLowerCase();


			
			if (iconCode == "edit") return "el-icon-edit";
			
			if (iconCode == "plus") 
			{

				return "el-icon-plus";	
			}
			
			if (iconCode == "minus") return "el-icon-minus";
			
			if (iconCode == "check") return "el-icon-check";
		},




		getPrimaryIconCssClass()
		{
			var iconCode = this.code;
			if (iconCode == undefined) return "";

			iconCode = iconCode.toLowerCase();


			
			if (iconCode == "edit") return "el-icon-edit";
			
			if (iconCode == "plus") return "el-icon-plus";
			
			if (iconCode == "minus") return "el-icon-minus";
			
			if (iconCode == "check") return "el-icon-check";
		}
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
</style>
<template>
	<div>
		<div class="accordian accordian-open mb-2"
		:class="['accordion', 'accordian', 'componenent-accordian', 
			isDisabledState() == true ? 'accordian-disabled' : '',
			state == states.collapsed ? 'accordian-closed' : '',
			state == states.expanded ? 'accordian-open' : '',
			cssClass]">
			
			<div class="accordian-header" @click="onClick">
				<span class="col-8">
					<slot name="headerTextLeft"></slot>
				</span>
				<span class="col-4 text-right float-right">
					<slot name="headerTextRigth"></slot>
				</span>
			</div>

			<div class="accordian-body" v-if="state == states.expanded">
				<slot name="content"></slot>
			</div>
		</div>
	</div>
</template>





<script>
export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: [String, Array],
			default: ""
		},


		disableDrillInGroupByBucketList: {
			type: Boolean,
			default: false
		},

		isAllTimesheetBuckets: {
			type: Boolean,
			default: false
		},


		initState: {
			type: String,
			required: false,
			default: "collapsed"
		}
	},





	data()
	{
		return {
			states: {
				expanded: "expanded",
				collapsed: "collapsed"
			},

			state: ""	// Values: "expanded", "collapsed"
		};
	},






	mounted()
	{
		this.state = this.initState;


		// Determine if Font Awesome icon should be used.
		this.showFontAwesomeIcon = false;
		if (this.fontAwesomeIconClasses != "") this.showFontAwesomeIcon = true;
	},





	computed: {

	},





	methods: {
		isDisabledState()
		{
			// Return true if this panel needs to be in a "disabled" state
			//console.log("isDisabledState(): this.isAllTimesheetBuckets=", this.isAllTimesheetBuckets, "this.disableDrillInGroupByBucketList=", this.disableDrillInGroupByBucketList);

			if (this.isAllTimesheetBuckets == false && this.disableDrillInGroupByBucketList == true)
			{
				// Disabling the ability to expand bucket group that are no
				return true;
			}

			return false;
		},


		onClick()
		{
			// Toggle the state.

			//console.log("onClick(): this.isAllTimesheetBuckets=", this.isAllTimesheetBuckets, "this.disableDrillInGroupByBucketList=", this.disableDrillInGroupByBucketList);

			if (this.isDisabledState() == true)
			{
				// Disabling the ability to expand bucket groups in a disabled state
				return;
			}


			if (this.state == this.states.collapsed) 
			{
				this.state = this.states.expanded;
				return;
			}


			if (this.state == this.states.expanded)
			{
				this.state = this.states.collapsed;
				return;
			}


		}
	}
}
</script>




<style lang="scss">
	// scoped










	.accordian
	{
		-webkit-transition: height 0.5s linear;
		-moz-transition: height 0.5s linear;
		-ms-transition: height 0.5s linear;
		-o-transition: height 0.5s linear;
		transition: height 0.5s linear;
	}
	.accordian-open
	{
		box-shadow: rgba(0,0,0,0.08) 0px 7px 15px;
		border-radius: 7px;
		transition:all ease 0.250s;
		-webkit-transition: height 0.5s linear;
		-moz-transition: height 0.5s linear;
		-ms-transition: height 0.5s linear;
		-o-transition: height 0.5s linear;
		transition: height 0.5s linear;
	}
	.accordian-closed
	{
		box-shadow: rgba(0,0,0,0.04) 0px 7px 15px;
		border-radius: 7px;
		-webkit-transition: height 0.5s linear;
		-moz-transition: height 0.5s linear;
		-ms-transition: height 0.5s linear;
		-o-transition: height 0.5s linear;
		transition: height 0.5s linear;
	}
	.accordian-header
	{
		background:#222f38 url("https://assets.lotusai.co/portal/images/icon-contract.svg");
		color: white;
		border-radius: 7px 7px 0px 0px;
		padding: 11px 10px 11px 25px;
		background-repeat: no-repeat;
		background-size: 13px;
		background-position: 15px;
		font-size: 14px;
		color: #fcb415;
		cursor: pointer;
		transition:all ease 0.250s;
	}
	.accordian-closed .accordian-header
	{
		background:#7b8288 url("https://assets.lotusai.co/portal/images/icon-expand.svg");
		padding: 11px 10px 11px 25px;
		background-repeat: no-repeat;
		background-size: 13px;
		background-position: 15px;
		border-radius: 7px;
		transition:all ease 0.250s;
		color: white;
	}

	.accordian-disabled .accordian-header,
	.accordian-disabled .accordian-header .icon-with-text
	{
		background:#c3c3c4 ;
		padding: 11px 10px 11px 25px;
		background-repeat: no-repeat;
		background-size: 13px;
		background-position: 15px;
		border-radius: 7px;
		transition:all ease 0.250s;
		color: black;
		cursor: text;
	}


	.accordian-disabled .accordian-header .icon-with-text img
	{
		display: none !important;
	}
	
	
	.accordian-closed .accordian-body
	{
		height:0px !important;
		overflow: hidden !important;
		-webkit-transition: height 0.5s linear;
		-moz-transition: height 0.5s linear;
		-ms-transition: height 0.5s linear;
		-o-transition: height 0.5s linear;
		transition: height 0.5s linear;
	}
	.accordian-open .accordian-body
	{
		height: auto;
		overflow: visible;
		-webkit-transition: height 0.5s linear;
		-moz-transition: height 0.5s linear;
		-ms-transition: height 0.5s linear;
		-o-transition: height 0.5s linear;
		transition: height 0.5s linear;
	}

	.accordian-header .float-right
	{
		color: white;
	}
	.accordian-header img
	{
		margin-top:-3px;
	}
	.accordian-body
	{
		border-radius:0px 0px 7px 7px;
		-webkit-transition: height 0.5s linear;
		-moz-transition: height 0.5s linear;
		-ms-transition: height 0.5s linear;
		-o-transition: height 0.5s linear;
		transition: height 0.5s linear;
	}


	


</style>
<!-- This is a wrapper around an Element UI select component that calls the backend for a list of currencies and allows the user to select one. -->

<!-- Usage notes: -->

<!-- This is a fully controller component: it will not work without v-model or handling 'input' event -->

<!-- the schema for the v-model value with be an object with this shape -->

<!-- 
		{
			"ID": "8",
			"ISO4217Code": "AUD",
			"ISO4217Number": "36",
			"ISO4217DigitsAfterDecimal": 2,
			"CurrencyName": "Australian dollar",
			"Status": "Active"
		}
 -->

 <!-- To start the component with a default value, simply use an object like this { ID: "8" } -->
 <!-- To start the component with no default value, use null -->


<template>


	<!-- use el-select to display dropdown of currencies. -->
	<!-- value is the value prop, unless isFailed, then it is an error message -->

	<el-select
		v-if="isLoading == false"
		:id="id"
		filterable
		default-first-option
		:disabled="isFailed"
		:placeholder="isFailed ? 'Could not load currencies' : 'Select currency'"
		:value="isFailed ? 'Could not load currencies' : selectValue"
		v-model="selectValue"
		@change="onCurrencySelect"
	>
		<!-- we are using indexes for keys here because backend is giving us duplicate IDs -->
		<el-option
			v-for="option, optionIndex in currenciesOptions"
			:key="id + '-' + optionIndex"
			:label="option.text"
			:value="option.value"
		>
		</el-option>
	</el-select>
	<div
		class="skeleton-box input-sized-box"
		v-else-if="isLoading == true && isFailed == false"
	></div>
</template>



<script>
//import 'regenerator-runtime/runtime'; // TODO: not recommneded - import to use async await. 

export default {
	name: 'SelectCurrencies',


	emits: ["input", "update:modelValue"],




	props: {
		// value prop takes an object with the shape:

		// {
		//     "ID": "8",
		//     "ISO4217Code": "AUD",
		//     "ISO4217Number": "36",
		//     "ISO4217DigitsAfterDecimal": 2,
		//     "CurrencyName": "Australian dollar",
		//     "Status": "Active"
		// } | null


		value: {
			type: [Object, null],
			// required: true,
		},

		id: {
			type: String,
			required: true,
		},



		//TODO: New with Vue 3
        modelValue: {
            type: [Object, null],
            default: null,
            required: true
        },




	},








	data() {
		return {
			// boolean whether the component is still fetching the stuff.
			isLoading: true,

			// boolean whether the fetch has failed.
			isFailed: false,



			// raw data from backend
			currencies: [],
		};
	},

	
	
	
	
	
	mounted() {
		this.init();
	},

	
	
	
	
	
	
	
	methods: {
        

		
		/*=============================================
		=            Init            =
		=============================================*/
		

		/**
		 * Master init function coordinates other init functions.
		 */
		async init() {
			// fetch raw currencies data
			await this.fetchAllCurrencies();

			// after that is finished, re-emit the object so that the parent component has more than just the ID
			this.emitFullObject();
		},





		/**
		 * Gets all the currencies from the backend.
		 * 
		 * @returns {Promise} 
		 */
		async fetchAllCurrencies() {
			try {
				
				const response = await this.$root.callApi('/Currencies/GetAllCurrencies', {}, 'GET', {failed: true});

				// console.log("select-currencies :: fetchAllCurrencies::", response);

				// check if failed == true 
				// if so, set this.isFailed to true and isLoading to false:
				if (response.failed == true) {
					this.isFailed = true;
					this.isLoading = false;
					throw new Error();
				}
				

				// push the returned array to state.
				this.currencies = response.Currencies;

				// set isLoading to false
				this.isLoading = false;
			} catch( exception ) {				
				console.error("select-currencies :: fetchAllCurrencies::", exception);
			}
		},








		/**
		 * Upon component Init, re-emit the full object of the currency corresponding to the
		 * value prop passed in by parent.
		 * 
		 * If the parent has { ID: "8" } as the starting value for the component,
		 * we want to emit this:
		 * {
				"ID": "8",
				"ISO4217Code": "AUD",
				"ISO4217Number": "36",
				"ISO4217DigitsAfterDecimal": 2,
				"CurrencyName": "Australian dollar",
				"Status": "Active"
			}
		 * So that the parent has access to all the other stuff in case they need it.
		 */
		emitFullObject() {

			// early exit if incoming prop is null
			if (this.value == null) {
				return;
			}


			// find the currency object with corresponding ID
			let selectedCurrency = this.currencies.find(obj => obj.ID == this.value?.ID);

			// emit whole object
			this.$emit('input', selectedCurrency);
			this.$emit('update:modelValue', selectedCurrency);
		},







		
		/*=============================================
		=            Action Handlers            =
		=============================================*/
		
		
		/**
		 * Since our component is taking a value in an object like so {ID: "id"}
		 * And we are passing on the "id" into the select,
		 * The el-select will only give us the "id" upon @change.
		 * 
		 * We want to emit the whole object corresponding to the selected "id", 
		 * so we will search the currencies array for such ID and return the found object.
		 */
		onCurrencySelect(data) {

			// find the currency object with corresponding ID
			let selectedCurrency = this.currencies.find(obj => obj.ID == data);

			// emit whole object
			this.$emit('input', selectedCurrency);
			this.$emit('update:modelValue', selectedCurrency);
		},
		
		

	},







	computed: {



		// format raw data from api to data suitable for the dropdown component.
		// Options are in this format
		// value = currency.ID
		// text = 'Australian dollar (AUD)'

		currenciesOptions() {
			return this.currencies.map( currency => {
				return {
					value: currency.ID,
					text: currency.CurrencyName + " (" + currency.ISO4217Code + ")",
				};
			});
		},






		// Since our component is taking and returning whole objects,
		// we need to translate the incoming objects into IDs for our dropdown control.
		// we do this by taking the ID of the object and passing it onto our el-select

		selectValue() {
			return this.value?.ID;
		},

	},



};
</script>

<style scoped lang="scss">
/* skeleton box animation from Markus Oberlehner */
/* https://markus.oberlehner.net/blog/skeleton-loading-animation-with-vue/ */

.skeleton-box {
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    background-color: #dddbdd;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
        );
        animation: shimmer 5s infinite;
        content: "";
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}

.input-sized-box {
    width: 15rem;
    height: 2.25rem;

    border-radius: 6px;
}
</style>
<template>
    <div>
        <div v-for="(module) in moduleList" :key="module.name"
            class="navigation-item col-12" 
            :class="{selected : module.routerName.includes(currentRouterName)}" 
            @click="() => $router.replace({ name: module.routerName[0] })"
        >
            <div>
                <span class="icon">
                    <i class="fa" :class="module.icon" aria-hidden="true"></i>
                </span>
                
                <span class="text sidebar-menu" v-if="!isCollapsed">{{ module.name }}</span>
            </div>
        </div> 
    </div>
			
</template>

<script>
    export default {
		name: "Primary2Sidebar",

        props : {
            isCollapsed : {
                type : Boolean,
                default : false
            }
        },

		computed : {
			currentRouterName() {
				return this.$route.name
			}
		},

		data() {
			return {
				moduleList : [
					{
						icon : 'fa-home',
						name : 'Home',
						routerName : ['tessta-clients-index', 'tessta-clients-home-index']
					},
					{
						icon : 'fa-exclamation-triangle',
						name : 'Defects',
						routerName : ['tessta-clients-defects-index']
					},
					{
						icon : 'fa-sitemap',
						name : 'Sites',
						routerName : ['tessta-clients-sites-index']
					},
					{
						icon : 'fa-file-text-o',
						name : 'Assessments',
						routerName : []
					}
				]
			}
		}
    }
</script>

<style scoped>
	.need-help-link{
		color: #F5BD4F;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: -0.048px;
		text-decoration-line: underline;
	}
	.sidebar-menu{
		color: #565656;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		letter-spacing: -0.096px;
	}
	.navigation-item{
		padding: 20px !important;
	}
	.selected{
		border-radius: 15px;
		background: rgba(203, 203, 203, 0.20);
	}
	.selected .icon{
		color: #EBC052;
	}
	.selected .sidebar-menu{
		color: var(--default-color, #33394A);
		font-size: 24px;
		font-style: normal;
		font-weight: 600 !important;
		line-height: normal;
		letter-spacing: -0.096px;
	}
</style>
<template>
    <span class="required-field" title="Required">*</span>
</template>





<script>
export default {

}
</script>





<style lang="scss" scoped>
    .required-field
    {
        color:red;
        display: inline-block;
        margin-left: 5px;

        cursor: help;
    }
</style>
/**
 * All API calls should be routed through these methods to ensure consistent and centralised exception handling, response data, etc.
 * 
 * This is version 2 of the API, implementing `reject()` in `callApiHandler()`, with a corresponding v2 `callApi()` to be 
 * implemented in the Vue app root. The Vue v2 handler will also automatically provide processing overlays unless explicitly ignored.
 */

import config from "./config.js";
import dateHelpers from "./helpers/dateHelpers.js";
import globalStore from "./globalStore";
import tesstaFakeApiHandler from "./module.tessta/fake-api/handler";
import tesstaClientsFakeApiHandler from "./module.tessta-clients/fake-api/handler";


const api = {



	/**
     * Call the Lotus back-end API.
     * 
     * @param {string} endpoint The root relative URL of the endpoint to call. Starts with a `/` (e.g. `/Clients/GetClientById`).
     * @param {object} data The data to submit in the request to the API.
     * @param {function} verb The Verb of the Http request - "GET" or "POST". Default is `GET`.
     */
	callApi: function(endpoint, data, verb = "GET")
	{
		return this.callApiHandler("", endpoint, data, verb, false);
	},





	/**
     * Call the Lotus "Files" API.
     * 
     * @param {string} endpoint The root relative URL of the endpoint to call. Starts with a `/` (e.g. `/Upload`).
     * @param {object} data The data to submit in the request to the API. At this stage, a property named "file" is required for the actual file.
     * @param {function} verb The Verb of the Http request - "GET" or "POST". Default is `GET`.
     */
	callFilesApi: function(endpoint, data, verb = "GET")
	{
		var baseUrl = config.GetLotusFilesApiBaseUrl()

		return this.callApiHandler(baseUrl, endpoint, data, verb, true);
	},





    // Reference: https://www.w3schools.com/xml/ajax_xmlhttprequest_create.asp
    /**
     * Call the Lotus back-end API.
     * 
	 * @param {string} baseUrl Set this to specifically define the based API URL (i.e. the domain) instead of using `config.GetLotusApiBaseUrl()` (which is the default). Leave the value blank/null/falsy to default to `config.GetLotusApiBaseUrl()`.
     * @param {string} endpoint The root relative URL of the endpoint to call. Starts with a `/` (e.g. `/Clients/GetClientById`).
     * @param {object} data The data to submit in the request to the API.
     * @param {function} verb The Verb of the Http request - "GET" or "POST". Default is `GET`.
     */
    callApiHandler: function(baseUrl, endpoint, data, verb = "GET", isFileUpload = false)
    {

		//-- Handle any fake API calls first (e.g. when developing a new module or feature that does not yet have an actual API).
		var fakeResolveResponse = {
			status: "success",
			data: null,
			lotusStatusCode: "success",
			lotusStatusDetails: "",

			failDisplayTitle: "",
			failDisplayMessage: "",
			logWarning: false
		};

		// if (endpoint.startsWith("/Module/Tessta/Portal"))
		// {
		// 	// Live API - let it run.
		// }


		
		// if (endpoint.startsWith("/Module/Tessta/") || endpoint.startsWith("/Module/Tessta/Portal"))
		// {
		// 	// const fakePromise = new Promise(function(resolve, reject) {
		// 	// 	const responseData = tesstaFakeApiHandler.handleRequest(endpoint, data, verb);
		// 	// 	fakeResolveResponse.data = responseData;
		// 	// 	resolve(fakeResolveResponse);
		// 	// });

		// 	// return fakePromise;

		// 	const responseData = tesstaFakeApiHandler.handleRequest(endpoint, data, verb);

		// 	console.log(">>> API [" + verb + "][" + endpoint + "]: responseData=", responseData, " | data=", data);

		// 	if (responseData != null)
		// 	{
		// 		const fakePromise = new Promise(function(resolve, reject) {
		// 			fakeResolveResponse.data = responseData;
		// 			resolve(fakeResolveResponse);
		// 		});
	
		// 		return fakePromise;
		// 	}

		// 	// Continue with real API
		// 	console.log("   -- API: Continue live");
		// }

		// if (endpoint.startsWith("/Module/TesstaClients/"))
		// {
		// 	const responseData = tesstaClientsFakeApiHandler.handleRequest(endpoint, data, verb);

		// 	console.log(">>> API [" + verb + "][" + endpoint + "]: responseData=", responseData, " | data=", data);

		// 	if (responseData != null)
		// 	{
		// 		const fakePromise = new Promise(function(resolve, reject) {
		// 			fakeResolveResponse.data = responseData;
		// 			resolve(fakeResolveResponse);
		// 		});
	
		// 		return fakePromise;
		// 	}

		// 	// COntinue with real API
		// 	console.log("   -- API: Continue live");
		// }


		//-- Calling a live API.

		if (!endpoint.startsWith("/"))
		{
			// URL must start with a "/" to combine for a valid full URL.
			throw "api2.callApiHandler(): endpoint '" + endpoint + "' required to start with a '/' to be able to combine with the domain into a valid full URL.";
		}

		const promise = new Promise(function(resolve, reject) {
	
			let xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = function() 
			{
				//console.log("api::callApi.onreadystatechange(" + verb + ", " + endpoint + "): xhttp.readyState=", xhttp.readyState, "xhttp.status=", xhttp.status);
				var resolveResponse = {
					status: "fail",
					data: null,
					lotusStatusCode: lotusStatusCode,
					lotusStatusDetails: lotusStatusDetails,

					failDisplayTitle: "",
					failDisplayMessage: "",
					logWarning: false
				}

				if (this.readyState == 4 && (this.status == 200 || this.status == 401))
				{
					// console.log("api::callApi(" + verb + ", " + endpoint + "): Response SUCCESS. xhttp=", xhttp);

					var lotusStatusCode = xhttp.getResponseHeader("lotus-status-code");
					var lotusStatusDetails = xhttp.getResponseHeader("lotus-status-details");

					//console.log("api::callApi(" + verb + ", " + endpoint + "): lotusStatusCode=", lotusStatusCode, "lotusStatusDetails=", lotusStatusDetails);

					// var allHeaders = xhttp.getAllResponseHeaders();
					// console.log("api::callApi(" + verb + ", " + endpoint + "): allHeaders=", allHeaders);
					


					if (lotusStatusCode == "success")
					{
						// API action successfully completed.

						//var responseData = JSON.parse(xhttp.response);    // Nope, response is already an object.
						var responseData = xhttp.response;

						if (responseData == null)
						{
							// Something's not quite right.
							console.warn("api::callApi(" + verb + ", " + endpoint + "): No API response data. RequestData=", data);
							reject(resolveResponse);
						}
						else
						{
							// Data successfull returned from API.
							resolveResponse.data = responseData;
							resolveResponse.status = "success"
							resolve(resolveResponse);
						}
					}
					else if (lotusStatusCode == "not-authorised")
					{
						// User not allowed to perform the API action.
						console.warn("api::callApi(" + verb + ", " + endpoint + "): Not Authorised. RequestData=", data);

						resolveResponse.failDisplayTitle = "Not Allowed";
						resolveResponse.failDisplayMessage = "Action Failed - Not Allowed.<br>You are not allowed to perform the action";
						
						reject(resolveResponse);
					}
					else if (lotusStatusCode == "failed-validation")
					{
						// Failure validating submitted data.
						// UI should perform all the user friendly validation. Back-end only performs check and stop continued process if failure.
						console.warn("api::callApi(" + verb + ", " + endpoint + "): Failed Validation. RequestData=", data);

						resolveResponse.failDisplayTitle = "Failed Validation";
						resolveResponse.failDisplayMessage = "Action failed.<br>Invalid value provided - this message should only be seen by software developers.<br><br>Details: " + lotusStatusDetails;

						reject(resolveResponse);
					}
					else if (lotusStatusCode == "offline")
					{
						//TODO: I don't think this branch will ever be received. See `else if (xhttp.readyState == 4 && xhttp.status == 0)` below
						console.error("api::callApi(" + verb + ", " + endpoint + "): FAIL. Server response not received.");

						resolveResponse.failDisplayTitle = "Offline";
						resolveResponse.failDisplayMessage = "Action failed.<br>It looks like the beating heart of Lotus AI operations cannot be reached at the moment.<br>Please check your internet connection, or wait a few moments in case this is a temporary interruption, then try again.<br>If you continue to have problems please email <a href='mailto:support@lotusai.co' style='color: blue;'>support@lotusai.co</a>";

						reject(resolveResponse);
					}
					else
					{
						// Some other non-successful result.
						console.warn("api::callApi(" + verb + ", " + endpoint + "): Some other fail result (" + lotusStatusCode + ", " + lotusStatusDetails + "). RequestData=", data);

						resolveResponse.failDisplayTitle = "Something Went Wrong";
						resolveResponse.failDisplayMessage = "Action failed.<br>Something went wrong.<br>Lotus AI developers have been alerted and will investigate as soon as possible.<br>If you do not hear about the issue or would like to contact us, please email <a href='mailto:support@lotusai.co' style='color: blue;'>support@lotusai.co</a>.";
						resolveResponse.logWarning = true;

						reject(resolveResponse);
					}
				}
				else if (xhttp.readyState == 4 && xhttp.status == 0) 
				{
					// The server is offline

					console.error("api::callApi(" + verb + ", " + endpoint + "): FAIL. Server response not received.");

					resolveResponse = {
						status: "fail",
						data: null,
						lotusStatusCode: "offline",
						lotusStatusDetails: "Server repsonse not received",

						failDisplayTitle: "Offline",
						failDisplayMessage: "Action failed.<br>It looks like the beating heart of Lotus AI operations cannot be reached at the moment.<br>Please check your internet connection, or wait a few moments in case this is a temporary interruption, then try again.<br>If you continue to have problems please email <a href='mailto:support@lotusai.co' style='color: blue;'>support@lotusai.co</a>",

						logWarning: false
					}

					reject(resolveResponse);
				}
				else
				{
					//-- Something went wrong? Or it's just the OPTIONS response? Either way, skip this branch and don't return anything.

					//console.warn("api::callApi(" + verb + ", " + endpoint + "): readyState=" + this.readyState + ", status=" + this.status);
					//reject("readyState=" + this.readyState + ", status=" + this.status);
					
					//TODO: Don't reject. It may be a response from the API before full completion.
					
					// console.log("api::callApi(" + verb + ", " + endpoint + "): FAIL.", this.readyState, this.status);
				}
	
				/*
				readyState	
					Holds the status of the XMLHttpRequest.
					0: request not initialized
					1: server connection established
					2: request received
					3: processing request
					4: request finished and response is ready
				status	
					200: "OK"
					403: "Forbidden"
					404: "Page not found"
					For a complete list go to the Http Messages Reference (https://www.w3schools.com/tags/ref_httpmessages.asp)
				*/
			};
			
			// console.log("api::callApi(" + verb + ", " + endpoint + "): data=", data);

	
			// If an explicit base URL is supplied then use it, otherwise use the default URL defined in config.
			let url = baseUrl ? baseUrl : config.GetLotusApiBaseUrl();

			// And append the endpoint to have the complete URL to call.
			url = url + endpoint;
	

			if (verb != "GET" && verb != "POST")
			{
				throw "API::callApi(): Unknown verb '" + verb + "' (expecting: `GET` or `POST`)";
			}
	
			// console.log("globalStore.state.currentUser=", JSON.stringify(globalStore.state.currentUser));
			// console.log("globalStore.state.currentUser.authToken=", globalStore.state.currentUser.authToken);

			if (verb == "GET")
			{
				// Ref: https://howchoo.com/g/nwywodhkndm/how-to-turn-an-object-into-query-string-parameters-in-javascript
				var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
				
				url = url + "?" + queryString;

				// console.log("api::callApi(GET: " + url + ")");
			}


			xhttp.open(verb, url, true);
			xhttp.setRequestHeader('Lotus-User-AuthToken', globalStore.state.currentUser.authToken);
			xhttp.setRequestHeader('Lotus-Agent-LocalDateTime', dateHelpers.getLocalDateAndTimeAsIso());
			xhttp.setRequestHeader('Lotus-Agent-LocalDateTimeUtcOffset', dateHelpers.getLocalUtcOffset());

			//NOTE: [jsnelders, 2024-08-28] This because because I noticed some calls, in some circumstances would retrieve
			//		from the disk cache rather than call the actual endpoint.
			//		E.g. account admin > Users. Manage person. Update their details. "Back" to Users page. On return, it retrieves
			//		from cache and doesn't have the latest updated details.
			// REf: https://stackoverflow.com/questions/22356025/force-cache-control-no-cache-in-chrome-via-xmlhttprequest-on-f5-reload
			xhttp.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");

			xhttp.responseType = "json";

			xhttp.timeout = 0;	// Ensure no timeout	(https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/timeout) Also. https://stackoverflow.com/a/46117499/115704
	
			// xhttp.setRequestHeader('Access-Control-Allow-Headers', '*');
			// xhttp.setRequestHeader('Access-Control-Allow-Origin', '*');
	
			//console.log("api::callApi.send(" + verb + ", " + endpoint + ")");

			if (isFileUpload == true)
			{
				//Ref: https://gabrieleromanato.name/javascript-file-upload-with-the-xmlhttprequest-object
				xhttp.setRequestHeader("Content-Type", "multipart/form-data");

				// We set the "Content-Type" header to "multipart/form-data"
				xhttp.setRequestHeader("Content-Type", "multipart/form-data");

				// We create a FormData object and add the selected file
				const formData = new FormData();
				formData.append("file", data.file);

				// We send the request to the server with the send() method
				xhttp.send(formData);
			}
			else if (verb == "GET")
			{
				xhttp.send();
			}
			else if (verb == "POST")
			{
				// console.log("api::callApi(POST: " + url + "): data=", data);
	
				const postJsonData = JSON.stringify(data);
	
				// console.log("postJsonData=", postJsonData);

				xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
				xhttp.send(postJsonData);
			}
		});
		
		return promise;
    },



//-- Example usage

// loginRequestData = {};
// api.callApi("/Authenticate/Login", loginRequestData, "POST")
// .then((responseData) => {
// 	console.log("caller(): responseData=", responseData);

// 	// Do something
// })
// .catch( (responseData) => {
// 	console.warn("caller(): Failed. responseData=", responseData);

// 	// No action required. Warning message handled via central API.
// });







    // Source: https://www.w3schools.com/xml/ajax_intro.asp

    /**
     * Call an external API (not part of the Lotus back-end platform).
     * 
     * @param {string} verb HTTP verb to use in the call ("GET" or "POST").
     * @param {string} url The URL of the API to call.
     * @param {object} data The request data to pass to the API.
     * @param {object} headers HTTP headers to send to the API.
     * @param {function} callback Callback function to handle the response to this call. Takes a single parameter of type apiResponse().
     */
    callExternal: function(verb, url, data, headers = null, callback = undefined)
    {
        if (verb != "GET" && verb != "POST")
        {
            throw "API::callExternal(): Unknown verb '" + verb + "' (expecting: GET or POST)";
        }

        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() 
        {
            if (this.readyState == 4 && this.status == 200) 
            {
                // Typical action to be performed when the document is ready:
                //console.log("xhttp.responseText", xhttp.responseText);
                var apiJson = xhttp.response;
                var apiData = JSON.parse(apiJson);

				callback(apiData);
            }
        };


        xhttp.open(verb, url, true);
        // xhttp.setRequestHeader('Access-Control-Allow-Headers', '*');
        // xhttp.setRequestHeader('Access-Control-Allow-Origin', '*');

        if (headers != null)
        {
            for (const header in headers)
            {
                xhttp.setRequestHeader(header, headers[header]);
            }
        }

        xhttp.responseType = "json";

        if (verb == "GET")
        {
            xhttp.send();
        }
        else if (verb == "POST")
        {
            const postJsonData = JSON.stringify(data);
            xhttp.send(postJsonData);
        }
    }
}





export default api;
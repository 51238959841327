import globalStore from "/globalStore";
import config from "/config";

const tesstaFakeApiHandler = {
	/**
	 * The public entry point for this module
	 * 
	 * @param {string} endpoint The standard Lotus API endoint URL. Root relative.
	 * @param {object} request A standard API request data object
	 * @returns {object} Any response data. If no data to return then return a blank object `{}`.   
	 * Do not return `null` as this what's expected when there's a problem with an API call.
	 */
	handleRequest(endpoint, request, verb)
	{
		function is(value, compareTo)
		{
			return value.toLowerCase() === compareTo.toLowerCase();
		}

		endpoint = endpoint.toLowerCase();


		// Follow this `if(....) return` pattern for each endpoint that needs to be handled.
		// Create a separate function that handleds
		if (is(endpoint, "/Module/Tessta/ExampleEndpoint")) return this.exampleEndpoint(request);




		//if (is(endpoint, "/Module/Tessta/Clients")) return this.clientsEndpoint(request);
		if (is(endpoint, "/Module/Tessta/Portal/Clients/GetClientsInAccount/v1"))
		{
			return null;	// Live API
			//return this.clientsEndpoint(request);
		}

		//if (is(endpoint, "/Module/Tessta/Clients/CreateClient"))  return this.createClientEndpoint(request);
		if (is(endpoint, "/Module/Tessta/Portal/Clients/CreateClient/v1"))
		{
			return null;	// Live API
			//return this.createClientEndpoint(request);
		}

		//if (is(endpoint, "/Module/Tessta/Clients/UpdateClient"))  return this.updateClientEndpoint(request);
		if (is(endpoint, "/Module/Tessta/Portal/Clients/UpdateClient/v1"))  
		{
			return null;	// Live API
			//return this.updateClientEndpoint(request);
		}

		//if (is(endpoint, "/Module/Tessta/Clients/GetClient"))  return this.getClientEndpoint(request);
		if (is(endpoint, "/Module/Tessta/Portal/Clients/GetClientByID/v1"))
		{
			return null;	// Live API
			//return this.getClientEndpoint(request);
		}


		//if (is(endpoint, "/Module/Tessta/Sites")) return this.sitesEndpoint(request);
		//if (is(endpoint, "/Module/Tessta/Portal/Sites/GetSitesInAccount/v1")) return this.sitesEndpoint(request);

		//if (is(endpoint, "/Module/Tessta/Sites/GetSite"))  return this.getSiteEndpoint(request);
		//if (is(endpoint, "/Module/Tessta/Portal/Sites/GetSiteByID/v1"))  return this.getSiteEndpoint(request);

		//if (is(endpoint, "/Module/Tessta/Sites/UpdateSite"))  return this.updateSiteEndpoint(request);
		//if (is(endpoint, "/Module/Tessta/Portal/Sites/UpdateSite/v1"))  return this.updateSiteEndpoint(request);



		//if (is(endpoint, "/Module/Tessta/Sites/GetAeesesment"))  return this.getAssessmentEndpoint(request);
		//if (is(endpoint, "/Module/Tessta/Portal/Assessments/GetAssessmentByID/v1"))  return this.getAssessmentEndpoint(request);



		//if (is(endpoint, "/Module/Tessta/Projects"))  return this.getProjectsEndpoint(request);
		//if (is(endpoint, "/Module/Tessta/Portal/GetProjectsInAccount/v1"))  return this.getProjectsEndpoint(request);

		//if (is(endpoint, "/Module/Tessta/Projects/GetProject"))  return this.getProjectEndpoint(request);
		//if (is(endpoint, "/Module/Tessta/Portal/Projects/GetProjectByID/v1"))  return this.getProjectEndpoint(request);


		// //if (is(endpoint, "/Module/Tessta/QA/GetOpenProjects"))  return this.getProjectsEndpoint(request);
		// if (is(endpoint, "/Module/Tessta/Portal/GetProjectsInAccount/v1"))  return this.getProjectsEndpoint(request);

		// // Default response for an unhandled request
		// return {};

		// Let the live API handle it now.
		return null;
	},



	localStorageKeyPrefix: "FakeAPI:Tessta:",

	async fetchJsonContent(fileName)
	{
		let url = "/module.tessta/fake-api/json/" + fileName;

		if (config.IsTest())
		{
			url = "https://assets.lotusai.co/portal/tessta-dev-json/" + fileName;
		}

		const jsonFileResposnse = await fetch(url);
		//console.log("jsonFileResposnse=", jsonFileResposnse);

		const jsonObject = await jsonFileResposnse.json();	// Already parsed to an object.
		//console.log("jsonObject=", jsonObject);

		return jsonObject;
	},


	// This is an example of a fake endpoint handler.
	// Every handler must return an object that represents the API response object.
	async exampleEndpoint(request)
	{
		const response = {};

		//TODO: Logic in here for any saving/retrieval of data.


		//-- The following is an example of reading data from a /module.tessta/fake-api/json/**/* file.
		//
		//IMPORTANT NOTE!: This works in localhost, however, I haven't managed to get this working in the https://lotusai-test-tessta.netlify.app
		//				i.e. https://lotusai-test-tessta.netlify.app/module.tessta/fake-api/json/ExampleEndpoint.json does not return the JSON file.
		//				I didn't have time to figure out why.
		//				You may need to host your .json files in another domain you manage if you can't get this working.

		let url = "/module.tessta/fake-api/json/ExampleEndpoint.json";

		if (config.IsTest())
		{
			url = "https://assets.lotusai.co/portal/tessta-dev-json/";
		}

		const jsonFileResposnse = await fetch(url);
		//console.log("jsonFileResposnse=", jsonFileResposnse);

		const jsonObject = await jsonFileResposnse.json();	// Already parsed to an object.
		//console.log("jsonObject=", jsonObject);

		return jsonObject;
		
		


		//-- The following is an example of how to save and retrieve data from LocalStorage, using already available helpers.
		// Use localStorage helpers in `global` for saving and retrieving data.
		// Create a stroage key for each required case. Make sure to prefix with localStorageKeyPrefix
		// Example use case key
		const storageKey = this.localStorageKeyPrefix + "ExampleEndpoint";

		// When saving data to local storage
		globalStore.localStorage.saveDataToStorage(storageKey, request);

		// When retrieving data from local storage
		var savedData = globalStore.localStorage.getDataFromStorage(storageKey);
		response.ExampleData = savedData.ExampleData;
		response.Sites = savedData.Sites;

		return response;
	},


	async clientsEndpoint(request){
		
		const {filters} = request;

		// const jsonFileResposnse = await fetch("/module.tessta/fake-api/json/Clients.json");
		// const jsonObject = await jsonFileResposnse.json();	// Already parsed to an object.
		const jsonObject = await this.fetchJsonContent("Clients.json");
		
		if(filters.name ||filters.email ||filters.phone || filters.address ){
			let filteredJsonObject = jsonObject;
			const data =jsonObject.Data.filter( data => {
				let flag = true;
				if(filters.name  && flag){
					flag = data.Name.includes(filters.name)
				}
				if(filters.email  && flag ) {
					flag = data.Email.includes(filters.email)
				}
				if(filters.phone  && flag ) {
					flag = data.Phone.includes(filters.phone)
				}
				if(filters.address  && flag ) {
					flag = data.Address.Line1.includes(filters.address)
				}

				return flag;
			})
		
			filteredJsonObject.TotalFiltered = data.length;
			filteredJsonObject.Data = data;
			return filteredJsonObject;
		} else {
			return jsonObject;
		}
		
	},

	createClientEndpoint(request){
		return request;
	},

	updateClientEndpoint(request){
		return request;
	},

	async getClientEndpoint(request){
		// const jsonFileResposnse = await fetch("/module.tessta/fake-api/json/GetClient.json");
		// const jsonObject = await jsonFileResposnse.json();	// Already parsed to an object.
		const jsonObject = await this.fetchJsonContent("GetClient.json");
		
		return jsonObject;
	},


	async sitesEndpoint(request){
		
		const {filters} = request;

		// const jsonFileResposnse = await fetch("/module.tessta/fake-api/json/Sites.json");
		// const jsonObject = await jsonFileResposnse.json();	// Already parsed to an object.
		const jsonObject = await this.fetchJsonContent("Sites.json");

		if(filters.name ||filters.client ||filters.asset_code || filters.address ){
			let filteredJsonObject = jsonObject;
			const data =jsonObject.Data.filter( data => {
				let flag = true;
				if(filters.name  && flag){
					flag = data.Name.includes(filters.name)
				}
				if(filters.client  && flag ) {
					flag = data.Client.Name.includes(filters.client)
				}
				if(filters.asset_code  && flag ) {
					flag = data.AssetCode.includes(filters.asset_code)
				}
				if(filters.address  && flag ) {
					flag = data.Address.Line1.includes(filters.address)
				}

				return flag;
			})
		
			filteredJsonObject.TotalFiltered = data.length;
			filteredJsonObject.Data = data;
			return filteredJsonObject;
		} else {
			return jsonObject;
		}
		
	},

	async getSiteEndpoint(request){
		// const jsonFileResposnse = await fetch("/module.tessta/fake-api/json/GetSite.json");
		// const jsonObject = await jsonFileResposnse.json();	// Already parsed to an object.
		const jsonObject = await this.fetchJsonContent("GetSite.json");
		
		return jsonObject;
	},

	updateSiteEndpoint(request){
		return request;
	},

	async getAssessmentEndpoint(request){
		// const jsonFileResposnse = await fetch("/module.tessta/fake-api/json/SiteAssessment.json");
		// const jsonObject = await jsonFileResposnse.json();	// Already parsed to an object.
		const jsonObject = await this.fetchJsonContent("SiteAssessment.json");
		
		return jsonObject;
	},

	async getProjectsEndpoint(request){
		const {filters} = request;

		// const jsonFileResposnse = await fetch("/module.tessta/fake-api/json/Projects.json");
		// const jsonObject = await jsonFileResposnse.json();	// Already parsed to an object.
		const jsonObject = await this.fetchJsonContent("Projects.json");
		
		if(filters.site ||filters.client ||filters.status || filters.address ){
			let filteredJsonObject = jsonObject;
			const data =jsonObject.Data.filter( data => {
				let flag = true;
				if(filters.site  && flag){
					flag = data.Client.Site.Name.includes(filters.site)
				}
				if(filters.client  && flag ) {
					flag = data.Client.Name.includes(filters.client)
				}
				if(filters.status  && flag ) {
					flag = data.Status.includes(filters.status)
				}
				if(filters.address  && flag ) {
					flag =  data.Client.Site.Address.includes(filters.address)
				}

				return flag;
			})
		
			filteredJsonObject.TotalFiltered = data.length;
			filteredJsonObject.Data = data;
			return filteredJsonObject;
		} else {
			return jsonObject;
		}
	},


	async getProjectEndpoint(request){
		// const jsonFileResposnse = await fetch("/module.tessta/fake-api/json/GetProject.json");
		// const jsonObject = await jsonFileResposnse.json();	// Already parsed to an object.
		const jsonObject = await this.fetchJsonContent("GetProject.json");
		
		return jsonObject;
	},


}

export default tesstaFakeApiHandler;
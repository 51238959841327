<template>
	<div v-if="canViewEntityHistory()">
		<c-button-standard id="view-entity-history" @click="handleClick" title="View change history">
			<template v-slot:icon><i class="fas fa-history"></i></template>
			<!-- <template v-slot:text>View change history</template> -->
		</c-button-standard>
	</div>


	<c-modal2
		id="change-history-modal"
		:trigger="modalState"
		type="modal"
		size="large"
		:show-cancel-button="true"
		cancel-button-text="Close"
		@action="(data) => handleModalAction(data)"
	>
		<template v-slot:header>Change History</template>

		<p style="font-style: italic;" class="mb-3">
			Only up to the last 10 changes are listed at this stage.
		</p>
		<div style="max-height: 300px; overflow-y: scroll;">
			<table class="list">
				<thead>
					<tr>
						<th style="width: 200px">When</th>
						<th style="width: 100px">Action</th>
						<th style="width: auto">Changes</th>
					</tr>
				</thead>
				<tbody>
					<tr class="list-item" v-for="(item, idx) in logs" :key="item.ID">
						<td class="list-item">
							<small>
								{{ item.ChangeDateChangedCurrentUserLocalDisplayDate }} @ {{ item.ChangeDateChangedCurrentUserLocalDisplayTime }}
							</small>
						</td>
						<td class="list-item">
							<small>
								{{ item.Action }}
							</small>
						</td>
						<td class="list-item">
							<div v-if="!item.Diffs || item.Diffs.length == 0">
								<div v-if="item.Action == 'Insert'">
									Initial creation.
								</div>
								<div v-else>
									Save without any changes.
								</div>
							</div>

							<div v-if="item.Diffs && item.Diffs.length > 0">
								<div v-for="diff in item.Diffs" :key="diff.Property">
									<code class="change-value-property">{{ diff.Property }}</code> 
									<small>changed from</small> 
									<code class="change-value">{{ getValue(diff.From) }}</code>
									<small>to</small> 
									<code class="change-value">{{ getValue(diff.To) }}</code>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

	</c-modal2>
</template>





<script>
export default {
	components: {

	},


	props: {
		entityName: {
			type: String,
			default: "",
			required: true
		},

		entityId: {
			type: String,
			default: "",
			required: true
		}
	},


	watch: { 
		entityId: function(newVal, oldVal) {
			this.init();
		},
	},


	emits: ["list-change", "update:modelValue"],
	
	data()
	{
		return {
			logs: [],

			modalState: "closed"
		};
	},


	mounted()
	{
		this.init();
	},



	methods: {
		init()
		{
			this.getEntityHistoryLogs();
		},


		getEntityHistoryLogs()
		{
			var _this = this;

			const requestData = {
				EntityName: this.entityName,
				EntityID: this.entityId
			};

			this.$root.callApi2WithOverlay("GET", "/EntityHistory/GetLastNForEntity/v1", requestData)
			.then((responseData) => {
				//console.log("getEntityHistoryLogs(): responseData=", responseData);
				if (responseData == null) return;

				_this.logs = responseData.Logs;
			});
		},



		getValue(rawValue)
		{
			if (!rawValue) return "[NO VALUE]";

			return rawValue; 
		},




		canViewEntityHistory()
		{
			if (this.$root.hasAccess('LotusDevelopment.All')) return true;

			return false;
		},



		handleClick()
		{
			this.modalState = "open";
		},




		// Handle the "action" event of the "edit section" modal.
		handleModalAction(data)
		{
			//console.log("onEditSectionModalAction(): data=", data);

			this.modalState = "closed";
		},




		
	}
}
</script>





<style lang="scss" scoped>
// @import './mobile-app.scss'


td.list-item
{
	text-align: left;
	vertical-align: top;
	// border-bottom: 1px solid #cccccc;
	padding-top: 5px;
	padding-bottom: 5px;
}


.change-value-property,
.change-value
{
	color: green;
	display: inline-block;
	margin-left: 8px;
	margin-right: 8px;
	border-bottom: 1px dotted #aaa;
}

.change-value-property
{
	margin-left: 0;
}
</style>
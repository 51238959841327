<template>
	<div style="display: inline">
		<h1 v-if="type == 'normal' && hSize== 'h1'" class="text-warning h1 font-weight-normal">
			<slot></slot>
		</h1>
		<h3 v-if="type == 'normal' && hSize == 'h3'" class="text-warning h3 font-weight-normal">
			<slot></slot>
		</h3>
		<h4 v-if="type == 'normal' && hSize == 'h4'" class="text-primary mb-3">
			<slot></slot>
		</h4>

		<h5 v-if="type == 'center'" class="text-primary h3 text-center">
			<slot></slot>
		</h5>
	</div>
</template>





<script>
export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},


		hSize: {
			type: String,
			required: false,
			default: "h1"
		},
		

		type: {
			type: String,
			required: false,
			default: "normal"
		},
	},





	data()
	{
		return {
			
		};
	},






	mounted()
	{
		
	},





	computed: {

	},





	methods: {

	}
}
</script>





<style scoped lang="scss">
@import "/assets/sass/_global.scss";

</style>
<template>
	<div class="login-container">
                    
		<img src="https://assets.lotusai.co/portal/images/logo-white-icon.png" alt="" class="header-logo mb-4 login-logo">
		
	
		<h2 class="login-title">Lotus AI</h2>

		<c-layout-container>
			<div class="field-container">
				<c-input-label forName="email-label" :is-required="true">
					Email
				</c-input-label>
				<c-input-text id="email" name="" :initial-value="login.email" v-model="login.email" width="100%"></c-input-text>
			</div>


			<div class="field-container">
				<c-input-label forName="password-label" :is-required="true">
					Password
				</c-input-label>
				<c-input-text id="password" name="" :initial-value="login.password" v-model="login.password" width="100%"></c-input-text>
			</div>


			<div class="field-container">
				<c-input-label forName="remember-me-label" :is-required="false">
					Remember me?
				</c-input-label>
				<c-input-checkbox class="pl-3" id="remember-me" :initial-value="login.rememberMe" v-model="login.rememberMe"></c-input-checkbox>
			</div>


			<div class="field-container">
				<c-button-confirm id="login-button" @click="onLoginClick">
					<template v-slot:text>Login</template>
				</c-button-confirm>
			</div>
		</c-layout-container>

	</div>
</template>

<script>
export default {
	data() {
		return {
            //-- Login
            login: {
                email: "",
                password: "",
                rememberMe: true,
            },
		}
	},



	methods: {
        onLoginClick()
        {
            this.$parent.showView("current-status", false);
        },
	}
}
</script>

<style lang="scss" scoped>
@import './mobile-app.scss'
</style>
<template>
	<div class="c-readonly-field c-input-native-base-restyle">
		<div class="inner-container">
			{{ modelValue }}
		</div>
	</div>
</template>





<script>
//Note: `data-lpignore="true"` to stop LastPass icon
import allFormElementPropsMixin from "./all-form-element-props-mixin";


export default {

	mixins: [allFormElementPropsMixin],

	props: {
		initialValue: {
			type: String,
			default: ""
		},

		
		placeholder: {
			type: String,
			default: ""
		},

		
		maxlength: {
			type: String,
			default: ""
		},

		isBold: {
			type: Boolean,
			default: false,
		},


		preventFormSubmitOnEnter: {
			type: Boolean,
			default: false,
			required: false,
		},



		//Ref: https://vuejs.org/guide/components/v-model.html
		//TODO: New with Vue 3
        modelValue: {
            type: String,
            default: '',
            required: true
        },

	},





	data()
	{
		return {
			value: ""
		};
	},



	mounted()
	{
		this.value = this.initialValue;
	},


	//TODO: Do I seriously need to do this for each control. Why? Because mounted doesn't work, probably because we're getting a value from the API in the parent and it needs to flow through to here, after this control is mounted.
	watch: {
		initialValue: function(to, from)
		{
			this.value = this.initialValue;
		}
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {

	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
	
	.c-readonly-field
	{
		width: 100%;
		font-size: inherit;
	}
</style>

<style scoped lang="scss">
	
	.c-readonly-field
	{
		padding: 0;

		// background-color: rgba($color: #ffffff, $alpha: 0.2);

		// border-radius: 4px;
		//border-bottom: 1px dotted #ddd;
	}

	.c-readonly-field.c-input-native-base-restyle
	{
		border: none;
	}

	.inner-container
	{
		padding: 4px;

		min-height: 24px;
	}
</style>


<template>
    <div :class="['container-fluid', 'environment-banner', environmentCode]" v-if="canShowBanner() == true">
        <div class="container environment-banner-inner">
            <div class="row">
                <div class="col">
                    {{environmentName}}
					<!-- <a v-if="canCurrentUserAccessSystemAdmin" href="/system-admin">System Admin</a> -->
                </div>
            </div>
        </div>
    </div>
</template>





<script>
import config from "/config"
import globalStore from '/globalStore';



export default {
	name: "EnvironmentBanner",

	data()
	{
		return {
			globalStore: globalStore,
			environmentName: config.GetEnvironmentName(),
			environmentCode: config.GetEnvironmentCode(),
			// isProduction: config.IsProduction(),
			// isDevelopment: config.IsDevelopment(),
			canCurrentUserAccessSystemAdmin: true
		};
	},



	mounted()
	{
		this.canCurrentUserAccessSystemAdmin = this.$root.isSystemAdminUser();
	},





	methods: {
		canShowBanner()
		{
			if (this.canCurrentUserAccessSystemAdmin == false)
			{
				// Don't show the banner for standard users. Only for system admins.
				return false;
			}
			
			//return this.isDevelopment() == false;	//TODO: for dev testings
			return this.isProduction() == false;
		},



		isDevelopment()
		{
			return this.$root.isDevelopment();
		},

		isProduction()
		{
			return this.$root.isProduction();
		},
	}
}
</script>





<style lang="scss" scoped>
	/*--------------------------------------------------------------------------------*/
	/*                                                                                */
	/*          Environment name banner in everything except proudction               */
	/*                                                                                */
	/*--------------------------------------------------------------------------------*/
	.environment-banner {
		/*    background-color: sandybrown;*/
		/*    background-color: papayawhip;*/
		background-color: lightskyblue;


		&.production
		{
			background-color: orangered;
			font-size: 200%;
		}

		// //TODO: for dev testings
		// &.development
		// {
		// 	background-color: orangered;
		// 	font-size: 200%;
		// }
	}





	body > .container.environment-banner {
		padding-top: 0px;
	}


	.environment-banner-inner {
		padding: 2px;
		font-weight: bold;
	}


	.environment-banner-link,
	.environment-banner-link:visited
	{
		font-weight: normal;
		font-style: italic;
		color: black;
		
	}
	.environment-banner-link:hover {
		text-decoration: underline;
	}



	// Override to place it in the top-left
	.environment-banner
	{
		position: fixed;
		top: 0;
		left: 0;

		width: auto;
		max-width: 200px;
		
	}
</style>
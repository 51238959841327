<template>
	<div class="settings-container">
		<h2>Settings</h2>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},



	methods: {
        onLoginClick()
        {

        },
	}
}
</script>

<style lang="scss" scoped>
@import './mobile-app.scss'
</style>
<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div>
		<!-- TODO: Implement -->
	</div>
</template>





<script>
export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},



		currentPageNumber: {
			type: Number
		},

		totalPages: {
			type: Number
		},

		pageSize: {
			type: Number
		},

		totalResults: {
			type: Number
		}
	},





	data()
	{
		return {

		};
	},



	// watch: {
	// 	currentPageNumber: function (to, from) {
	//
	// 	}
	// },






	mounted()
	{
		this.init();
	},





	computed: {
		
	},





	methods: {
		init()
		{
			
		}
	}
}
</script>





<style scoped lang="scss">
	.scroll-container
	{
		width: 100%;
		overflow-x: auto; 
		white-space: nowrap;
		
	}

	.page-number
	{
		padding-right: 4px;
		cursor: pointer;
		color: blue;
	}


	.is-selected
	{
		cursor: default;
		color: inherit;	
		font-weight: bold;
	}


	.sub-text
	{
		font-size: 75%;
	}
</style>
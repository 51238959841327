<template>
	<span>
		<!-- <span :id="'a' + id" class="scroll-arrow previous" v-if="direction == 'previous'" v-on:click="onClick"><i class="font-awesome-icon fas fa-caret-left"></i></span> -->
		<!-- <span :id="'a' + id" class="scroll-arrow next" v-if="direction == 'next'" v-on:click="onClick"><i class="font-awesome-icon fas fa-caret-right"></i></span> -->

		<a href="javascript:void(0)" :id="id" class="arrow ml-2 scroll-arrow previous" v-if="direction == 'previous'" v-on:click="onClick">
			<img :src="arrowLeftImagePath" height="12" />
		</a>
		<a href="javascript:void(0)" :id="id" class="arrow scroll-arrow next" v-if="direction == 'next'" v-on:click="onClick">
			<img :src="arrowRightImagePath" height="12" />
		</a>
	</span>
</template>





<script>
export default {

	emits: ["click"],

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},


		/**
		 * Which direction to indicate.  
		 * Values: "previous" or "next"
		 */
		direction: {
			type: String,
			required: true
		},
	},





	data()
	{
		return {
			arrowLeftImagePath: "https://assets.lotusai.co/portal/images/arrow-left-dark.svg",
			arrowRightImagePath: "https://assets.lotusai.co/portal/images/arrow-right-dark.svg",
		};
	},






	mounted()
	{
		
	},





	computed: {

	},





	methods: {
		onClick()
		{
			var _this = this;
			this.$emit('click', {
				id: _this.id == undefined ? "" : _this.id,
				direction: _this.direction
			});
		}
	}
}
</script>





<style scoped lang="scss">
	.scroll-arrow
	{
		// display: inline-block;
		// background-color: #cccccc;
		// // padding: 7px;
		// padding-left: 8px;


		// width: 30px;
		// height: 30px;
		// border-radius: 15px;
		
		cursor: pointer;
	}


	// Ref: https://stackoverflow.com/questions/21905710/make-font-awesome-icons-in-a-circle
	//i.font-awesome-icon 
	// .scroll-arrow
	// {
	// 	display: inline-block;
	// 	border-radius: 60px;
	// 	box-shadow: 0px 0px 2px #888;
	// 	padding: 0.5em 0.6em;
	// }



	// Ref: https://stackoverflow.com/questions/41532908/make-font-awesome-icons-in-a-circle-border-with-right-11-ratio
	.font-awesome-icon
	{  
		font-size: 1.2em;
		border-radius: 50%;
		border: solid #cccccc 1px;
		// color: white;
		line-height: 2em;
		width: 1em;
		height: 1em;
		text-align: center;
		display: inline-block;
		transition:0.5s;
	}



	.arrow{
		background: #f4f5f5;
		border-radius: 25px;
		height: 30px;
		width: 30px;
		display: inline-block;
		text-align: center;
	}
	.arrow:hover{
		background:#f9f9f9;
	}
	.arrow img{
		margin-top: 3px;
		display: inline-block;
	}


</style>
<template>
	<span class="icon-with-text">
		<c-lotus-icon v-if="1 == 2"></c-lotus-icon>

		<img :src="imagePath" class="d-inline-block mr-2" width="20" v-if="imagePath != ''" />
		<span class="font-awesome-icon mr-2" v-if="showFontAwesomeIcon == true">
			<i :class="[fontAwesomeIconClasses]"></i>
		</span>

		<span :class="['text', getIsBoldText == true ? 'is-bold' : '' ]">
			<slot></slot>
		</span>
	</span>
</template>





<script>
export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},


		fontAwesomeIconClasses: {
			type: String
			//required: true
		},


		/**
		 * 
		 */
		isBoldText: {
			type: Boolean,
			required: false,
			default: true
		},



		imagePath: {
			type: String,
			default: ""
		}
	},





	data()
	{
		return {
			showFontAwesomeIcon: false
		};
	},






	mounted()
	{
		// Determine if Font Awesome icon should be used.
		this.showFontAwesomeIcon = false;
		if (this.fontAwesomeIconClasses != "") this.showFontAwesomeIcon = true;
	},






	computed: {
		/**
		 * Get the `boldText` value. If not value then default to `true`.
		 */
		getIsBoldText() {
			//return this.isBoldText ? this.isBoldText : true;
			return this.isBoldText;
		}
	},





	methods: {

	}
}
</script>





<style scoped lang="scss">
@import "/assets/sass/_global.scss";

.icon-with-text
{
	.font-awesome-icon
	{
		color: $primary-yellow;
	}
	.text
	{
		color: inherit;
		// padding-left: 8px;
	}

	.is-bold
	{
		font-weight: bold;
	}
}
</style>
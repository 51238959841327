<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="row align-items-start p-3 width-full">
		<div class="col-7">
			<div>
				<router-link :to="{name: backRouteName}" v-if="backRouteName">
					<em class="back_arrow" ></em>
				</router-link>

				<span class="breakcumbs">
					{{ moduleName }} 
					<span class="clr4" v-if="backPageName"> / {{ backPageName }}</span>
				</span>
			</div>
			<div class="mt-4">
				<span class="pageTitle" >{{ currentPageName }}</span>
			</div>
		</div>

		
		<div class="col-5 text-right" v-if="showUserDetails">
			<div>
				<span class="userName">{{ userDetailsLine1 }}</span>
			</div>
			<div>
				<span class="useSubName">{{ userDetailsLine2 }}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import globalStore from '/globalStore';

    export default {
		props : {
			/**
			 * The name of the module the current displayed page is in.
			 */
			moduleName: {
				type: String,
				default: ''
			},

			/**
			 * The name of the previous (return) page
			 */
			backPageName: {
				type: String,
				default: ''
			},

			/**
			 * The name of the current page being displayed.
			 */
			currentPageName: {
				type: String,
				default: ''
			},

			showUserDetails: {
				type: Boolean,
				default : false
			},

			backRouteName: {
				type: String,
				default : ''
			}
		},

		computed : {
			currentRouterName() {
				return this.$route.name
			}
		},

		data() {
			return {
				userDetailsLine1: "",
				userDetailsLine2: ""
			}
		},


		mounted()
		{
			this.init();
		},



		methods: {
			init()
			{
				this.userDetailsLine1 = "Welcome " + globalStore.state.currentUser.firstName;
				this.userDetailsLine2 = globalStore.state.currentUser.activeClientDisplayName;  //TODO: Or, in the future, maybe the active account display name
			}
		}
    }
</script>

<style scoped>
.breakcumbs{
	color: #737373;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
}
.clr4 {
	color: var(--default-color, #33394A);
}
.pageTitle{
	color: var(--default-color, #33394A);
	font-size: 34px;
	font-style: normal;
	font-weight: 600;
	line-height: 42px; /* 123.529% */
}

.userName{
	color: var(--default-color, #33394A);
	text-align: right;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.096px;
}
.userSubName{
	color: var(--black, #171717);
	text-align: right;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.width-full {
	width: 100%;
}

.back_arrow {
	cursor: pointer;
	width: 20px;
    margin-right: 10px;
    height: 20px;
    display: inline-flex;
	background: url('https://assets.lotusai.co/portal/icons/back_arrow.svg') no-repeat;
}
</style>
<template>
	<span class="c-button-space"></span>
</template>




<script>
export default {

}
</script>




<style lang="scss" scoped>
.c-button-space
{
	padding-right: 4px;
}
</style>
<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="row">
		<div class="col-12 col-md-6 text-center text-md-left">
			<p class="footer mb-0" style="padding-top: 10px">
				<a href="/support" style="color: rgba(67, 60, 171, 1)">Request Support</a>
			</p>	
		</div>
		<div class="col-12 col-md-6 text-center text-md-right">
			<p class="footer mb-0">
				<span style="font-size: 85%">Powered by &nbsp;</span>
				<img src="https://assets.lotusai.co/portal/images/logo-dark-ai-yellow.svg" alt="">
			</p>	
		</div>
	</div>
</template>



<script>

export default {
	// name: "LayoutFooter",

	

	// data() {
	// 	return {
	// 	};
	// },



	// mounted() 
	// {
	// },




	// methods: {


	// }
}
</script>






<style lang="scss" scoped>
.footer {
    img {
        width: 128px;
        height: auto;
    }
}
</style>
const data = {
	//-- Work Plan
	workPlan: {
		filterDate: "2022-04-19",

		jobs: [
			{
				id: "job1",
				timeFromTo: "9am - 1pm",
				allocatedDuraton: "4 hrs",
				clientName: "ANZ Banking Corporation",
				address: "123 Fake Street, Sydney, 2000",
				jobName: "Sydney branch cleaning",
				status: "Completed",
				statusClass: 'status-completed',
				actualDuration: "(Finished in 4hrs 2min)",
				bucketPath: "Sydney branch cleaning",
				startingIn: "",

				tasks: [
					{
						id: "task1",
						name: "Empty Bins",
						duration: "1h",
						status: "Comp",

						commentary: [
							{ details: "task 1 commentary 1" },
							{ details: "task 1 commentary 2" },
							{ details: "task 1 commentary 3" }
						]
					},
					{
						id: "task2",
						name: "Wipe desks",
						duration: "1h",
						status: "Comp",
						commentary: []
					},
					{
						id: "task3",
						name: "Fill mugs",
						duration: "1h 30m",
						status: "Comp",
						commentary: []
					}
				],

				commentary: [
					{ details: "job 1 commentary 1" },
					{ details: "job 1 commentary 2" },
					{ details: "job 1 commentary 3" }
				]
			},

			{
				id: "job2",
				timeFromTo: "1:15pm - 4:45pm",
				allocatedDuraton: "3 hrs 30 mins",
				clientName: "Westpac Banking Group",
				address: "403/100 Swanston Street, Melbourne, Vic, 3000",
				jobName: "Head office cleaning",
				status: "In Progress",
				statusClass: 'status-in-progress',
				actualDuration: "(1hr 15min since check in)",
				bucketPath: "Head office cleaning",
				startingIn: "3 hours 40 minutes (1:15pm)",
				totalTimeOnBucketToday: "2 hours 30 minutes",

				tasks: [
					{
						id: "task4",
						name: "Empty Bins",
						duration: "1hr",
						status: "Comp",
						statusFull: "Completed",
						timeRecorded: "47 minutes",

						commentary: [
							{ details: "task 4 commentary 1" },
							{ details: "task 4 commentary 2" }
						]
					},
					{
						id: "task5",
						name: "Wipe desks",
						duration: "1hr",
						status: "In Prog",
						statusFull: "In Progress",
						timeRecorded: "1 hour 10 minutes",

						commentary: [
							{ details: "task 5 commentary 1" },
							{ details: "task 5 commentary 2" }
						]
					},
					{
						id: "task6",
						name: "Fill mugs",
						duration: "1hr 30m",
						status: "NS",
						statusFull: "Not Started",
						timeRecorded: "2 minutes",

						commentary: [
							{ details: "task 6 commentary 1" }
						]
					},
					{
						id: "task60",
						name: "Stack Sue's boxes on her desk.",
						duration: "10m",
						status: "NS",
						statusFull: "Not Started",
						timeRecorded: "",

						commentary: []
					}
				],

				commentary: [
					{ details: "job 2 commentary 1" },
					{ details: "job 2 commentary 2" }
				]
			},


			{
				id: "job3",
				timeFromTo: "5pm - 8pm",
				allocatedDuraton: "3 hrs",
				clientName: "CommBank",
				address: "123 Sussex St, Sydney, 2001",
				jobName: "Head office cleaning",
				status: "Not Started",
				statusClass: 'status-not-started',
				actualDuration: "",
				bucketPath: "Head office cleaning",
				startingIn: "",

				tasks: [
					{
						id: "task7",
						name: "Empty Bins",
						duration: "1hr",
						status: "NS",
						commentary: []
					},
					{
						id: "task8",
						name: "Wipe desks",
						duration: "1hr",
						status: "NS",
						commentary: []
					},
					{
						id: "task9",
						name: "Fill mugs",
						duration: "1hr 30m",
						status: "NS",
						commentary: []
					}
				],

				commentary: []
			},


			{
				id: "job4",
				timeFromTo: "9pm - 9:30pm",
				allocatedDuraton: "30 mins",
				clientName: "Dodgy Brothers Cash Cleaning",
				address: "Dark ally behind Westpac, Sydney, 2000",
				jobName: "Cleaning supplies pick-up",
				status: "Not Started",
				statusClass: 'status-not-started',
				actualDuration: "",
				bucketPath: "Cleaning supplies pick-up",
				startingIn: "",

				tasks: [
					{
						id: "task10",
						name: "'Purchase' supplies",
						duration: "30 mins",
						status: "NS",

						commentary: []
					},
				],

				commentary: []
			},



			{
				id: "job5",
				timeFromTo: "10pm - 11pm",
				allocatedDuraton: "1hr",
				clientName: "Internal",
				address: "Home Base",
				jobName: "Debrief",
				status: "Not Started",
				statusClass: 'status-not-started',
				actualDuration: "",
				bucketPath: "Debrief",
				startingIn: "",

				tasks: [
				],

				commentary: []
			},

		]
	},
};


export default data;
var allFormElementPropsMixin = {
	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: true
		},

		/**
		 * The field's `name` value. 
		 * If not defined, `id` is used.
		 */
		name: {
			type: String
		},

		/**
		 * The element's `title` value. 
		 */
		title: {
			type: String,
			default: ""
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},


		/**
		 * Inline CSS styles to apply
		 */
		cssStyle: {
			type: String
		},

		
		/**
		 * A CSS string representing the width to set this field to
		 */
		width: {
			type: String,
			default: "auto"
		},


		/**
		 * Passing the placeholder through to the element
		 */
		placeholder: {
			type: String,
			default: "",
		},

		
		//TODO: See nativeType
		// isSubmit: {
		// 	type: Boolean,
		// 	required: false,
		// 	default: false
		// },


		disabled: {
			type: Boolean,
			required: false,
			default: false
		},




		/**
		 * The rendered size of the button.  
		 * Valid values are: "standard" (default), "large", "small", "mini" 
		 */
		size: {
			type: String,
			default: "standard"
		},




		
		isValid: {
			type: Boolean,
			default: true
		},


		validationMessage: {
			type: String,
			default: ""
		}
		
	},





	data()
	{
		return {
			/**
			 * Set to true when we're setting the width on the container element rather than the actual input.
			 */
			widthOnContainer: false
		}
	},







	computed: {
		getName() {
			return this.name ? this.name : this.id;
		},



		getCssStyle()
		{
			// console.log("getCssStyle(): this.cssStyle=", this.cssStyle);

			if (!this.cssStyle)
			{
				// console.log("   getCssStyle(): 000");
				return "";
			}

			// Set width to 100% by default, unless an alternate value is passed in.
			if (this.cssStyle && this.cssStyle.toLowerCase().includes("width:"))
			{
				// console.log("   getCssStyle(): 1111");
				// Explicitly defining the width, so don't use the "width" property.
				return this.cssStyle;
			}
			else
			{
				// console.log("   getCssStyle(): 222");
				// No width provided, or, width is being applied to the container rather than the actual input.
				// So not setting the width on the input style (see getContainerCssStyle() instead)
				if (!this.width || this.widthOnContainer) this.cssStyle;

				return "width: " + this.width + "; " + this.cssStyle;
			}
		},
		

		getContainerCssStyle()
		{
			if (this.widthOnContainer)
			{
				return "width: " + this.width + "; " + this.cssStyle;
			}

			// No style to set on the container.	
			return "";
		}

	},






	methods: {

	}

};

export default allFormElementPropsMixin;
<!-- This is a wrapper around an ELement UI select component that calls the Backend for a list of countries and allows the user to select one. -->

<!-- USAGE NOTES: -->

<!-- THIS IS FULLY CONTROLLED COMPONENT: IT WILL NOT WORK WITHOUT v-model OR HANDLING'input' EVENT. -->

<!-- The schema for the v-model value will be an object with this shape 

    {
        ID: String,                 // IDs as strings in the form of integers starting at "1", "-1" for custom inputs.
        ISO366x1Alpha2Code: String, // the ISO recognised two-letter code for the country e.g. "AU" for australia
        "CountryName": String       // The name of the country, custom text input will also be presented here.
        "Status": String            // active, etc
    }
-->

<!-- The ISO366x1Alpha2Code and Status fields will not be emitted for custom inputs. -->
<!-- If you are loading an entity to be edited and you already have a ID in mind, the component should be able to display the correct country using just the ID. -->
<!-- If you are loading the component with an existing custom input you'll have to pass in both an ID: -1 and the custom input text in CountryName -->

<!-- Some notes on implementation -->
<!-- Since our value prop is a complex object, we will use computed values to delegate the incoming data depending on our business logic: -->
<!-- If the incoming ID is -1 for example, then we will direct the incoming CountryName to the value of the text input. -->
<!-- We want our value prop to be the single source of truth for the whole component. -->

<!-- We also want to be able to emit 'input' events up to the parent so that v-model can be simulated properly by vue. -->
<!-- To do this, we will use separate onInput event handlers that will emit the proper values. -->

<!-- Some notes on isFailed and isLoading flow: -->
<!-- isLoading ? display the loading skeleton instead of the el-select -->
<!-- isNotLoading but isFailed ? display the el-select but disabled and with 'Could not load countries.' as placeholder text. -->
<!-- isNotLoading and isNotFailed ? Display the el-select as normal. -->

<template>
    <div v-if="isLoading == false" class="_OBSOLETE__d-flex">
        <el-select
            :id="id"
            filterable
            default-first-option
            clearable
            :placeholder="
                isFailed ? 'Could not load countries.' : 'Select Country'
            "
            :multiple="false"
            :disabled="isFailed == true"
			v-model="selectValue"
            @change="onSelectChange"
            class="_OBSOLETE__flex-basis-30 _OBSOLETE__clear-button-big-red"
			style="width: 250px"
        >
            <el-option
                v-for="option in options"
                :key="id + '-' + option.value"
                :label="option.text"
                :value="option.value"
            >
            </el-option>
        </el-select>

		<br/>
        <el-input
            placeholder="Other: please input."
            width="auto"
            clearable
            v-model="textValue"
            :disabled="isTextDisabled"
			v-if="!isTextDisabled"
            @input="onTextChange"
            class="_OBSOLETE__clear-button-big-red"
			style="width: 250px"
        >
        </el-input>
    </div>

    <!-- If loading, show the skeleton animation. -->

    <div
        class="skeleton-box input-sized-box"
        v-else-if="isLoading == true && isFailed == false"
    ></div>
</template>


<script>
export default {
    name: "cSelectCountries",

	emits: ["input", "update:modelValue"],

    props: {
        id: {
            type: String,
            required: true,
        },


        // value: {
        //     type: [Object, null],
        //     required: true,
        // },



		//TODO: New with Vue 3
        modelValue: {
            type: [String, null],
            default: null,
            required: true
        },

    },

    data() {
        return {
            isLoading: true,
            isFailed: false,

			value: null,
			textValue: ""
        };
    },

    mounted() {
        this.init();
    },

    // We will use a bunch of derived state off the incoming value object to control the component.
    // Some of this is necessary because it is not easy to handle null values as value in the template itself.
    // When this.value is null, we assume a blank slate, and input should be null and not disabled.
    computed: {
        // The value of the dropdown should match value.ID except when -1
        selectValue() {
            if (this.value == null)
			{
                return null;
            }

            if (this.value.ID != "-1")
			{
                return this.value.ID;
            }

            return null;
        },

        // // The value of the text input should match value.CountryName when value.ID is -1
        // textValue() {
        //     if (this.value == null)
		// 	{
        //         return null;
        //     }

        //     if (this.value.ID == "-1")
		// 	{
        //         return this.value.CountryName;
        //     }

        //     return null;
        // },

        // The select should be disabled when the value is non-null and value.ID -1
        isSelectDisabled() {
            if (this.value == null)
			{
                return false;
            }

            if (this.value.ID == "-1")
			{
                return true;
            }

            return false;
        },

        // the text input should be disabled when the value is non null and value.ID is > 0
        isTextDisabled() {
			if (!this.value)
			{
				// No value provided.
				return true;
			}

			console.log("isTextDisabled(): this.value.ID=", this.value.ID);
			if (this.value.ID == "-1")
			{
                return false;
            }

			return true;


            // if (this.value == null)
			// {
            //     return false;
            // }

            // if (this.value.ID != "-1")
			// {
            //     return true;
            // }

            // return false;
        },
    },

    methods: {
        /**
         * Component init makes an api call to the Lotus backend to retrieve an up-to-date list of countries.
         */
        init() {
            // make api call
            this.$root
                .callApi("/Countries/GetAllCountries", {}, "GET", {
                    failed: true,
                })
                .then((data) => {
                    if (data.failed === true) {
                        // Check if call succeeded,
                        // if api call fails, then set state to show a failed call.
                        this.isLoading = false;
                        this.isFailed = true;
                        return;
                    }

                    // continuing on if call has succeeded.

                    // upon receiving the data, push the data to the component state.
                    this.Countries = data.Countries;

                    // also convert the data and push into the options
                    this.options = data.Countries.map((country) => {
                        return {
                            value: country.ID,
                            text: country.CountryName,
                        };
                    });

                    // Also add an empty to the top to allow the user to easily unselect (hopefully)
                    this.options.unshift({
                        value: "-1",
                        text: "Other",
                    });

                    // finally set isLoading to false to show the actual input
                    this.isLoading = false;
                });
        },

        /**
         * The onSelectChange event handler will take any select inputs and emit an 'input' event to be handled by its parents.
         * This function will assume that since the inputs would be appropriately disabled, any calls to itself must be valid.
         *
         * NOTE: the data parameter is the value of the input control automatically passed into the function by elementui
         */
        onSelectChange(data) {
            // find the corresponding country in this.Countries and emit as the payload.

			console.log("onSelectChange(): data=", data);
			console.log("   onSelectChange(): data=", this.Countries);
			
			let selectedCountry = {};
			if (data == "-1")
			{
				// "Other" is selected
				selectedCountry = {
					ID: "-1",
					ISO366x1Alpha2Code: "",
					CountryName: "",
					Status: "Active"
				};
			}
			else
			{
				selectedCountry = this.Countries.find(
					(item) => item.ID == data
				);
			}

			this.value = selectedCountry;

			console.log("   onSelectChange(): selectedCountry=", selectedCountry);

            this.$emit("input", selectedCountry);
			this.$emit('update:modelValue', selectedCountry);
        },

        /**
         * onTextChange event handler will emit 'input' events with the ID of "-1".
         *
         * If the value of the input is null or empty, emit a null object to reset the component.
         */
        onTextChange(data) {
            // // If data is null or empty, emit null
            // if (data == null || data === "") 
			// {
            //     this.$emit("input", null);
			// 	this.$emit('update:modelValue', null);
            //     return;
            // }

			this.value.CountryName = data;
			console.log("onTextChange(): data=", data, this.value);
			console.log("   onTextChange(): textValue=", this.textValue);

            this.$emit("input", this.value);
			this.$emit('update:modelValue', this.value);
        },
    },
};
</script>

<style scoped lang="scss">
/* skeleton box animation from Markus Oberlehner */
/* https://markus.oberlehner.net/blog/skeleton-loading-animation-with-vue/ */

.skeleton-box {
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    background-color: #dddbdd;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
        );
        animation: shimmer 5s infinite;
        content: "";
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}

.input-sized-box {
    width: 100%;
    height: 2.25rem;

    border-radius: 6px;
}

.flex-basis-30 {
    flex-basis: 30%;
}

// .clear-button-big-red::v-deep .el-input__clear {
//     color: red;
//     transform: scale(1.25);
// }

// .clear-button-big-red::v-deep
//     .el-input
//     .el-select__caret.el-input__icon.el-icon-circle-close {
//     color: red;
//     transform: scale(1.25);
// }
</style>

<template>
	<!-- No drop-down -->
	<el-button 
		:id="id" :name="getName"
		value="initialValue" 
		:class="['c-button-secondary', cssClass]" 
		:style="getCssStyle"
		:type="displayType"
		:plain="isPlain"
		@click="onClick" 
		v-if="isDropDown == false" 
		:disabled="disabled ? true : false"
		:native-type="nativeType"
		:size="getSize"
	>
		<span class="icon" v-if="$slots.icon"><slot name="icon"></slot></span>
		<span class="text"><slot name="text"></slot></span>
	</el-button>
</template>





<script>
import buttonPropsMixin from "./button-props-mixin";
import allFormElementPropsMixin from "../forms/all-form-element-props-mixin";

export default {

	mixins: [allFormElementPropsMixin, buttonPropsMixin],


	mounted()
	{
		this.displayType = "info";
	},

}
</script>





<style scoped lang="scss">
	// .c-button-secondary-container
	// {
	// 	display: inline-block;
	// }
</style>
<template>
	<div class="c-page-sub-title">
		<el-card class="box-card page-sub-title-v1" style="width: 100%" shadow="never" v-if="version == 1">
			<h2 class="page-sub-title">
				<slot></slot>
			</h2>
		</el-card>

		<h2 class="page-sub-title page-sub-title-v2" v-if="version == 2">
			<slot></slot>
		</h2>
	</div>
</template>





<script>
export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},


		/**
		 * What version of this componet. Controls styling.
		 */
		version: {
			type: Number,
			required: false,
			default: 1
		},

		// text: {
		// 	type: String,
		// 	required: true
		// },
	},





	data()
	{
		return {
			
		};
	},






	mounted()
	{
		
	},





	computed: {

	},





	methods: {

	}
}
</script>





<style scoped lang="scss">
@import "/assets/sass/_global.scss";

.page-sub-title
{
	// font-family: $font-bold;
	// // color: $primary-yellow;
	
	// // color: #111111;
	// color: navy;

	// // font-size: 28pt;
	// // font-size: 1.75em;
	// font-size: 1.5em;
	// margin-bottom: 0.5rem;
    // font-weight: 500;
    // line-height: 1.2;



	font-family: $font-bold;

	font-size: 16px;
	margin-bottom: 0;
	font-weight: bold;

	font-family: CircularStd, Montserrat, sans-serif;
}

.page-sub-title-v1
{
	color: #303133;
}

.page-sub-title-v2
{
	//color: $primary-yellow;
	color: whitesmoke;
}
</style>

<style lang="scss">
	.c-page-sub-title
	{
		.el-card {
			border: 0;
			// // border-bottom: 3px solid #EBEEF5;
			// border-bottom: 2px dotted #87888a;

			background-color: #FFF;
			color: #303133;
			transition: .3s;
		}


		.el-card__body
		{
			padding-left: 0;
		}

	}
</style>
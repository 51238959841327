<template>

	<div class="work-plan-container">
		<h2>Your Plan</h2>

		<div class="text-right mb-3">
			<c-input-date id="filter-date" name="" :initial-value="workPlan.filterDate" v-model="workPlan.filterDate"></c-input-date>
		</div>


		<div v-for="job in workPlan.jobs" :key="'job-' +job.id">
			<h3 v-if="job.id == 'job1-nope'">Previous</h3>
			<h3 v-if="job.id == 'job2'">Current</h3>
			<h3 v-if="job.id == 'job3'">Upcoming</h3>
			<div :class="['card', 'mb-3', job.statusClass]">
				<div class="card-body">
					<div class="row">
						<div class="col-8">
							<div style="font-weight: bold; font-size: 120%">{{job.timeFromTo}}</div>
							<div style="font-weight: bold">{{job.clientName}}</div>
							<div>{{job.jobName}}</div>
							<!-- <div class="mt-3">{{job.address}}</div> -->
						</div>
						<div class="col-4 text-right">
							<div>{{job.allocatedDuration}}</div>
							<!-- <div>{{job.tasks.length}} tasks</div> -->
							<div>{{job.status}}</div>
							
							<div v-if="job.commentary.length > 0">
								<span class="commentary-container">
									{{job.commentary.length}} <i class="fas fa-comments"></i>
								</span>
							</div>
						</div>
					</div>

					<div class="row mt-3">
						<div class="col-12" style="font-size: 100%">
							<div>{{job.address}}</div>
						</div>
					</div>

					<div class="row mt-3">
						<div class="col-3 text-left" style="font-size: 90%">
							<div>
								{{job.tasks.length}} <i class="fas fa-tasks"></i>
							</div>
						</div>
						<div class="col-9 text-right" style="font-size: 90%">
							<div>
								{{job.actualDuration}}
							</div>
						</div>
					</div>
				</div>
				<div style="width: 100%; background-color: #fafafa; font-size: 90%" v-if="job.status == 'In Progress'">
					<div class="card-body tasks-container" v-if="job.tasks.length > 0">
						<div class="text-left mb-1" style="font-weight: bold;">
							<div>{{job.tasks.length}} tasks</div>
						</div>
						<div>
							<div class="z-row mb-1 task-row" v-for="task in job.tasks" :key="'task' + task.id">
								<div class="z-col-5 task-name-col">
									<div class="task-name-text">{{task.name}}</div>
								</div>
								<div class="z-col-2 task-duration-col">
									{{task.duration}}
								</div>
								<div class="z-col-3 text-right task-status-col">
									{{task.status}}
								</div>
								<div class="z-col-2 text-right task-commentary-col">
									<div v-if="task.commentary.length > 0">
										<span class="commentary-container">
											{{task.commentary.length}} <i class="fas fa-comments"></i>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>


		

	</div>
</template>

<script>
import mobileData from './mobile-data.js';

export default {
	data() {
		return {

			workPlan: mobileData.workPlan
		}
	},



	methods: {

	}
}
</script>

<style lang="scss" scoped>
@import './mobile-app.scss'
</style>
<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div :id="id" class="row">
		<slot></slot>
	</div>
</template>





<script>
export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},

	},





	data()
	{
		return {

		};
	},



	// watch: {
	// 	currentPageNumber: function (to, from) {
	//
	// 	}
	// },






	mounted()
	{
		
	},





	computed: {
		
	},





	methods: {

	}
}
</script>





<style scoped lang="scss">
	.row
	{
		// Override the -15px styles coming from somewhere.
		// margin-left: -30px;
		// margin-right: -30px;

		// width: 100%;
	}
</style>
var layoutMixin = {





	data()
	{
		return {};
	},







	computed: {
		

	},






	methods: {


		init()
		{
			//console.log("SystemAdminLayout.mounted()");

			this.pageTitle = this.$route.meta.pageTitle;

			var section = this.$route.meta.section;
			this.section = (section ? section.toLowerCase() : "");

			//console.log("Primary Layout: section=", this.section);

			if (this.section.toLowerCase() == "system-admin")
			{
				this.sectionLayoutClass = "layout-system-admin"
			}
			
			// Check the user is [still] logged in.
			this.$root.checkCurrentAuthTokenStillValid();


			//this.authoriseAccess();

		},

		/**
		 * Authorise user access to this page(s).
		 */
		authoriseAccess()
		{
			if (this.$root.isSystemAdminUser() == false)
			{
				// Not logged in. Direct to the login page.
				this.$root.redirectToLogin(true);
				// console.log("SystemAdmin: No access");
				return;
			}
			
			// console.log("SystemAdmin: Has access");
		},

	}

};

export default layoutMixin;
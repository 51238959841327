<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<img :id="id" :class="['c-img', cssClass]" :src="src" :atl="alt" />
</template>





<script>
export default {

	emits: ["click"],
	
	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: true
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},

		/**
		 * URL to the image to display
		 */
		src: {
			type: String
		},

		
		/**
		 * 'Alt' text to display for screen readers and accessibility.
		 */
		alt: {
			type: String,
			required: true
		},
	},




	data()
	{
		return {

		};
	},





	mounted()
	{
		if (this.alt == "")
		{
			console.warn("No alt text supplied for <img id='" + this.id + "'>");
		}
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {
		onClick()
		{
			this.$emit('click', {});
		}
	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";
</style>
<template>
	<span :class="['health-light', getColorClass, getSizeClass]"></span>
</template>





<script>

export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},


		/**
		 * The health status to show.   
		 *    'healthy',	'warning',	'danger'.  
		 * OR 'green',		'amber',	'red'
		 */
		status: {
			type: String,
			required: true
		},



		size: {
			type: String,
			required: false,
			default: ''
		}
	},





	data()
	{
		return {

		};
	},






	mounted()
	{
		
	},





	computed: {
		getColorClass()
		{
			if (this.status == undefined) return '';

			if (this.status == 'healthy' || this.status == 'green') return 'green';
			if (this.status == 'warning' || this.status == 'amber') return 'amber';
			if (this.status == 'danger' || this.status == 'red') return 'red';

			return '';
		},

		getSizeClass()
		{
			if (this.size == undefined) return 'size-nornmal';

			if (this.size == 'small') return 'size-small';
			if (this.size == 'normal') return 'size-nornmal';
			if (this.size == 'large') return 'size-large';

			return 'size-nornmal';
		}
	},





	methods: {

	}
}
</script>





<style scoped lang="scss">
	.health-light
	{
		background-color: #bbb;
		border-radius: 50%;
		display: inline-block;
	}


	.size-small
	{
		height: 20px;
		width: 20px;
	}
	.size-nornmal
	{
		height: 25px;
		width: 25px;
	}
	.size-large
	{
		height: 50px;
		width: 50px;
	}


	.green
	{
		background-color: green;
	}

	.amber
	{
		background-color:gold;
	}

	.red
	{
		background-color: red;
	}

</style>
<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div :id="id" class="row input-row">
		<div :class="['col-' + labelCols, 'label-column']">
			<slot name="label"></slot>
			<c-required-field v-if="isRequired"></c-required-field>
		</div>
		<div :class="['col-' + inputCols, 'input-column']">
			<slot name="input"></slot>
			<c-field-validation-result :message="validationMessage" v-if="validationMessage"></c-field-validation-result>
			<c-info-text size="small">
				<slot name="infoText"></slot>
			</c-info-text>
		</div>
	</div>
</template>





<script>
export default {

	props: {
		
		/**
		 * The field's `id` value. Required.  
		 * Should be unique among all elements currently rendered on the page.
		 */
		id: {
			type: String,
			required: false
		},

		/**
		 * Additional CSS class(s) to apply.  
		 * Does NOT overwrite default classes.
		 */
		cssClass: {
			type: String
		},

		
		/**
		 * The number of columns in width for the "label", out of 12 possible columns in a row
		 */
		labelCols: {
			type: Number,
			required: false,
			default: 3
		},
		
		
		/**
		 * The number of columns in width for the "input", out of 12 possible columns in a row
		 */
		inputCols: {
			type: Number,
			required: false,
			default: 9
		},


		isRequired: {
			type: Boolean,
			required: false,
			default: false
		},



		validationMessage: {
			type: String,
			required: false,
			default: ""
		}

	},





	data()
	{
		return {

		};
	},



	// watch: {
	// 	currentPageNumber: function (to, from) {
	//
	// 	}
	// },






	mounted()
	{
		
	},





	computed: {
		
	},





	methods: {

	}
}
</script>





<style scoped lang="scss">
	//-- Not needed when nested under a <c-row>
	// .label-column
	// {
	// 	padding-left: 0;
	// }

	.input-row
	{
		padding-bottom: 10px;
	}
</style>
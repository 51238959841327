<template>
	<span>
		<input type="checkbox" 
			:id="id" 
			:name="getName" 
			:disabled="disabled"
			:class="['c-input-checkbox', 'c-input-checkbox-native-style', cssClass, isValid == false ? 'is-invalid' : '']" 
			:checked="modelValue"
			@input="onInput"
		>
			<!-- @change="onChange"
			@blur="onBlur" -->

		<!-- //TODO: Remove. Obsolete -->
		<el-checkbox 
			:id="id" :name="getName" 
			:class="['c-input-checkbox', cssClass, isValid == false ? 'is-invalid' : '']" 
			v-model="value"
			@change="onChangeEl"
			v-if="1 == 2 && type == 'element'"
		></el-checkbox>

		<span class="validation-text" v-if="isValid == false && validationMessage != ''">
			<small style="color: red" v-html="validationMessage"></small>
		</span>
	</span>
</template>





<script>
import allFormElementPropsMixin from "./all-form-element-props-mixin";

export default {

	mixins: [allFormElementPropsMixin],

	emits: ["change", "update:modelValue"],

	props: {
		
		// initialValue: {
		// 	type: Boolean
		// },


		// /**
		//  * Set this from the parent page/component when wanting to 
		//  * programatically update the selected value.
		//  */
		// updateValue: {
		// 	type: Boolean
		// },


		//TODO: New with Vue 3
        modelValue: {
            type: Boolean,
            default: false,
            required: true
        },



		// type: {
		// 	type: String,
		// 	default: "native"
		// },

	},





	data()
	{
		return {
			//value: false
		};
	},






	mounted()
	{
		//this.value = this.initialValue;
		this.value = this.modelValue;
	},


	watch: {
		initialValue: function(to, from)
		{
			this.value = this.initialValue;
		},


		updateValue: function(to, from)
		{
			//console.log("input-checkbox.watch(updateValue): from=", from, "to=", to); 

			this.value = this.updateValue;
		}
	},





	computed: {
		getName() {
			return this.name ? this.name : this.id;
		}
	},





	methods: {

		onInput(event)
		{
			const value = event.target.checked;

			console.log("c-input-checkbox.onInput(): event=", event, " | value=", value);

			this.$emit('change', value);

			this.$emit('update:modelValue', value);
		},







		//TODO: Obsolete
		// onChangeEl(value)
		// {
		// 	console.log("onChangeEl(): value=", value);
		// 	console.log("onChangeEl(): this.value=", this.value);

		// 	// this.$emit('input', this.value);
		// 	this.$emit('change', value);

		// 	this.$emit('update:modelValue', value);
		// },


	}
}
</script>





<style scoped lang="scss">
	@import "/assets/sass/_global.scss";


	//Source: https://stackoverflow.com/questions/8635317/how-do-change-the-color-of-the-text-of-an-option-within-a-select
	.c-input-checkbox-native-style
	{
		/* Double-sized Checkboxes */
		-ms-transform: scale(1.2); /* IE */
		-moz-transform: scale(1.2); /* FF */
		-webkit-transform: scale(1.2); /* Safari and Chrome */
		-o-transform: scale(1.2); /* Opera */
		transform: scale(1.2);
	}
</style>

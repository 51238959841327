import globalStore from '/globalStore';

var accountAdminMixin = {
	



	data()
	{
		return {
			globalStore: globalStore,

			account: {
				id: "",
				name: "",
				status: "",
				defaultTimezoneID: "",
				defaultTimezoneCurrentOffset: ""
			}
		};
	},







	computed: {


		getCurrentAccountName()
		{

		},

		//TODO: Not used.
		// getCurrentAccountAdminAccountId()
		// {
		// 	if (this.globalStore.state.systemAdminSelectedAccountId != "")
		// 	{
		// 		return this.globalStore.state.systemAdminSelectedAccountId;
		// 	}
		// 	else
		// 	{
		// 		return this.globalStore.state.currentUser.activeAccountId;
		// 	}
		// }


	},






	methods: {


		/**
		 * Calculate and return the account ID to user for the current account-admin page.
		 */
		getCurrentAccountAdminAccountId()
		{
			var accountId = "";

			var routeAccountId = this.$root.getRouteValue("accountId");

			if (routeAccountId != "")
			{
				accountId = routeAccountId;
			}
			else if (this.globalStore.state.systemAdminSelectedAccountId != "")
			{
				accountId = this.globalStore.state.systemAdminSelectedAccountId;
			}
			else
			{
				accountId = this.globalStore.state.currentUser.activeAccountId;
			}

			return accountId;
		},


		
		loadAccount(callback)
		{
			var accountId = this.getCurrentAccountAdminAccountId();


			//TODO: Nope.
			// Set this so we can access it in account-admin-layout and pass a value in to account-admin-navigation.
			//this.globalStore.state.systemAdminSelectedAccountId = accountId;


			
			var requestData = {
				accountId: accountId
			};

			//console.log("account-admin-mixin.loadAccount():", requestData);

			this.$root.callApi("/Accounts/GetAccount", requestData, "GET")
			.then((responseData) => {
				//console.log("account-admin-mixin.loadAccount(): responseData=", responseData);

				if (responseData == null) 
				{
					if (callback) callback(false);
					return; // Not successful
				}

				//_this.account = responseData;
				// _this.$set(_this.account, 'ID', responseData.ID);
				// _this.$set(_this.account, 'Name', responseData.Name);
				// _this.$set(_this.account, 'Status', responseData.Status);

				const account = responseData.Account;

				this.account.id = account.ID;
				this.account.name = account.Name;
				this.account.status = account.Status;
				this.account.defaultTimezoneCurrentOffset = account.DefaultTimezoneUTCOffset;
				this.account.defaultTimezoneID = account.DefaultTimezoneID;
				
				//console.log("~~~~ loadAccount()", callback);

				if (callback) callback(true);
			});
		},

	}

};

export default accountAdminMixin;
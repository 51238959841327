<template>
	<div>
		<form v-on:submit.prevent="createOrUpdateAddress" class="form-horizontal" method="post" role="form" id="address-edit-form">
			
			<div class="form-group row">
				<label for="CityName" class="col-sm-3 col-form-label">Search</label>
				<div class="col-sm-9">
					<c-input-text id="googleMapsAutocomplete" name="googleMapsAutocomplete" :initial-value="addressSearch" v-model="addressSearch" width="100%" placeholder="Search for an address or place"></c-input-text>

					<div id="selected-address-details"></div>
					<p class="c-info-text" style="font-size: small; margin-bottom: 8px;">Search currently supports only Australian addresses. Contact <a href="/support">support</a> for international assistance.</p>
					<a href="javascript:void(0);" @click="showForm = true;" style="font-size: small; font-weight: bold;">Manual address input</a>
				</div>
			</div>


			<div v-if="showForm">

				<hr />

				<div class="form-group row">
					<label for="Name" class="col-sm-3 col-form-label">Name</label>
					<div class="col-sm-9">
						<c-input-text id="Name" name="Name" :initial-value="address.Name" v-model="address.Name" width="100%" placeholder="Line 1"></c-input-text>
						<p class="c-info-text" style="font-size: small">A business name or your reference name for this address.</p>
						<c-field-validation-result :message="validation.nameMessage"></c-field-validation-result>
					</div>
				</div>

				<div class="form-group row">
					<label for="Line1" class="col-sm-3 col-form-label">Street Line 1<c-required-field></c-required-field></label>
					<div class="col-sm-9">
						<c-input-text id="Line1" name="Line1" :initial-value="address.Line1" v-model="address.Line1" width="100%" placeholder="Line 1"></c-input-text>
						<c-input-text id="Line2" name="Line2" :initial-value="address.Line2" v-model="address.Line2" width="100%" placeholder="Line 2"></c-input-text>
						<c-input-text id="Line3" name="Line3" :initial-value="address.Line3" v-model="address.Line3" width="100%" placeholder="Line 3"></c-input-text>
						<c-field-validation-result :message="validation.linesMessage"></c-field-validation-result>
					</div>
				</div>

				<div class="form-group row">
					<label for="CityName" class="col-sm-3 col-form-label">City / Town</label>
					<div class="col-sm-9">
						<c-input-text id="CityName" name="CityName" :initial-value="address.CityName" v-model="address.CityName" width="100%"></c-input-text>
						<c-field-validation-result :message="validation.cityNameMessage"></c-field-validation-result>
					</div>
				</div>

				<div class="form-group row">
					<label for="Line3" class="col-sm-3 col-form-label">State / Province</label>
					<div class="col-sm-9">
						<c-input-text id="StateName" name="StateName" :initial-value="address.StateName" v-model="address.StateName" width="100%"></c-input-text>
						<c-field-validation-result :message="validation.stateNameMessage"></c-field-validation-result>
					</div>
				</div>

				<div class="form-group row">
					<label for="PostCode" class="col-sm-3 col-form-label">Post Code</label>
					<div class="col-sm-9">
						<c-input-text id="PostCode" name="PostCode" :initial-value="address.PostCode" v-model="address.PostCode" width="100%"></c-input-text>
						<c-field-validation-result :message="validation.postCodeMessage"></c-field-validation-result>
					</div>
				</div>

				<div class="form-group row">
					<label for="Country" class="col-sm-3 col-form-label">Country</label>
					<div class="col-sm-9">
						<c-input-select id="Country" name="Country" 
							:options="$root.getSelectOptionsFromData(countries, 'ID', 'CountryName', '-- Select a Country --')"
							:initial-value="address.CountryID" v-model="address.CountryID" width="100%">
						</c-input-select>
						<c-field-validation-result :message="validation.countryMessage"></c-field-validation-result>
					</div>
				</div>

				<div class="form-group row" v-show="showCountryOther">
					<label for="CountryNameFreeText" class="col-sm-3 col-form-label">Other Country
					</label>
					<div class="col-sm-9">
						<c-input-text id="CountryNameFreeText" name="CountryNameFreeText" :initial-value="address.CountryNameFreeText" v-model="address.CountryNameFreeText" width="100%" placeholder="Line 1"></c-input-text>
						<c-info-text>If your country doesn't appear in the list above.</c-info-text>
						<c-field-validation-result :message="validation.countryNameFreeTextMessage"></c-field-validation-result>
					</div>
				</div>

				<div class="form-group row" v-show="showCoordinates">
					<label for="LongitudeS" class="col-sm-3 col-form-label">Cordinates (Lat/Long)</label>
					<div class="col-sm-4">
						<c-input-text id="LatitudeS" name="LatitudeS" :initial-value="address.LatitudeS" v-model="address.LatitudeS" width="100%" placeholder="Latitude"></c-input-text>
						<p class="c-info-text" style="font-size: small">Latitude</p>
						<c-field-validation-result :message="validation.latitudeSMessage"></c-field-validation-result>
					</div>
					<div class="col-sm-4">
						<c-input-text id="LongitudeS" name="LongitudeS" :initial-value="address.LongitudeS" v-model="address.LongitudeS" width="100%" placeholder="Longitude"></c-input-text>
						<p class="c-info-text" style="font-size: small">Longitude</p>
						<c-field-validation-result :message="validation.longitudeSMessage"></c-field-validation-result>
					</div>
				</div>

				<div class="form-group row" v-show="showActivationRadius">
					<label for="ActivationRadiusMeters" class="col-sm-3 col-form-label">Activation Radius</label>
					<div class="col-sm-9">
						<c-input-number id="ActivationRadiusMeters" name="ActivationRadiusMeters" :initial-value="address.ActivationRadiusMeters" v-model="address.ActivationRadiusMeters"></c-input-number>
						<c-field-validation-result :message="validation.activationRadiusMetersMessage"></c-field-validation-result>
					</div>
				</div>



				<div class="form-group row" v-if="showMapLink">
					<label for="Country" class="col-sm-3 col-form-label"></label>
					<div class="col-sm-9">
						<a :href="mapLink" target="_blank">
							Show address on Google Maps
							<!-- <i>{{mapAddress}}</i> -->
						</a>
						<br>
						<a :href="'https://www.openstreetmap.org/?mlat=' + address.LatitudeS + '&mlon=' + address.LongitudeS + '#map=16/' +address.LatitudeS + '/' + address.LongitudeS" target="_blank">
							Show coordinates on Open Street Maps
							<!-- for {{address.LatitudeS}},{{address.LongitudeS}} -->
						</a>
					</div>
				</div>


				<div class="form-group row mt-5">
					<label for="Buttons" class="col-sm-3 col-form-label"></label>
					<div class="col-sm-9">
						<c-button-confirm id="save" native-type="submit" :disabled="isSaving">
							<template v-slot:icon><i class="fas fa-save"></i></template>
							<template v-slot:text>{{saveButtonText}}</template>
						</c-button-confirm>

						<c-button-cancel id="cancel" @click="onCancel()">
							<!-- <template v-slot:icon></template> -->
							<template v-slot:text>Cancel</template>
						</c-button-cancel>
					</div>
				</div>
				<div class="form-group row mt-5" v-if="canArchive || canRestore">
					<label for="Buttons2" class="col-sm-3 col-form-label"></label>
					<div class="col-sm-9 text-right">
						<c-button-danger id="arhive-button" type="danger" @click="archiveAddress()" v-if="canArchive" :disabled="isSaving">
							<template v-slot:icon><i class="fas fa-minus"></i></template>
							<template v-slot:text>Archive Address</template>
						</c-button-danger>
						<!-- <c-button-confirm id="restore-button" type="success" @click="restoreAddress()" v-if="canRestore" :disabled="isSaving">
							<template v-slot:icon><i class="fas fa-minus"></i></template>
							<template v-slot:text>Restore Address</template>
						</c-button-confirm> -->
					</div>
				</div>
			</div>

		</form>
	</div>
</template>


<script>
import globalStore from '/globalStore.js';
import jobHelpers from './jobHelpers.js';

export default {

	emits: ["archived", "restored", "cancelled", "saved"],

	props: {
        addressId: {
			type: String,
			required: false,
            default: ""
		},


        showCountryOther: {
			type: Boolean,
			required: false,
            default: true
		},
        showCoordinates: {
			type: Boolean,
			required: false,
            default: true
		},
		showActivationRadius: {
			type: Boolean,
			required: false,
            default: true
		},
	},




	watch: {

		//TODO: Need this especially in a modal, so subsequent loads of the form are correctly loaded.
		addressId: function(to, from) 
		{
			console.log("address-edit-form.watch(addressId)", "to=", to, " | from=", from);

			this.init();
		},

	},




	mounted()
	{
		// var inputId = 'googleMapsAutocomplete';
		// console.log("mounted: initGoogleMapsAutocomplete()", document.getElementById(inputId));

		this.init();
	},



	updated()
	{
		// var inputId = 'googleMapsAutocomplete';
		// console.log("updated(): initGoogleMapsAutocomplete()", document.getElementById(inputId));
		//this.init();

		// Added this because `document.getElementById(inputId)` in `initGoogleMapsAutocomplete()` wasn't working.
		// I think Vue hadn't finished rendering for it to be available for `getElementById`.
		this.initGoogleMapsAutocomplete();
	},





	data()
	{
		return {
			globalStore: globalStore,


			accountId: "",
			//addressId: "",

			formTitle: "",


			countries: [],
			

			// Address search input
			addressSearch: "",


			showForm: false,

			//TDOO: This is temporary for when I copy this file to create address-edit-page.vue.
			address: {
				ID: "",
				Name: "",
				Format: "",
				Line1: "",
				Line2: "",
				Line3: "",
				CityName: "",
				StateName: "",
				PostCode: "",
				FreeFormat: "",
				CountryID: "",
				CountryNameFreeText: "",
				LongitudeS: "",
				LatitudeS: "",
				ActivationRadiusMeters: 50,
				Status: "Active",

				GoogleMapsApiPlaceId: "",
				LookupSource: "GooglePlacesAPI"
			},


			saveButtonText: "Create Address",


			
			isSaving: false,
			validation: {
				isValid: true,

				nameMessage: "",
				linesMessage: "",
				cityNameMessage: "",
				stateNameMessage: "",
				postCodeMessage: "",
				countryMessage: "",
				countryNameFreeTextMessage: "",
				longitudeSMessage: "",
				latitudeSMessage: "",
				activationRadiusMetersMessage: "",
			}

		};
	},





	computed: {
		getAccountId()
		{
			return this.accountId;
		},



		/**
		 * Can the address be archived?
		 */
		canArchive()
		{
			return this.address.ID != '' && this.address.Status == 'InProgress';
		},


		canRestore()
		{
			return this.address.ID != '' && this.address.Status == 'Archived';
		},


		showMapLink()
		{
			//return true;
			if (!this.isLotusAccount()) return false;

			return (
				(this.address.Name || this.address.Line1 || this.address.CityName)	// One of these is required.

				// And all of these are required...
				&& this.address.PostCode
				&& this.address.StateName
			);
		},


		mapAddress()
		{
			var address = "";

			if (this.address.Name) address = this.address.Name

			if (this.address.Line1) 
			{
				if (address) address = address + ", ";
				address = address + this.address.Line1;
			}
			if (this.address.Line2) 
			{
				if (address) address = address + ", ";
				address = address + this.address.Line2;
			}
			if (this.address.Line3) 
			{
				if (address) address = address + ", ";
				address = address + this.address.Line3;
			}

			if (this.address.CityName) 
			{
				if (address) address = address + ", ";
				address = address + this.address.CityName;
			}

			if (this.address.StateName) 
			{
				if (address) address = address + ", ";
				address = address + this.address.StateName;
			}

			if (this.address.PostCode) 
			{
				if (address) address = address + ", ";
				address = address + this.address.PostCode;
			}

			if (this.address.CountryID) 
			{
				var country = this.getCountryById(this.address.CountryID);
				if (country)
				{
					if (address) address = address + ", ";
					address = address + country.CountryName;
				}
			}
			else
			{
				if (this.address.CountryNameFreeText) 
				{
					if (address) address = address + ", ";
					address = address + this.address.CountryNameFreeText;
				}
			}

			return address;
		},

		mapLink()
		{
			//var link = "https://maps.google.com/?q=1200 Pennsylvania Ave SE, Washington, District of Columbia, 20003";
			var link = "https://maps.google.com/?q=" + this.mapAddress;

			return link;
		}
	},





	methods: {
		init()
		{
			var _this = this;

			this.accountId = this.globalStore.state.currentUser.activeAccountId;
			//console.log("accountId=", this.accountId);
			
			//TODO: Obsolete. See prop.
			//this.addressId = this.$root.getRouteValue("addressId");

			console.log("FORM:addressId=", this.addressId);

			if (this.addressId != "")
			{
				this.saveButtonText = "Update Address";
				this.formTitle = "Edit Address";
			}
			else
			{
				this.formTitle = "New Address";
			}


			jobHelpers.getAllCountriesInAccount(this.$root, this.countries, () => {
				//console.log('this.countries=', this.countries);
			});

			if (this.addressId)
			{
				this.getAddress(() => {
					// Show the form for the loaded address (IF it has a value).
					_this.showForm = _this.formHasValue();
				});
			}


			this.initGoogleMapsAutocomplete();
		},



		
		/**
		 * Everything related to the Google Maps Places API autocomplete lookup
		 */
		initGoogleMapsAutocomplete()
		{
			var _this = this;
			
			var inputId = 'googleMapsAutocomplete';

			//console.log("initGoogleMapsAutocomplete()", document.getElementById(inputId));

			let autocomplete = new window.google.maps.places.Autocomplete(
				document.getElementById(inputId),
				{
					//types: ['establishment'],
					componentRestrictions: {'country': ['AU','IE', 'AF','PK', 'US']},
					fields: ['place_id', 'geometry', 'name', 'adr_address', 'address_components','formatted_address','utc_offset_minutes']
				}
			);

			function onPlaceChanged()
			{
				var place = autocomplete.getPlace();
				
				console.log("onPlaceChanged(): place=", place);


				_this.address.LookupSource = "GooglePlacesAPI";

				if (!place.geometry)
				{
					// User did not select a prediction. Reset the input field.
					document.getElementById(inputId).placeholder = 'Search for an address or place.'
				}
				else
				{
					//-- First check if the form already has any data entered, and alert the user if they want to overwrite
					if (_this.formHasValue())
					{
						console.log("FORM HAS VALUE;")
						if (confirm("Form already has values entered.\nAre you sure you want to replace everything with the selected address?") == false)
						{
							// User says "no, don't replace it".
							return;
						}
					}


					//-- Update the address details
					var name = place.name;
					var subPremise = getComponent(place.address_components, 'subpremise');	// Unit/Lot
					var streetNumber = getComponent(place.address_components, 'street_number');
					var streetNameAndType = getComponent(place.address_components, 'route');
					var city = getComponent(place.address_components, 'locality');
					var state = getComponent(place.address_components, 'administrative_area_level_1');
					var country = getComponent(place.address_components, 'country');
					var postCode = getComponent(place.address_components, 'postal_code');

					let street = '';

					//street = streetNumber.long_name + " " + streetNameAndType.long_name;
					if (streetNumber != null) street = streetNumber.long_name;
					if (streetNameAndType != null) street = street + " " + streetNameAndType.long_name;

					if (subPremise != null)
					{
						street = (subPremise.long_name ? subPremise.long_name : "") + "/" + street;
					}

					const countryId = getCountryId(
										country.long_name ? country.long_name : "", 
										country.short_name ? country.short_name : ""
										);


					_this.address.GoogleMapsApiPlaceId = place.place_id;
					_this.address.LookupSource = "GooglePlacesAPI";

					_this.address.Name = name;
					_this.address.Line1 = street;
					_this.address.Line2 = "";
					_this.address.Line3 = "";
					_this.address.CityName = city.long_name ? city.long_name : "";
					_this.address.StateName = state.long_name ? state.long_name : "";
					_this.address.PostCode = postCode.long_name ? postCode.long_name : "";
					_this.address.CountryID = countryId;

					if (countryId == "")
					{
						// Lookup failed. Write to the free text
						_this.address.CountryNameFreeText = country.long_name;
					}

					//TODO: If country ID lookup fails, need a free text country entry

					_this.address.LatitudeS = place.geometry.location.lat().toString();
					_this.address.LongitudeS = place.geometry.location.lng().toString();
					

					_this.showForm = true;
				}
			}


			function getCountryId(countryName, iso366x1Alpha2Code)
			{
				let countryId = "";

				// Try on the short name (which I presume is the country code.)
				_this.countries.forEach((item, index) => {
					if (item.ISO366x1Alpha2Code.toLowerCase() == iso366x1Alpha2Code.toLowerCase())
					{
						countryId = item.ID;
						return;
					}
				});

				// Try on the full name
				if (countryId == "")
				{
					_this.countries.forEach((item, index) => {
						if (item.CountryName.toLowerCase() == countryName.toLowerCase())
						{
							countryId = item.ID;
							return;
						}
					});
				}

				// Not found.

				if (countryId == "") console.warn("getCountryId(): coountry not found for '" + countryName + "', '" + iso366x1Alpha2Code + "'.");
				return countryId;
			}


			function getComponent(addressComponents, component)
			{
				let foundComponent = null;

				addressComponents.forEach((item, index) => {
					if (item.types.includes(component))
					{
						foundComponent = item;
						return;
					}
				});

				if (foundComponent == null) console.warn("getComponent(): component not found for type '" + component + "'.");
				return foundComponent;
			}

			autocomplete.addListener('place_changed', onPlaceChanged);
		},



		getCountryById(countryId)
		{
			return jobHelpers.getCountryById(this.countries, countryId);
		},




		//NOTE: Need to make sure we're retrieved the clients and buckets from the API before calling this. They're dependencies.
		getAddress(callback)
		{
			var _this = this;
			
			var requestData = {
				AddressID: this.addressId
			};

			console.log("getAddress(): requestData=", requestData);

			this.$root.showProcessingOverlay();
			
			this.$root.callApi("/Addresses/GetAddressByID", requestData, "GET")
			.then((responseData) => {
				console.log("getAddress(): responseData=", responseData);

				_this.$root.hideProcessingOverlay();

				// This can happen when the ID is not found (e.g. a fake test ID)
				if (!responseData) return;
				
				_this.address = responseData.Address;

				if (callback) callback();
			});
		},





		archiveAddress()
		{
			this.address.Status = "Archived";

			this.createOrUpdateAddress();

			this.$emit('archived', {});
		},



		restoreAddress()
		{
			this.address.Status = "Active";

			this.createOrUpdateAddress();

			this.$emit('restored', {});
		},





		onCancel()
		{
			//if (this.redirectIfRequired()) return;

			// No redirect specified. Default redirect.
			// this.$root.redirectToPath('/manage/addresses');

			this.$emit('cancelled', {});
		},


		/**
		 * Conditionally perform redirect back to a soruce page if the return values are provided.
		 */
		redirectIfRequired()
		{
			//var returnTo = this.$root.getQueryValue("returnTo");	//TODO: For some reason, calling through the helper function doesn't have any route/query data

			//var query = this.$router.currentRoute.query;
			var query = this.$root.getCurrentRouteQuery();

			var returnTo = query.returnTo;


			console.log("redirectIfRequired(): returnTo=", returnTo);

			if (!returnTo) return false;	// Cannot redirect without this value

			if (returnTo.toLowerCase() == "job".toLowerCase())
			{
				//var jobId = this.$router.currentRoute.query.jobId;
				var jobId = this.$root.getCurrentRouteQuery().jobId;


				if (!jobId) return false;	// Cannot redirect without this value

				let path = '/manage/job/' + jobId + '/edit';
				//console.log("redirectIfRequired(): path=", path);

				// No redirect specified. Default redirect.
				this.$root.redirectToPath(path);

				return true;
			}

			return false;
		},


		
		createOrUpdateAddress()
		{
			var _this = this;

			var requestData = {
				AccountID: this.accountId,
				AddressID: "",

				Format: this.address.Format,
				Name: this.address.Name,
				Line1: this.address.Line1,
				Line2: this.address.Line2,
				Line3: this.address.Line3,
				CityName: this.address.CityName,
				StateName: this.address.StateName,
				PostCode: this.address.PostCode,
				FreeFormat: this.address.FreeFormat,
				CountryID: this.address.CountryID,
				CountryNameFreeText: this.address.CountryNameFreeText,
				LongitudeS: this.address.LongitudeS,
				LatitudeS: this.address.LatitudeS,
				ActivationRadiusMeters: this.address.ActivationRadiusMeters,
				Status: this.address.Status,

				LookupSource: this.address.LookupSource,
				GoogleMapsApiPlaceId: this.address.GoogleMapsApiPlaceId
			};

			let action = '';
			if (!this.address.ID)
			{
				// Creating a new address
				action = "create";
			}
			else
			{
				// Updating existing address
				requestData.AddressID = this.address.ID;
				action = "update";
			}

			console.log("createOrUpdateAddress(): requestData=", requestData);

			if (!this.validateAddress()) return;	// Invalid

			//return;	// Testing

			this.isSaving = true;

			//console.log("createOrUpdateAddress():", requestData);

			this.$root.showProcessingOverlay();

			this.$root.callApi("/Addresses/CreateOrUpdateAddress", requestData, "POST")
			.then((responseData) => {
				//console.log("createOrUpdateAddress(): responseData=", responseData);

				_this.$root.hideProcessingOverlay();

				this.isSaving = false;
				
				if (responseData == null) return; // Not successful

				_this.$root.showToastMessage("Address saved.", "Address");
				
				// Try a conditional redirect to a return page first.
				//if (this.redirectIfRequired()) return;

				// Redirect to buckets page.
				//console.log("createOrUpdateAddress(): Redirect to addresses page");
				//_this.$root.redirectToPath("/manage/addresses");

				this.$emit('saved', { NewAddressID: responseData.AddressID, Address: _this.address, Action: action });
			});
		},



		validateAddress()
		{
			// Reset
			this.validation.isValid = true;

			this.validation.linesMessage = "";

			this.validation.cityNameMessage = "";
			this.validation.stateNameMessage = "";
			this.validation.postCodeMessage = "";
			this.validation.countryMessage = "";

			this.validation.longitudeSMessage = "";
			this.validation.latitudeSMessage = "";
			this.validation.activationRadiusMetersMessage = "";


			// Validate

			//Disabled: We may have a location that's not an address (e.g. a rotunda in a part) and just have coordinates for it.
			// if (!this.address.Line1 && !this.address.Line2 && !this.address.Line3)
			// {
			// 	this.validation.linesMessage = "At least 1 street line requires a value.";
			// 	this.validation.isValid = false;
			// }

			if (!this.address.CountryID)
			{
				this.validation.countryMessage = "Country is required.";
				this.validation.isValid = false;
			}

			if (this.address.LongitudeS && !this.address.LatitudeS)	 // Latitude, no longitude.
			{
				this.validation.longitudeSMessage = "Longitude is required when a latitude is entered.";
				this.validation.isValid = false;
			}


			if (this.address.LongitudeS && !this.address.LatitudeS)	 // Longitude, no latitude.
			{
				this.validation.latitudeSMessage = "Latitude is required when a longitude is entered.";
				this.validation.isValid = false;
			}

			//TODO: Only if there are coordinates.
			if (this.address.ActivationRadiusMeters != "0" && !this.address.ActivationRadiusMeters)	// Zero is a valid number, not "falsy".
			{
				this.validation.activationRadiusMetersMessage = "Activation radius is required";
				this.validation.isValid = false;
			}

			//console.log("validateAddress(): this.validation=", this.validation);

			return this.validation.isValid;
		},




		/**
		 * Check if the form already has a value (based on bound address data).
		 */
		formHasValue()
		{
			//console.log("formHasValue(): this.address=", JSON.stringify(this.address));
			console.log("formHasValue(): this.address=", this.address);

			// It's not a new address because it has an ID, therefore it has a value.
			// Note (21 Nov 2023): This is no longer always true. Organisations now have a primary address record set as a placeholder.
			// so, we need to do the value checks below instead.
			//
			// if (this.address.ID) return true;

			if (this.address.Name) return true;
			if (this.address.Line1) return true;
			if (this.address.Line2) return true;
			if (this.address.Line3) return true;
			if (this.address.CityName) return true;
			if (this.address.StateName) return true;
			if (this.address.PostCode) return true;
			//if (this.address.CountryID) return true;	// No - a default value might be set. Besides, country is not enough on its own at this stage.
			if (this.address.CountryNameFreeText) return true;	// ... however, if a user has entered a name then it definitely has a value. 

			if (this.address.LongitudeS) return true;
			if (this.address.LatitudeS) return true;
			//if (this.address.ActivationRadiusMeters) return true;	// This will have a default value

			console.log("formHasValue(): NOPE");

			return false;
		},





		isLotusAccount()
		{
			return this.$root.isLotusAccount();
		},


	}





}
</script>


<style>
/*
* In the context of a diologue box, the address select list is hidden below the form/modal/overlay.
* Raise the list so it's always above everything.
* Ref: https://stackoverflow.com/questions/44850642/google-maps-autocomplete-dropdown-hidden-when-google-maps-full-screen-is-true
*/
.pac-container, .pac-item {
	z-index: 2147483647 !important;
}





#address-edit-form .form-group
{
	margin-bottom: 0.1rem;
}
</style>